import poster_01 from "../../assets/img/spark/alternate_futures/poster_01.jpg";

import Alternate_Futures001 from "../../assets/img/spark/ddd/Alternate_Futures001.jpg";
import Alternate_Futures002 from "../../assets/img/spark/ddd/Alternate_Futures002.jpg";
import poster_alternate_Futures01 from "../../assets/img/spark/ddd/poster_alternate_Futures01.jpg";

import difussed_01 from "../../assets/img/spark/ddd/diffused/01_DIFFUSED.jpg";
import difussed_02 from "../../assets/img/spark/ddd/diffused/02_A.jpg";
import difussed_03 from "../../assets/img/spark/ddd/diffused/02_B.jpg";
import difussed_04 from "../../assets/img/spark/ddd/diffused/03_heymesh.jpg";
import difussed_05 from "../../assets/img/spark/ddd/diffused/04_momento.jpg";
import difussed_06 from "../../assets/img/spark/ddd/diffused/05_interyor.jpg";


import ai_dreams_ff from "../../assets/img/spark/ddd/ai_dreams/ai_dreams_ff.jpg";
import ai_dreams_gms from "../../assets/img/spark/ddd/ai_dreams/ai_dreams_gms.jpg";
import amazon_xgrinch_poster from "../../assets/img/spark/ddd/ai_dreams/amazon_xgrinch_poster.jpg";
import up_for_whatever_poster from "../../assets/img/spark/ddd/ai_dreams/up_for_whatever_poster.jpg";
import the_dot_poster from "../../assets/img/spark/ddd/ai_dreams/the_dot_poster.jpg";
import mayhem_poster from "../../assets/img/spark/ddd/ai_dreams/mayhem_poster.jpg";
import fast_and_furious_poster from "../../assets/img/spark/ddd/ai_dreams/fast_and_furious_poster.jpg";

import brassroots_district_01 from "../../assets/img/spark/ddd/brassroots/brassroots_district_01.jpg";
import brassroots_district_02 from "../../assets/img/spark/ddd/brassroots/brassroots_district_02.jpg";
import brassroots_district_03 from "../../assets/img/spark/ddd/brassroots/brassroots_district_03.jpg";
import brassroots_district_04 from "../../assets/img/spark/ddd/brassroots/brassroots_district_04.jpg";
import brassroots_poster from "../../assets/img/spark/ddd/brassroots/brassroots_poster.jpg";

import surfgenie_01 from "../../assets/img/spark/ddd/surf/surfgenie_01.jpg";
import surfgenie_02 from "../../assets/img/spark/ddd/surf/surfgenie_02.jpg";
import surfgenie_poster from "../../assets/img/spark/ddd/surf/surfgenie_poster.jpg";

  const SparkObject = [
    { 
      name: "Alternate Futures",
      year: "2023",
      url: "spark-alternate-futures",
      color: "rgba(0, 131, 81, 1)",
      type_theme: "normal_001",
      files: [
        {
          "type": "image",
          "data": Alternate_Futures001
        },
        {
          "type": "video",
          "data": {
            "sm": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/779026413(540p).mp4#t=75",
            "lg": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/779026413(1080p).mp4#t=75",
            "poster": poster_alternate_Futures01
          },
        },
        {
          "type": "image",
          "data": Alternate_Futures002
        }
      ],
      "content": [
        {
          "type": "p",
          "text": "Most people have a vivid vision of what the world will look like if we do nothing about the climate crisis."
        },
        {
          "type": "p",
          "text": "Fire, floods, extreme heat, persistent drought, climate refugees, etc. And with good reason - this is the path we are currently on. On the other hand…"
        },
        {
          "type": "p",
          "text": "What does the world look like if we massively confront and solve the climate crisis? Ask people this question and most people either: 1. Draw a blank; 2. Go into a science fiction fantasy world; 3. Think it will look like it did when they were a kid. The reality is… none of this is true."
        },
        {
          "type": "p",
          "text": "For the first time in human history, A.I. allows us to rapidly visualize and synthesize our collective visions at scale and with high accuracy and resolution. Over time as our database grows, A.I. begins to source points of consilience. This collective data, enables us to guide the decisions of policy makers and business leaders."
        },
        {
          "type": "p",
          "text": "How?"
        },
        {
          "type": "p",
          "text": "AF leverages a suite of generative AI tools and a proprietary set of filters to output a range of creative assets including: images, animation, and a story specific to each individuals’ inputs. These outputs are displayed to the individual in the experience as well as provided via text link for sharing."
        },
        {
          "type": "p",
          "text": "All of the individual inputs are collected and a “consilient” vision based on input prompts is created using a machine learning algorithm. This consilient vision can be made available to all participants in a variety of ways - text link, as an invited experience at the end of the event, etc."
        },
      ]
    },
    {
      name: "AI Dreams",
      year: "2023",
      url: "spark-ai-dreams",
      color: "#007CB0",
      type_theme: "one_column",
      files: [
        {
          "type": "image",
          "data": ai_dreams_ff,
          "title": "AI DREAMS",
          "text": "What would Artificial Intelligence do, if handed the original briefs we've received?<br>HELO decided to find out. We discovered that while the technology offers amazing tools, there’s still no replacing the creative spirit and human intuition within the equation.",
        },
        {
          "type": "video",
          "data": {
            "sm": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/821748102(540p).mp4#t=17",
            "lg": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/821748102(1080p).mp4#t=17",
            "poster": amazon_xgrinch_poster,
          },
          "title": "GRINCH ROCKEFELLER<br>PLAZA TREE",
          "text": "Grinch Rockefeller Plaza Tree We started the series by working with an early iteration of Midjourney, re-imagining one of our <mark>favourite Christmas activations</mark> that we did to promote Universal/Illumination’s ‘The Grinch’ movie, building a 30-foot tall Christmas tree in New York’s famed Rockefeller Center. As a physical spectacle it drew thousands of visitors and reached millions via NBC’s The Today Show, but as a design brief,what would A.I. bring to the mix?",
        },
        {
          "type": "video",
          "data": {
            "sm": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/797837099(540p).mp4#t=1",
            "lg": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/797837099(1080p).mp4#t=1",
            "poster": up_for_whatever_poster,
          },
          "title": "UP FOR WHATEVER",
          "text": "This is a play on the original Super Bowl spot we did for <mark>Bud Light</mark>. We deployed some AI magic to help generate images, deep fakes and even the voices for the featured celebrities who contributed to the original craziness. If this brief was now, we think you’d see more AI powered approaches to ramp up the number of celebs or enhance environments. But  for an unsuspecting member of the public, it's clear that more traditional & imaginative production design methods still very much has its place. ",
        },
        {
          "type": "video",
          "data": {
            "sm": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/815082986(540p).mp4#t=1",
            "lg": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/815082986(1080p).mp4#t=1",
            "poster": the_dot_poster
          },
          "title": "HEADSPACE THE DOT",
          "text": "Testing the photo-real capabilities of AI, we riffed off of an installation we created with <mark>Sebastian Kite</mark> in the city of Pittsburgh for Headspace. Everything featured in this video was generated by AI, even the script.",
        },
        {
          "type": "video",
          "data": {
            "sm": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/314559418(540p).mp4#t=31",
            "lg": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/314559418(1080p).mp4#t=31",
            "poster": mayhem_poster
          },
          "title": "MAYHEM",
          "text": "Rather than start with the brief, we ran our original <mark>Mayhem TV</mark> spot through AI to see what additional madness we could create. The visual results are a tonne of fun and remind us of the <mark>faux ‘takeaway’ TVC’s</mark> that were doing the rounds recently (though less terrifying) albeit further proof that you can create some fantastic imagery via AI, but without considered human curation, the output can’t always be gold.",
        },        {
          "type": "video",
          "data": {
            "sm": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/825581560(540p).mp4#t=73",
            "lg": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/825581560(1080p).mp4#t=73",
            "poster": fast_and_furious_poster
          },
          "title": "FATE OF THE FURIOUS",
          "text": "With this abstract re-interpretation of our original piece for <mark>Fate of the Furious</mark>, we’ve leveraged AI to take us fully down the Manga rabbit hole, featuring a couple of characters who are learning about our crazy stunt as it’s taking place, and wondering why people would do such a thing. Turns out Fast and Furious is all about family, no matter how it’s been created.",
        },
      ]
    },
    {
      name: "Brassroots District",
      year: "2023",
      url: "spark-brassroots-district",
      color: "#DE5307",
      type_theme: "normal_02",
      files: [
        {
          "type": "image",
          "data": brassroots_district_01
        },
        {
          "type": "video",
          "data": {
            "sm": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/855830353(540p).mp4#t=12",
            "lg": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/855830353(1080p).mp4#t=12",
            "poster": brassroots_poster
          },
        },
        {
          "type": "image",
          "data": brassroots_district_02
        },
        {
          "type": "image",
          "data": brassroots_district_03
        },
        {
          "type": "image",
          "data": brassroots_district_04
        }
      ],
      "content": [
        {
          "type": "p",
          "text": "<div class='block_ctrl pb-4'>Record Release Summer ‘73 is an immersive concert experience that is being designed for release in Las Vegas later this year.</div><div class='block_ctrl pb-3'>It's an immersive event.</div><div class='block_ctrl pb-3'>It's a movement</div><div class='block_ctrl pb-3'>It’s a genre-defying musical spectacle.</div><div class='block_ctrl pb-3'>It's a full-on dive into the funk scene of the early 1970s where audience members are a key part of the action.</div><div class='block_ctrl pb-3'>Patrons get to live out their 1970s rock n’ roll fantasies for one night as they not only get down on the dance floor to the hottest funk band this side of the Mississippi, Brassroots District, but also play an active role in the experience.</div>This is an event where fantasy and reality collide, a CYOA + hybrid theatre experience, that isn’t just a concert; but a journey through 70’s rock with a narrative that happens as much off stage as it does on."
        }
      ]
    },
    {
      name: "Surf Genie",
      year: "2023",
      url: "spark-surf-genie",
      color: "#6049F8",
      type_theme: "normal_01",
      files: [
        {
          "type": "image",
          "data": surfgenie_01
        },
        {
          "type": "video",
          "data": {
            "sm": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/856544953(540p).mp4#t=1",
            "lg": "https://f004.backblazeb2.com/file/b9-videos/helo/spark/856544953(1080p).mp4#t=1",
            "poster": surfgenie_poster
          },
        },
        {
          "type": "image",
          "data": surfgenie_02
        },
      ],
      "content": [
        {
          "type": "p",
          "text": "The Surf Genie is the digital offspring of a humorous conversation between Brendan Kiernan and Kyle Ruddick. Observing Kyle’s knack for pointing friends to the best surf spots, Brendan mused, “Kyle, you need to make an AI surf Genie”. Thus, the idea was born."
        },
        {
          "type": "p",
          "text": "Today, the Surf Genie has emerged as an engaging online personality, dishing out daily surf reports with humor and wisdom. It’s more than just a forecasting tool, it’s an AI influencer, an entertaining companion for surfers worldwide. But that’s just the beginning. The Surf Genie has ambitions to offer tailored surf reports directly to those who sign up, advising on when and where to go, factoring in key details like crowd size and weather changes."
        },
        {
          "type": "p",
          "text": "At the heart of the Surf Genie is a groundbreaking collaboration between human intuition and artificial intelligence. Guided by Kyle’s deep understanding of surfing and wave forecasting, the AI is being honed to interpret and respond to surfing data in an authentic and engaging way. This unique interplay is shaping the Surf Genie’s persona, ensuring it’s not only accurate but also funny and deeply rooted in surf culture."
        },
        {
          "type": "p",
          "text": "Soon, the Surf Genie will become a personalized guide, delivering advice suited to each surfer’s experience and comfort level. This innovative development of a digital influencer who can provide both entertainment and information is part of the wider AI revolution. Together, Brendan and Kyle are not only enhancing the surfing experience but also pushing the boundaries of what AI can achieve."
        }
      ]
    },
    {
      name: "Diffused",
      year: "2023",
      url: "spark-diffused",
      color: "#6049F8",
      type_theme: "normal_03",
      files: [
        {
          "type": "image",
          "data": difussed_04
        },
        {
          "type": "image",
          "data": difussed_02
        },
        {
          "type": "image",
          "data": difussed_03
        },
        {
          "type": "image",
          "data": difussed_01
        },
        {
          "type": "image",
          "data": difussed_05
        },
        {
          "type": "image",
          "data": difussed_06
        },
      ],
      "content": [
        {
          "type": "p",
          "text": "DIFFUSED’s existing array of AI driven applications and services impressed us enormously, combined with the speed in which they are able to go from concept to distributed product so quickly, as proven by their impressive roster of apps that you can see here - https://www.diffused.tech/apps"
        },
        {
          "type": "p",
          "text": "Creative Technologist, Meddy Abdi, and Connor Cargill, Solutions Architect, Co-Founders of DIFFUSED believe that community comes first and that complex technology should be democratised, simultaneously empowering creators and helping brands to connect with new audiences."
        },
        {
          "type": "p",
          "text": "The essence of HELO SPARK is to shine a light on precisely this kind of work and ambition, so we will be supporting Meddy & Connor in this journey, whilst partnering on new projects, R&D and IP opportunities, and we look forward to sharing the fruits of our collaboration very soon."
        },
        {
          "type": "p",
          "text": "Welcome DIFFUSED!"
        }
      ]
    }
  ];










export default SparkObject;