import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";

import AlternateFutures_sq from "../../assets/img/AlternateFutures_sq.jpg";
import linkImg from "../../assets/img/linkImg.svg";

import poster_01 from "../../assets/img/posters/helo_labs/821748102.jpg";
import poster_02 from "../../assets/img/posters/helo_labs/797837099.jpg";
import poster_03 from "../../assets/img/posters/helo_labs/815082986.jpg";
import poster_04 from "../../assets/img/posters/helo_labs/823840805.jpg";
import poster_05 from "../../assets/img/posters/helo_labs/825581560.jpg";

import brassroots_01 from "../../assets/img/posters/helo_labs/brassroots_01.jpg";
import brassroots_02 from "../../assets/img/posters/helo_labs/brassroots_02.jpg";
import brassroots_03 from "../../assets/img/posters/helo_labs/brassroots_03.jpg";

import Menu from '../../components/submodules/Menu';
import Indice from '../../components/submodules/Indice';
import { motion } from "framer-motion/dist/framer-motion";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const HeloSparkV4 = () => {

  const [showIndice, setShowIndice] = useState(false);

  // helper functions
  const MathUtils = {
      // map number x from range [a, b] to [c, d]
      map: (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c,
      // linear interpolation
      lerp: (a, b, n) => (1 - n) * a + n * b,
      // Random float
      getRandomFloat: (min, max) => (Math.random() * (max - min) + min).toFixed(2)
  };

  // body element
  const body = document.body;
    
  // calculate the viewport size
  let winsize;
  const calcWinsize = () => winsize = {width: window.innerWidth, height: window.innerHeight};
  calcWinsize();

    
  // scroll position
  let docScroll;
  // for scroll speed calculation
  let lastScroll;
  let scrollingSpeed = 0;
  // scroll position update function
  const getPageYScroll = () => docScroll = window.pageYOffset || document.documentElement.scrollTop;
    
  // Item
  class Item {
      constructor(el) {
          // the .item element
          this.DOM = {el: el};
          // the inner image
          this.DOM.image = this.DOM.el.querySelector('.content__item-img');
          this.DOM.imageWrapper = this.DOM.image.parentNode;
          this.DOM.title = this.DOM.el.querySelector('.content__item-title');
          this.renderedStyles = {
              // here we define which property will change as we scroll the page and the item is inside the viewport
              // in this case we will be:
              // - scaling the inner image
              // - translating the item's title
              // we interpolate between the previous and current value to achieve a smooth effect
              imageScale: {
                  // interpolated value
                  previous: 0, 
                  // current value
                  current: 0, 
                  // amount to interpolate
                  ease: 0.1,
                  // current value setter
                  setValue: () => {
                      const toValue = 1.5;
                      const fromValue = 1;
                      const val = MathUtils.map(this.props.top - docScroll, winsize.height, -1 * this.props.height, fromValue, toValue);
                      return Math.max(Math.min(val, toValue), fromValue);
                  }
              },
              titleTranslationY: {
                  previous: 0, 
                  current: 0, 
                  ease: 0.1,
                  fromValue: Number(MathUtils.getRandomFloat(30,400)),
                  setValue: () => {
                      const fromValue = this.renderedStyles.titleTranslationY.fromValue;
                      const toValue = -1*fromValue;
                      const val = MathUtils.map(this.props.top - docScroll, winsize.height, -1 * this.props.height, fromValue, toValue);
                      return fromValue < 0 ? Math.min(Math.max(val, fromValue), toValue) : Math.max(Math.min(val, fromValue), toValue);
                  }
              }
          };
          // gets the item's height and top (relative to the document)
          this.getSize();
          // set the initial values
          this.update();
          // use the IntersectionObserver API to check when the element is inside the viewport
          // only then the element styles will be updated
          this.observer = new IntersectionObserver((entries) => {
              entries.forEach(entry => this.isVisible = entry.intersectionRatio > 0);
          });
          this.observer.observe(this.DOM.el);
          // init/bind events
          this.initEvents();
      }
      update() {
          // sets the initial value (no interpolation)
          for (const key in this.renderedStyles ) {
              this.renderedStyles[key].current = this.renderedStyles[key].previous = this.renderedStyles[key].setValue();
          }
          // apply changes/styles
          this.layout();
      }
      getSize() {
          const rect = this.DOM.el.getBoundingClientRect();
          this.props = {
              // item's height
              height: rect.height,
              // offset top relative to the document
              top: docScroll + rect.top
          }
      }
      initEvents() {
          window.addEventListener('resize', () => this.resize());
      }
      resize() {
          // gets the item's height and top (relative to the document)
          this.getSize();
          // on resize reset sizes and update styles
          this.update();
      }
      render() {
          // update the current and interpolated values
          for (const key in this.renderedStyles ) {
              this.renderedStyles[key].current = this.renderedStyles[key].setValue();
              this.renderedStyles[key].previous = MathUtils.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].ease);
          }
          
          // and apply changes
          this.layout();
      }
      layout() {
          // scale the image
          this.DOM.image.style.transform = `scale3d(${this.renderedStyles.imageScale.previous},${this.renderedStyles.imageScale.previous},1)`;
          // translate the title
          this.DOM.title.style.transform = `translate3d(0,${this.renderedStyles.titleTranslationY.previous}px,0)`;
      }
  }

    // SmoothScroll
    class SmoothScroll {
        constructor() {
            // the <main> element
            this.DOM = {main: document.querySelector('main')};
            // the scrollable element
            // we translate this element when scrolling (y-axis)
            this.DOM.scrollable = this.DOM.main.querySelector('div[data-scroll]');
            // the items on the page
            this.items = [];
            this.DOM.content = this.DOM.main.querySelector('.content');
            [...this.DOM.content.querySelectorAll('.content__item')].forEach(item => this.items.push(new Item(item)));
            // here we define which property will change as we scroll the page
            // in this case we will be translating on the y-axis
            // we interpolate between the previous and current value to achieve the smooth scrolling effect
            this.renderedStyles = {
                translationY: {
                    // interpolated value
                    previous: 0, 
                    // current value
                    current: 0, 
                    // amount to interpolate
                    ease: 0.1,
                    // current value setter
                    // in this case the value of the translation will be the same like the document scroll
                    setValue: () => docScroll
                }
            };
            // set the body's height
            this.setSize();
            // set the initial values
            this.update();
            // the <main> element's style needs to be modified
            this.style();
            // init/bind events
            this.initEvents();
            // start the render loop
            requestAnimationFrame(() => this.render());
        }
        update() {
            // sets the initial value (no interpolation) - translate the scroll value
            for (const key in this.renderedStyles ) {
                this.renderedStyles[key].current = this.renderedStyles[key].previous = this.renderedStyles[key].setValue();   
            }   
            // translate the scrollable element
            this.layout();
        }
        layout() {
            this.DOM.scrollable.style.transform = `translate3d(0,${-1*this.renderedStyles.translationY.previous}px,0)`;
        }
        setSize() {
            // set the heigh of the body in order to keep the scrollbar on the page
            body.style.height = `${this.DOM.scrollable.scrollHeight}px`;
        }
        style() {
            // the <main> needs to "stick" to the screen and not scroll
            // for that we set it to position fixed and overflow hidden 
            this.DOM.main.style.position = 'fixed';
            this.DOM.main.style.width = this.DOM.main.style.height = '100%';
            this.DOM.main.style.top = this.DOM.main.style.left = 0;
            this.DOM.main.style.overflow = 'hidden';
        }
        initEvents() {
            // on resize reset the body's height
            window.addEventListener('resize', () => this.setSize());
        }
        render() {
            // Get scrolling speed
            // Update lastScroll
            scrollingSpeed = Math.abs(docScroll - lastScroll);
            lastScroll = docScroll;
            
            // update the current and interpolated values
            for (const key in this.renderedStyles ) {
                this.renderedStyles[key].current = this.renderedStyles[key].setValue();
                this.renderedStyles[key].previous = MathUtils.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].ease);    
            }
            // and translate the scrollable element
            this.layout();
            
            // for every item
            for (const item of this.items) {
                // if the item is inside the viewport call it's render function
                // this will update item's styles, based on the document scroll value and the item's position on the viewport
                if ( item.isVisible ) {
                    if ( item.insideViewport ) {
                        item.render();
                    }
                    else {
                        item.insideViewport = true;
                        item.update();
                    }
                }
                else {
                    item.insideViewport = false;
                }
            }
            
            // loop..
            requestAnimationFrame(() => this.render());
        }
    }


  function toggleIndice(){
    setShowIndice(!showIndice);
  }

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");

    // and recalculate on resize
    window.addEventListener('resize', calcWinsize);
    // 
    window.addEventListener('scroll', getPageYScroll);

    getPageYScroll();
    lastScroll = docScroll;
    new SmoothScroll();

  }, []);

  return(
    <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
    <Menu propType={'lighten'}/>
    <Indice propShowIndice={showIndice} propSetShowIndice={setShowIndice} />
    <style>{`
      html>body{
        --aspect-ratio: 32/17;
          --imgwidthmax: 500px;
          --size-title: 10rem;
          --font-weight-title: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          overflow-y: auto;
          background-color: #209267;
      }
      div.submenu_{
        position: fixed;
      }
      a.icon{
        position: fixed;
      }
    `}</style>
      
      <main>
        <div data-scroll className="page page--layout-2">
          <div className="content content--full content--alternate">
            {/*            
            <div className="content__item content__item--wide">
              <span className="content__item-number">HELO SPARK</span>
              <div className="content__item-imgwrap"><div className="content__item-img" style={{ backgroundImage: `url(${poster_01})` }}></div></div>
            </div>
            */}
            <div className="content__item content__item--wide">
              <span className="content__item-number">01</span>
              <div className="content__item-imgwrap"><div className="content__item-img" style={{ backgroundImage: `url(${poster_01})` }}></div></div>
              <div className="content__item-deco"></div>
              <h2 className="content__item-title">Oh</h2>
              <p className="content__item-description">Little trees and bushes grow however makes them happy.</p>
            </div>
            <div className="content__item content__item--wide">
              <span className="content__item-number">02</span>
              <div className="content__item-imgwrap"><div className="content__item-img" style={{ backgroundImage: `url(${poster_02})` }}></div></div>
              <div className="content__item-deco"></div>
              <h2 className="content__item-title">Ri</h2>
              <p className="content__item-description">We don't have to be committed. We are just playing here.</p>
            </div>
            <div className="content__item content__item--wide">
              <span className="content__item-number">03</span>
              <div className="content__item-imgwrap"><div className="content__item-img" style={{ backgroundImage: `url(${poster_03})` }}></div></div>
              <div className="content__item-deco"></div>
              <h2 className="content__item-title">Nj</h2>
              <p className="content__item-description">I thought today we would do a happy little picture.</p>
            </div>
            <div className="content__item content__item--wide">
              <span className="content__item-number">04</span>
              <div className="content__item-imgwrap"><div className="content__item-img" style={{ backgroundImage: `url(${poster_04})` }}></div></div>
              <div className="content__item-deco"></div>
              <h2 className="content__item-title">Mo</h2>
              <p className="content__item-description">Nature is so fantastic, enjoy it. Let it make you happy.</p>
            </div>
            <div className="content__item content__item--wide">
              <span className="content__item-number">05</span>
              <div className="content__item-imgwrap"><div className="content__item-img" style={{ backgroundImage: `url(${poster_05})` }}></div></div>
              <div className="content__item-deco"></div>
              <h2 className="content__item-title">Ne</h2>
              <p className="content__item-description">We need a shadow side and a highlight side.</p>
            </div>
            <div className="content__item content__item--wide">
              <span className="content__item-number">06</span>
              <div className="content__item-imgwrap"><div className="content__item-img" style={{ backgroundImage: `url(${brassroots_01})` }}></div></div>
              <div className="content__item-deco"></div>
              <h2 className="content__item-title">Wy</h2>
              <p className="content__item-description">We'll put some happy little leaves here and there.</p>
            </div>
            <div className="content__item content__item--wide">
              <span className="content__item-number">07</span>
              <div className="content__item-imgwrap"><div className="content__item-img" style={{ backgroundImage: `url(${brassroots_02})` }}></div></div>
              <div className="content__item-deco"></div>
              <h2 className="content__item-title">Tx</h2>
              <p className="content__item-description">With something so strong, a little bit can go a long way.</p>
            </div>
            <div className="content__item content__item--wide">
              <span className="content__item-number">08</span>
              <div className="content__item-imgwrap"><div className="content__item-img" style={{ backgroundImage: `url(${brassroots_03})` }}></div></div>
              <div className="content__item-deco"></div>
              <h2 className="content__item-title">Sd</h2>
              <p className="content__item-description">There are no limits in this world.</p>
            </div>
          </div>
        </div>
      </main>

      <span className="btn_toindex" href="#" onClick={() => {toggleIndice();}}>
        INDEX
      </span>
  </motion.div>
  );
};

export default HeloSparkV4;