import React, { useRef, useEffect } from 'react';
import in_her_shoes from "../../assets/img/posters/in_her_shoes.jpg";
import ESG_launch_film from "../../assets/img/posters/ESG_launch_film.jpg";
import chillboards from "../../assets/img/posters/chillboards.jpg";
import alternate_futures from "../../assets/img/posters/alternate_futures.jpg";
import the_dot from "../../assets/img/posters/the_dot.jpg";
import brief_histories_of_the_future from "../../assets/img/posters/brief_histories_of_the_future.jpg";
import kiev_beacon_project from "../../assets/img/posters/kiev_beacon_project.jpg";
import tmrw_project from "../../assets/img/posters/tmrw_project.jpg";
import michelo_ultra from "../../assets/img/posters/michelob_ultra.jpg";
import drone_show01 from "../../assets/img/posters/drone_show01.jpg";

const ProjectsObject = [
    {
      "nombre": "The Dot",
      "autor": "Headspace",
      "extracto": "Headspace + HELO brought joy to the people of Pittsburgh at our custom-built inflatable structure we called 'The Dot'. Here, groups could meditate, children could pop balloons, and an AR overlay brought fantastical creatures to life.",
      "url": "/the-dot",
      "video540": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/387419463+(540p).mp4#t=16",
      "video1080": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/387419463+(1080p).mp4#t=16", 
      "poster": the_dot
    },
    {
      "nombre": "Dreamcaster",
      "autor": "Michelob Ultra",
      "extracto": "Despite being born blind, Cameron Black’s dream was to commentate on a live basketball game. We made that dream a reality by developing a proprietary technology using real-time and human assisted-AI.",
      "url": "/dreamcaster",
      "video540": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/848445183+(540p).mp4#t=27",
      "video1080": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/848445183+(1080p).mp4#t=27",
      "poster": michelo_ultra
    },
    {
      "nombre": "TMRW Project",
      "autor": "",
      "extracto": "The TMRW Project is an ambitious, long tail effort to propel humanity towards an intentional tomorrow. by combining the power of an ecological world-view AI with best-in-class immersive experiences. The project will open its flagship location in Washington, DC in the summer of 2025.",
      "url": "/kyiv-beacon-project",
      "video540": "https://f004.backblazeb2.com/file/b9-videos/helo/creators/tmrw_sizzle_3+(540p).mp4",
      "video1080": "https://f004.backblazeb2.com/file/b9-videos/helo/creators/tmrw_sizzle_3+(1080p).mp4", 
      "poster": tmrw_project
    },
    {
      "nombre": "Chillboards",
      "autor": "Coors Light",
      "extracto": "HELO+Coors Light Light truly chilled a neighborhood in Miami, reducing the indoor air temperature by engaging a local street artist to paint twelve rooftops white. Nominated for 7 Titanium Lions as one of the most effective initiatives of the year.",
      "url": "/chillboards",
      "video540": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/814992739+(540p).mp4#t=15",
      "video1080": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/814992739+(1080p).mp4#t=15", 
      "poster": chillboards
    },
    {
      "nombre": "The Decision",
      "autor": "Deloitte",
      "extracto": "Deloitte's new Sustainability, Climate, and Equity division needed to ignite curiosity and pride among both clients and employees, so we created an anthemic narrative-driven story that sets a complex, global challenge in a very real personal and human tone.",
      "url": "/the-decision",
      "video540": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/823069046+(540p).mp4#t=3",
      "video1080": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/823069046+(1080p).mp4#t=3", 
      "poster": ESG_launch_film
    },
    {
      "nombre": "Drone Show",
      "autor": "Climate Week NY",
      "extracto": "To kick off Climate Week NY, HELO partnered with Projecting Change and Nova Sky Stories to launch the first drone show of its kind, with 1000 drones - lighting up the NYC sky above the UN Building.",
      "url": "/drone-show",
      "video540": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/865757845+(540p).mp4#t=1",
      "video1080": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/865757845+(1080p).mp4#t=1",
      "poster": drone_show01
    },
    {
      "nombre": "In Someone Else's Shoes",
      "autor": "Santander",
      "extracto": "HELO and Santander Bank demonstrated how financial manipulation plays a role in abusive relationships by creating a harrowing immersive experience to highlight the systemic and structural dynamics of domestic violence. ",
      "url": "/in-someone-else-shoes",
      "video540": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/395415629+(540p).mp4#t=69",
      "video1080": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/395415629+(1080p).mp4#t=69",      
      "poster": in_her_shoes
    },
    {
      "nombre": "Imagining Alternate Futures",
      "autor": "Verge ClimateTech",
      "extracto": "At one of the world's largest climate tech conferences, HELO launched a prototype that allowed people to imagine a positive post-climate crisis future using generative AI. People were able to share and mix their visions with others.",
      "url": "/alternate-futures",
      "video540": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/779026413+(540p).mp4#t=10",
      "video1080": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/779026413+(1080p).mp4#t=10", 
      "poster": alternate_futures
    },
    {
      "nombre": "Kyiv Beacon Project",
      "autor": "Sebastian Kite",
      "extracto": "Installed in Kyiv, Ukraine by HELO’s Sebastian Kite for UDP Real Estate and UNIT.City. The 60M high Beacon broadcast a vertical beam of light 3km into the sky, creating a positive visual connection between past, the present and the future.",
      "url": "/kyiv-beacon-project",
      "video540": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/703471930+(540p).mp4#t=2",
      "video1080": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/703471930+(1080p).mp4#t=2", 
      "poster": kiev_beacon_project
    },
    {
      "nombre": "Brief Histories of the Future",
      "autor": "Deloitte",
      "extracto": "A series featuring discussions between global consulting co. Deloitte's Chief Futurist and innovators in their fields. Graphical overlays unground the viewer, encouraging them to think of the possibilities of tomorrow.",
      "url": "/brief-histories-of-the-future",
      "video540": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/638769232+(540p).mp4#t=6",
      "video1080": "https://f004.backblazeb2.com/file/b9-videos/helo/projects/638769232+(1080p).mp4#t=6", 
      "poster": brief_histories_of_the_future
    }
];

export default ProjectsObject;