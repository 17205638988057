import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from '@studio-freight/lenis';
import mouse from "../../assets/img/mouse.svg";
import scroll from "../../assets/img/scroll.svg";
import Menu from '../../components/submodules/Menu';
import { motion } from "framer-motion/dist/framer-motion";

SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);
gsap.registerPlugin(ScrollTrigger);


const HeloLabs = () => {

  const carousel_videos = [
    'https://player.vimeo.com/progressive_redirect/playback/632008543/rendition/720p/file.mp4?loc=external&signature=a2cbe0292980dcced5ea383992f44678d1bab1c6cfac7b58d88231abb6a8b45f',
    'https://player.vimeo.com/progressive_redirect/playback/632006510/rendition/720p/file.mp4?loc=external&signature=23c1fb99cd00981d733a526c8e72724395196833ba08c5afe3d13ccd70135aa1',
    'https://player.vimeo.com/progressive_redirect/playback/632004825/rendition/720p/file.mp4?loc=external&signature=69493d6044d19ad64b9d667df5b044f938d2357cafacf2bf5643816534d64b95',
    'https://player.vimeo.com/progressive_redirect/playback/632004036/rendition/720p/file.mp4?loc=external&signature=227413302cf6feecf4861eff19658f2b1557df432e726f7faa35bff2035b06a3'
  ];

  useEffect(() => {

    var TL_body_bg = gsap.timeline({
      defaults: {
        duration: 1,
        ease: "linear"
      },
      paused: true,
      onComplete: () => {
      },
      onStart: () => {
      }
    });
    TL_body_bg
    .fromTo('body', { backgroundColor: '#6049F8'}, { backgroundColor: '#008351'})
    .fromTo('div.overlay_fixed>div.top_', 
      { 
        background: '-moz-linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      {
        background: '-moz-linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      '<'
    )
    .fromTo('div.overlay_fixed>div.bottom_', 
      { 
        background: '-moz-linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      {
        background: '-moz-linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      '<'
    )

    gsap.from('div.row-about', {
      duration: .1,
      scrollTrigger: {
        scroller: "#about_container",
        trigger: "div.row-about",
        start: "top top",
        end: "bottom top-100",
        toggleActions: "play pause reverse reset",
        onEnter: () => {
          TL_body_bg.play();
        },
        onLeave: () => {
          TL_body_bg.reverse();
        },
        onEnterBack: () => {
          TL_body_bg.play();
        },
        onLeaveBack: () => {
          TL_body_bg.reverse();
        },
        scrub: 1
      }
    })

    var wrapper = document.querySelector('section#about_container');
    var content = document.querySelector('section#about_container>div.container-xl');
    if (wrapper!==null) {
      var lenis = new Lenis({
        wrapper: wrapper,
        content: content,
        wheelEventsTarget: window,
        lerp: 1,
        duration: 2.5,
        wheelMultiplier: .5,
        smoothTouch: true
      })
      gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
      })

      /*<----Scroll wheel on body*/
      var triggerElement = document.querySelector('body');
      var targetElement = document.getElementById('about_container');
      if (navigator.userAgent.indexOf('Firefox') !== -1) {
        triggerElement.addEventListener('wheel', (event) => {
          var wheelEvent = new WheelEvent('wheel', {
            deltaX: 0,
            deltaY: event.deltaY,
            deltaZ: 0,
            deltaMode: WheelEvent.DOM_DELTA_PIXEL,
          });
          targetElement.dispatchEvent(wheelEvent);
        });
      } else {
        triggerElement.addEventListener('mousewheel', (event) => {
          var wheelEvent = new WheelEvent('wheel', event);
          targetElement.dispatchEvent(wheelEvent);
        });
      }

    }
  }, []);

  return(
    <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
    <Menu propType={'lighten'}/>
    <style>{`
      html>body{
        background-color: #6049F8;
      }
    `}</style>
    <Helmet>
      <title>HELO - About Us</title>
      <meta name='description' content='Beginner friendly page for learning React Helmet.' />
    </Helmet>
    <main data-barba="container" data-barba-namespace="about">
      <div className="overlay_fixed">
        <div className="top_"></div>
        <div className="bottom_"></div>
      </div>
      <section id="about_container">
        <div className="container-xl helo_labs">
          <div className="row row-about">
            <div className="col-12 col-md-6 py-0 px-0 px-md-5 text-end title">
              <div className="row justify-content-end">
                <div className="col-auto text-start main_title">
                  <span>HELO LABS</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-0 info">
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="split">At HELO Labs, invention and originality is everything. Here, we meddle with the familiar - film, episodic, theatrical, original IP, even as we charge into new paradigms, testing ideas and integrating unusual formats in unexpected ways. Our alchemists here are encouraged to engineer the transcendent storytelling techniques of tomorrow. We do all this to find original and authentic avenues to reach audiences for our clients - today’s perplexing prototype becoming tomorrow’s product.HELO Labs. There’s method to our madness.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-about-helo">
            <div className="col-12 col-md-6 py-0 px-0 px-md-5 text-end title">
              <div className="row justify-content-end">
                <div className="col-auto text-start">
                  <span>
                    <small>Alternate Futures</small>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-0 info">
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="split bold">Grinch Rockefeller Plaza Tree (AI)</p>
                    <p className="split">We started the series by re-imagining one of our favorite Christmas activations that we did in support of The Grinch movie, building a 40-foot tall social media magnet in Rock Center.</p>
                    <iframe className="static_001vid" src="https://player.vimeo.com/video/632008543?h=b2a9c64da2" width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-about-helo">
            <div className="col-12 col-md-6 py-0 px-0 px-md-5 text-end title">
              <div className="row justify-content-end">
                <div className="col-auto text-start">
                  <span>
                    <small>Bassroots District Record Release Party</small>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-0 info">
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="split">With Les Enfants Terribles and creators Ari Herstedt and Andrew Lieb, HELO is developing an immersive musical experience for Las Vegas that takes audiences into the funkadelic world of 1973 through original music and immersive theater.</p>
                    <Swiper
                      direction="horizontal"
                      speed={300}
                      slidesPerView={1}
                      centeredSlides={true}
                      spaceBetween={0}
                      loop={true}
                      className="carousel_videos pt-2"
                      pagination={{
                        type: 'bullets',
                        clickable: true
                      }}
                    >
                      {carousel_videos.map((video, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="row h-100">
                              <div className="col-12">
                                <video loop id={"vid_"+index} controls>
                                    <source type="video/mp4" src={video} />
                                </video>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </motion.div>
  );
};

export default HeloLabs;