import kyle_ruddick01 from "../../assets/img/posters/kyle_ruddick01.jpg";
import kyle_ruddick02 from "../../assets/img/posters/kyle_ruddick02.jpg";
import kyle_ruddick03 from "../../assets/img/posters/kyle_ruddick03.jpg";
import kyle_ruddick04 from "../../assets/img/posters/kyle_ruddick04.jpg";
import kyle_ruddick05 from "../../assets/img/posters/kyle_ruddick05.jpg";
import kyle_ruddick06 from "../../assets/img/posters/kyle_ruddick06.jpg";
import kyle_ruddick07 from "../../assets/img/posters/kyle_ruddick07.jpg";
import nepyru from "../../assets/img/posters/nepyru.jpg";
import nepyru_02 from "../../assets/img/posters/nepyru_02.jpg";
import sara_nesson01 from "../../assets/img/posters/sara_nesson01.jpg";
import sara_nesson02 from "../../assets/img/posters/sara_nesson02.jpg";
import sara_nesson03 from "../../assets/img/posters/sara_nesson03.jpg";
import sara_nesson04 from "../../assets/img/posters/sara_nesson04.jpg";
import sara_nesson05 from "../../assets/img/posters/sara_nesson05.jpg";
import set_free from "../../assets/img/posters/set_free.png";
import william_maher01 from "../../assets/img/posters/william_maher01.jpg";
import william_maher02 from "../../assets/img/posters/william_maher02.jpg";
import william_maher03 from "../../assets/img/posters/william_maher03.jpg";
import william_maher04 from "../../assets/img/posters/william_maher04.jpg";
import william_maher05 from "../../assets/img/posters/william_maher05.jpg";
import william_maher06 from "../../assets/img/posters/william_maher06.jpg";
import william_maher07 from "../../assets/img/posters/william_maher07.jpg";
import kk_barret from "../../assets/img/posters/kk_barret.jpg";
import nathan_crowley from "../../assets/img/posters/nathan_crowley.jpg";
import jon_carlos01 from "../../assets/img/posters/jon_carlos01.jpg";
import damian_madray01 from "../../assets/img/posters/damian_madray01.jpg";
import jessica_kantor from "../../assets/img/posters/jessica_kantor.jpg";
import anthony_nikolchev from "../../assets/img/posters/anthony_nickolchev.jpg";
import sebastian_kite from "../../assets/img/posters/sebastian_kite.jpg";
import les_enfants_terribles from "../../assets/img/posters/les_enfants_terribles.jpg";

const CreatorsObject = [
  {
    "nombre": "Kyle Ruddick",
    "sub": "Filmmaker, Futurist, Documentarian, Animator, Wave-Rider. OR Bringing real people and experiences to film.",
    "url": "/kyle-ruddick",
    "clasificacion": ["ALL", "EXPERIENCES", "CONTENT", "TECHNOLOGY"],
    "extracto": "Kyle Ruddick is a groundbreaking documentary director and experience designer known for futurist themes that blend emerging technology and cinematic visuals to tell powerful human stories.",
    "contenido": [
      "Kyle Ruddick is a groundbreaking documentary director and experience designer known for using emerging technology and cinematic visuals to tell powerful human stories. His documentary feature “ONE DAY ON EARTH”, made history, with filming in each country taking place on the exact same day. This project helped create both a global network of filmmakers and non-profits, as well as a collaboration with the United Nations. Additionally, Kyle has directed visually stunning projects like the THX trailer “AMAZING LIFE”, released alongside the first Avatar film, and helmed a PBS series focused on exploring the future of American cities. He also directed a documentary series called “SINGAPORE REIMAGINED”, which delved into the future of technology in Singapore. Currently, he is working on a documentary that investigates the impact of AI on humanity, incorporating artificial intelligence characters and other transformative AI technologies. As a commercial director, Kyle uses his expertise to portray authentic experiences and emotion in his work. He has worked with clients such as McDonald's, Lucasfilm, Minute Maid, Verizon, Anthem, Deloitte, Blizzard, Cheerios, 3M, EA, Fan Duel, and United Airlines, among others.",
    ],
    "videos": [
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/674902517+(540p).mp4#t=2",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/674902517+(1080p).mp4#t=2",
        "poster": kyle_ruddick01,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/638769232+(540p).mp4#t=6",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/638769232+(1080p).mp4#t=6",
        "poster": kyle_ruddick02,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/632110221+(540p).mp4#t=1",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/632110221+(1080p).mp4#t=1",
        "poster": kyle_ruddick03,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/153273956+(540p).mp4#t=4",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/153273956+(1080p).mp4#t=4",
        "poster": kyle_ruddick04,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/529118767+(540p).mp4#t=1",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/529118767+(1080p).mp4#t=1",
        "poster": kyle_ruddick05,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/194433877+(540p).#t=3",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/194433877+(1080p).#t=3",
        "poster": kyle_ruddick06,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/75542454+(540p).mp4#t=15",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/75542454+(1080p).mp4#t=15",
        "poster": kyle_ruddick07,
      }
    ]
  },
  {
    "nombre": "Jon Carlos",
    "sub": "Jon Carlos is an Academy and Guild-nominated production designer.",
    "url": "/jon-carlos",
    "clasificacion": ["ALL", "DESIGN"],
    "extracto": "Jon Carlos is an Academy and Guild-nominated production designer credited with internationally recognized work such as HBO's Westworld and Apple Studios' “Palm Royale”",
    "contenido": [
      "With an award-winning career spanning over two decades, Jon has a diverse portfolio of credits to his name including Apple Studios' “Palm Royale”, debuting in Fall 2023, HBO's “Westworld”, and “Hacks” - for which he received Emmy and Guild nominations for Outstanding Production Design.",
      "From Cuba to Hong Kong, Jon's work has taken him around the world crafting iconic scenes and visuals for live action, animation, CGI and experiential projects. You will have seen Jon's creative eye on “Mad Men”, “Fate of the Furious”, “Furious 7”, “War Dogs”, “Halt & Catch Fire”, and “Flight”.",
      "Jon's skillset includes digital drafting and modeling, concept illustration, and graphic design. His eye for style, presentation, and taste are equalled by his ability to lead his team to consistently provide superlative environments while balancing a production's logistic and scheduling needs.",
    ],
    "videos": [
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/848465184+(540p).mp4#t=11",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/848465184+(1080p).mp4#t=11",
        "poster": jon_carlos01,
      },
    ]
  },
  {
    "nombre": "NEPYRU",
    "sub": "Technology Engineered for Wonder",
    "url": "/nepyru",
    "clasificacion": ["ALL", "TECHNOLOGY"],
    "extracto": "NEPYRU is a an award-winning technology studio specializing in creative brand experiences. Their approach to creativity connects the physical with digital through seamless technology integrations, visual design, and traditional storytelling.",
    "contenido": [
      "NEPYRU is a technology studio specializing in creative brand experiences. As an engineering-led, award-winning, creative technology company, every project represents an opportunity to craft something wonderful. NEPYRU deeply understands the emerging world of hybrid, digital, interactive, and mixed realities, and they provide consultation services, product ideas, and creative executions to help brands and their audiences navigate these landscapes. With 25 years of experience shaping campaigns and executions for brands, as well as access to creative studios and agencies across the globe, the NEPYRU team has delivered award-winning work for a range of global clients including Bentley, Ford, Frito-Lays, Honda, IBM, L'Oréal, Louis Vuitton, Nike, Porsche, Range Rover, and Toyota. NEPYRU has also provided research and development services to companies to shape the future of brand engagement, such as Meta's Creative Shop and Spark Studio, Microsoft's Hololens and Magic Leap, refining Deep Machine Learning, and Spatial Perception and Computer Vision modelling. Their partnership model and interdisciplinary approach to creativity connects the physical with digital through seamless technology integrations, visual design, and traditional storytelling. They have been recognized at the Cannes Lions, FWAs, and Webby Awards, and other industry major industry awards.",
    ],
    "videos": [
      {

        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/644079131+(540p).mp4#t=2",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/644079131+(1080p).mp4#t=2",
        "poster": nepyru,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/848445183+(540p).mp4#t=28",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/848445183+(1080p).mp4#t=28",
        "poster": nepyru_02,
      },
    ]   
  },
  {
    "nombre": "Sara Nesson",
    "sub": "Sara Nesson is multi-award winning, Oscar and EMMY-nominated director, cinematographer, and editor.",
    "url": "/sara-nesson",
    "clasificacion": ["ALL"],
    "extracto": "Sara Nesson is an award-winning filmmaker, know for her vérité style cinematography. In addition to documentaries, she has also directed music videos, experiential films, and has created commercial content.",
    "contenido": [
      "Her elevated verité style allows for freedom of movement and a deeper intimacy with her subjects. Her vision to capture the heart and spirit of the human condition nourishes her passion for filmmaking and the never-ending search for connection and meaning. Sara believes in packing a big punch into the short form. She is best known for her films “Poster Girl” and “Women Who Score”. She has also directed music videos, experiential films, and commercial content for iconic names such as Cirque du Soleil, Bonnaroo Music Festival, Deloitte, and more."
    ],
    "videos": [
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/405981499+(540p).mp4#t=4",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/405981499+(1080p).mp4#t=4",
        "poster": sara_nesson01,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/638709454+(540p).mp4#t=1",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/638709454+(1080p).mp4#t=1",
        "poster": sara_nesson02,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/638723756+(540p).mp4#t=1",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/638723756+(1080p).mp4#t=1",
        "poster": sara_nesson03,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/638724445+(540p).mp4#t=1",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/638724445+(1080p).mp4#t=1",
        "poster": sara_nesson04,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/633022989+(540p).mp4#t=3",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/633022989+(1080p).mp4#t=3",
        "poster": sara_nesson05,
      }
    ]   
  },
  {
    "nombre": "Set Free Richardson",
    "sub": "Set Free Richardson is a director and experimental creative who considers himself a cultural conduit, connecting brands to niche lifestyles and specializing in the entertainment, sports, and fashion industries.",
    "url": "/set-free-richardson",
    "clasificacion": ["ALL", "EXPERIENCES", "CONTENT"],
    "extracto": "Set Free Richardson is an award-winning director and experimental creative who considers himself a cultural conduit, connecting brands to niche lifestyles and specializing in the entertainment, sports, and fashion industries.",
    "contenido": [
      "Born in The Bronx and raised in Philadelphia, Set Free Richardson, founder of Compound - a creative collective and gallery merging art, fashion, sports, and music  - is known for incorporating his experiences with urban culture into breakthrough ideas. Set Free's ability to bring unique and authentic marketing ideas to the table has made him one of the most sought-after creatives in the industry, working with clients like Nike, Adidas, EA Sports, among many others.",
      "His role in creating the AND1 Mixtapes propelled the AND1 brand into a multi-million-dollar franchise and international sensation. Always on the pulse of what’s happening, Set Free has leveraged his ties to various industries to become the creative go-to in the entertainment field as well as public tastemaker.",
    ],
    "videos": [
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/667884706+(540p).mp4#t=1",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/667884706+(1080p).mp4#t=1",
        "poster": set_free,
      },
    ]   
  },
  {
    "nombre": "William Maher",
    "sub": "Dublin-born Director William (Bill) Maher wears many hats as a writer, director, and creative director for commercials, feature films, experiential activations, and more.",
    "url": "/william-maher",
    "clasificacion": ["ALL", "EXPERIENCES", "CONTENT"],
    "extracto": "Dublin-born Director William (Bill) Maher wears many hats as a writer, director, and creative director for commercials, feature films, experiential activations, and more.",
    "contenido": [
      "His notable commercial work includes films and ads for Deloitte, Michelob Ultra, Dick’s Sporting Goods, Mini, Adidas, and New Balance, in addition to experiential activations for The Grinch, Sorel, and Jurassic World. His Direction and Creative Direction of Michelob Ultra ‘Dreamcaster’ in 2023 won the Cannes Grand Prix for Sport, among many other Lions, Clios and a host of other industry accolades.",
      "William's creative journey started in architectural model making, then moved into the world of feature Art Department and VFX. Beyond the world of advertising, his diverse creative experience has led him to write a motion graphic novel series, and he maintains a parallel career as a feature film director.",
    ],
    "videos": [
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/DREAMCASTER_HELO_CASE_STUDY(540).mp4#t=7",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/DREAMCASTER_HELO_CASE_STUDY(1080).mp4#t=7",
        "poster": william_maher07,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/532407045+(540p).mp4#t=2",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/532407045+(1080p).mp4#t=2",
        "poster": william_maher01,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/394412779+(540p).mp4#t=3",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/394412779+(1080p).mp4#t=3",
        "poster": william_maher02,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/416396714+(540p).mp4#t=5",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/416396714+(1080p).mp4#t=5",
        "poster": william_maher05,
      },
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/823069046+(540p).mp4#t=1",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/823069046+(1080p).mp4#t=1",
        "poster": william_maher06,
      },
    ]   
  },
  {
    "nombre": "KK Barrett",
    "sub": "KK Barrett is a multi-award-winning production designer and director.",
    "url": "/kk-barret",
    "clasificacion": ["ALL", "DESIGN"],
    "extracto": "KK Barrett is a multi-award-winning production designer and director. He is known for his work on iconic music videos for artists like Beck, world-famous films like “HER”, and commercials for international brands such as Nissan and GE.",
    "contenido": [
      "From his presence in the avant-synth music scene in the 1970s and the early hip-hop scene of the 1980s, KK Barrett broke onto the design scene with such iconic music videos as Beck's “New Pollution” and “Smashing Pumpkins”, “Tonight, Tonight”, both of which earned him the MTV Video Music Award for Best Art Direction. From there, he partnered with Spike Jonze to bring moviegoers into the fascinating world of “Being John Malkovich” 1999. He's since served as production designer on films such as “Adaptation”, “Lost in Translation”, “I Heart Huckabees”, and “Where the Wild Things Are”.",
      "Barrett was nominated for an Academy Award for Best Production Design for his futurist look at our life with technology in Jonze's film, “HER”. His extensive design experience in the commercial space includes designing spots for brands such as Nissan, GE, Levi's, Budweiser, Xfinity, Audi and others. In the experiential and directing realm, he’s created a genre-mashing adaptation of Kid Koala's graphic novel, “Nufonia Must Fall”, the live theatrical event “Stop the Virgens,”an opera he co-created with Karen O, and numerous commercial projects for brands including Levi's and Disney Theme Parks.",
    ],
    "videos": [
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/652663676+(540p).mp4#t=3",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/652663676+(1080p).mp4#t=3",
        "poster": kk_barret,
      },
    ]   
  },
  {
    "nombre": "Nathan Crowley",
    "sub": "Nathan Crowley is an internationally renowned production designer and a former art director.",
    "url": "/nathan-crowley",
    "clasificacion": ["ALL", "DESIGN"],
    "extracto": "Nathan Crowley is an internationally renowned production designer and a former art director. Crowley's relentlessly original and meticulously crafted design designate him one of the most sought-after creatives in the world.",
    "contenido": [
      "Crowley's relentlessly original and meticulously crafted design concepts have made him one of the most sought-after production and experience designers in the world. He has been nominated for an Academy Award for Best Production Design for “The Prestige”, “The Dark Knight”, and “Interstellar”. With the Batman Franchise, he earned a 2005 BAFTA nomination for “Batman Begins” and an Art Directors Guild award for “The Dark Knight”.",
      "Other production design credits include “The Dark Knight Rises”, “John Carter”, “Public Enemies” and the WWII epic, “Dunkirk”. He was also the conceptual designer of HBO's series “Westworld”.",
      "His commercial and experiential work includes work for leading global brands such as Captain Morgan, Amazon, and Prada.",
    ],
    "videos": [
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/542869508+(540p).mp4#t=11",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/542869508+(1080p).mp4#t=11",
        "poster": nathan_crowley,
      },
    ]   
  },
  {
    "nombre": "Sebastian Kite",
    "sub": "Sebastian Kite is a highly regarded installation artist based in London and southwest France.",
    "url": "/sebastian-kite",
    "clasificacion": ["ALL", "EXPERIENCES", "DESIGN"],
    "extracto": "Sebastian Kite is a London and Southwest France-based installation artist. His works explore of the intersection between art, architecture, and music, with a particular focus on site-specific installations that challenge perceptions of space.",
    "contenido": [
      "His artistic practice revolves around the exploration of the intersection between art, architecture, and music, with a particular focus on site-specific installations that challenge traditional perceptions of space.",
      "To date, Kite's artistic practice spans various disciplines including installation, sculpture, and public art. His immersive environments are characterised by their architectural scale, immersing the audience in a multidimensional experience that blurs the boundaries between the tangible and intangible. By placing the viewer at the center of the artwork, Kite disrupts our relationship with the spaces we inhabit, prompting a conscious engagement and questioning of our perception of time. With a holistic creative approach, Kite employs light, kinetics, sound, moving-image, technology, sculpture and performance to illustrate his vision.",
      "Currently, Kite’s practice engages with the complex interplay between memory and trauma. Through immersive installations, he strives to create transformative experiences that invite viewers to reflect on their own personal narratives and the broader collective consciousness.",
    ],
    "videos": [
      {
        "540": "https://f004.backblazeb2.com/file/b9-videos/helo/creators/638769232+(540p).mp4#t=2",
        "1080": "https://f004.backblazeb2.com/file/b9-videos/helo/creators/638769232+(1080p).mp4#t=2",
        "poster": sebastian_kite,
      },
    ]   
  },
  {
    "nombre": "Les Enfants Terribles",
    "sub": "Les Enfants Terribles is an innovative theatre company known for striking visual aesthetic and innovative use of props, puppetry, and live music.",
    "url": "/les-enfants-terribles",
    "clasificacion": ["ALL", "EXPERIENCES"],
    "extracto": "Les Enfants Terribles is an innovative theatre company known for striking visual aesthetic and innovative use of props, puppetry, and live music, as well as their focus on putting the audience at the centre of the story.",
    "contenido": [
      "They were founded by Artistic Director Oliver Lansley in 2001 with long-time collaborator and Creative Director James Seager coming on board in 2005. Focused on putting the audience at the centre of the story, Les Enfants Terribles have toured worldwide, performed at over 400 UK venues, and received critical acclaim and top industry awards, including an Olivier Award nomination. One of their most famous shows, “Alice's Adventures Underground”, has been seen by over 350,000 people and is returning to London in 2023, after a two-year run in Shanghai, to a brand-new, purpose-built venue in the heart of the capital. With their expertise in devising unforgettable experiences, Les Enfants Terribles have partnered with a diverse range of brands throughout the cultural & corporate sectors in the UK, Europe and globally, including IKEA, Toyota, Canon, Disney, Heineken and PlayStation, and have reached over 56 million people on social media, received 2.5 million views online and helped raise over one million pounds for charity."
    ],
    "videos": [
      {
        "540": "https://f004.backblazeb2.com/file/b9-videos/helo/creators/643587263(540p).mp4#t=1",
        "1080": "https://f004.backblazeb2.com/file/b9-videos/helo/creators/643587263(1080p).mp4#t=1",
        "poster": les_enfants_terribles,
      },
    ]   
  },
  {
    "nombre": "Anthony Nikolchev",
    "sub": "Anthony Nikolchev is an experiential director, creator, award-winning actor, writer, and theater-maker.",
    "url": "/anthony-nikolchev",
    "clasificacion": ["ALL", "EXPERIENCES"],
    "extracto": "Anthony Nikolchev is an experiential director, creator, award-winning actor, writer, and theater-maker with an international portfolio. His work combines dancers and actors to make physically explosive, moving experiences.",
    "contenido": [
      "His work combines dancers and actors to make physically explosive, moving experiences on film, video, and live. He has directed live and streaming branded experiential events for clients such as Jack in the Box, Dos Equis, Amazon, and Twitch - recently yielding the second most unique views on Twitch's platform for the live escape room promoting Amazon's “Jack Ryan”.",
      "As an immersive theatre experiential creator and performer, he has collaborated with groups such Wilderness, choreographer and “Sleep No More” star Sophie Bortolussi, Source Material at the Pina Bausch Tanztheater in Germany, and the San Diego Opera. Anthony is also responisble for devising and performing at the LA Weekly’s immersive gold standard hit, “The Day Shall Declare It”, and guest speaker activations at Cannes Lions and LE Miami.",
    ],
    "videos": [
      {
        "540": "https://f004.backblazeb2.com/file/b9-videos/helo/creators/DREAMCASTER_HELO_CASE_STUDY(540).mp4#t=2",
        "1080": "https://f004.backblazeb2.com/file/b9-videos/helo/creators/anthony_nikolchev_overview+(1080p).mp4#t=2",
        "poster": anthony_nikolchev,
      },
    ]   
  },
  {
    "nombre": "Damian Madray",
    "sub": "Damian Madray is an experiential director whose work aims to provoke change and impact.",
    "url": "/damian-madray",
    "clasificacion": ["ALL", "EXPERIENCES", "DESIGN"],
    "extracto": "Damian Madray is an experiential director whose work aims to provoke change and impact. Throughout his career, Damian has synthesized complex concepts into engaging and accessible experiences that captivate and inspire.",
    "contenido": [
      "Throughout his career, Damian has synthesized complex concepts into engaging and accessible experiences that captivate and inspire. As the founder of Parousia, an innovative audio studio, Damian has pioneered a unique concept of streaming spatial audio specifically designed for psychedelic journeys. These immersive experiences have proven beneficial for a variety of wellness needs, such as calming the mind, easing anxiety, promoting relaxation, and integrating psychedelic-assisted therapies.",
      "Damian's cultural work at TheGlint, a platform that connects and inspires change-makers, artists, and technologists, has been featured in major media outlets, including the New York Times, Bold Italics, Huffington Post, Slate, Wall Street Journal, and Financial Times, underlining the reach and significance of his contributions. As the leader of experiential design for EQ's “PLANET HOME”, Damian oversaw the successful execution of a large-scale immersive environment promoting climate solutions. The event brought together 10,000 attendees, thought leaders, and artists from around the globe in a powerful dialogue centered on sustainable solutions to global challenges.",
    ],
    "videos": [
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/835494810+(540p).mp4#t=5",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/835494810+(1080p).mp4#t=5",
        "poster": damian_madray01,
      },
    ] 
  },
  {
    "nombre": "Jessica Kantor",
    "sub": "Jessica Kantor is a Los Angeles-based writer, director, and producer. She is a highly sought-after interdisciplinary artist, most known for her virtual and augmented reality work.",
    "url": "/jessica-kantor",
    "clasificacion": ["ALL", "TECHNOLOGY", "EXPERIENCES", "CONTENT"],
    "extracto": "Jessica Kantor is a Los Angeles-based writer, director, and producer. She is a highly sought-after interdisciplinary artist, most known for her virtual and augmented reality work.",
    "contenido": [
      'Jessica Kantor is a Los Angeles-based writer, director, and producer. She is a highly sought-after interdisciplinary artist, most known for her virtual and augmented reality work. Jessica was an artist in residence at Technicolor, a Google JUMP creator, Oculus VR for Good Fellow, and she had her work premiere at film festivals worldwide. Having developed a perspective of place, movement, and world-building, Jessica brings that new thinking to traditional filmmaking. Jessica shares her passion for cinema on her podcast, "Raising Cinephiles," where she interviews industry insiders on how they fell in love with film and discusses how they share their passion for cinema with the next generation. Last year, Jessica wrote "The Date Whisperer," a romantic comedy produced by ReelOne Entertainment. In addition, Jessica became a Producer’s Guild Innovation Award finalist for her work as lead producer on "Live@Expo." Jessica also has an interactive exhibition currently on view at The Wing Luke Museum in Seattle, titled "Be Water My Friend, The Teachings of Bruce Lee."',
    ],
    "videos": [
      {
        "540":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/854507165+(540p).mp4#t=3",
        "1080":"https://f004.backblazeb2.com/file/b9-videos/helo/creators/854507165+(1080p).mp4#t=3",
        "poster": jessica_kantor,
      },
    ] 
  }
];




export default CreatorsObject;