import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import creators from "../../assets/data/creators.json";
import Menu from '../../components/submodules/Menu';
import CreatorsObject from '../../components/submodules/CreatorsObject';

import { motion } from "framer-motion/dist/framer-motion";
import plus_dark from "../../assets/img/plus_dark.svg";

SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);

const CreatorsVideoFullScreenPreventClick = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth<992 ? true : false);
  const [videoSwiper, setVideoSwiper] = useState(null);
  const [infoSwiper, setInfoSwiper] = useState(null);
  const [showAll, setShowAll] = useState(null);
  const [creatorsOriginal] = useState([...CreatorsObject]);
  const [creatorsInfo, setCreatorsInfo] = useState([]);
  const [creatorsVideo, setCreatorsVideo] = useState([]);
  const [maxSlide] = useState(CreatorsObject.length+3);
  const [minSlide] = useState(2);

  //const [reloadKey, setReloadKey] = useState(0);


  //const reloadComponent = () => {
  //  setReloadKey(prevKey => prevKey + 1);
  //};

  const navigate = useNavigate();

  function goToView(route){
    document.querySelector("html").classList.add("exit");
    setTimeout( function() {
      navigate(route);
    }, 500);
  }

  const reloadSameView = () => {
    console.log('reloadSameView function');
    //reloadComponent();
    navigate('/creators', { replace: true });
  };



  useEffect(() => {
    window.addEventListener('resize', function() {
      var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (windowWidth<992) {
        if (isMobile===false) {
          //setIsMobile(true);
          reloadSameView();
        }
        
      }else{
        if (isMobile===true) {
          //setIsMobile(false);
          reloadSameView();
        }
        
      }
    });
  }, [isMobile]);

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
  }, []);

  useEffect(() => {
    if (!isMobile && creatorsInfo.length===0 && creatorsVideo.length===0) {
      var firstItems = [
        creatorsOriginal[0],
        creatorsOriginal[1],
        creatorsOriginal[2]
      ]
      var ultimoItem = creatorsOriginal[creatorsOriginal.length - 1];
      var penultimoItem = creatorsOriginal[creatorsOriginal.length - 2];
      var antePenultimoItem = creatorsOriginal[creatorsOriginal.length - 3];
      setCreatorsInfo([ultimoItem, penultimoItem, antePenultimoItem, ...creatorsOriginal, ...firstItems]);
      setCreatorsVideo([ultimoItem, penultimoItem, antePenultimoItem, ...creatorsOriginal, ...firstItems].reverse());
    }
  }, [CreatorsObject]);

  useEffect(() => {
    let creators_temp = [];
    if (isMobile) {
      creators_temp = creatorsOriginal;
    }else{
      creators_temp = creatorsVideo;
    }
    if (videoSwiper!==null && creators_temp.length>0) {
      function autoPlayVideo(){
        var activeIndex = videoSwiper.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        activeSlideVideo.play();
      }
      videoSwiper.on('transitionStart', function (swipe) {
        var videos = document.querySelectorAll('video');
        Array.prototype.forEach.call(videos, function(video){
          video.pause();
        });
      });
      videoSwiper.on('transitionEnd', function (swipe) {
        var activeIndex = swipe.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        activeSlideVideo.play();
      });
      autoPlayVideo();
    }
  }, [videoSwiper, creatorsVideo, creatorsOriginal, isMobile]);

  useEffect(() => {
    if (videoSwiper!==null && maxSlide!==null && minSlide!==null && isMobile===false) {
      videoSwiper.on('transitionEnd', function (swipe) {
        if (window.hover_state==='videos') {
          //console.log('transitionEnd videoSwiper');
          var activeIndex = swipe.activeIndex;
          if (activeIndex>=maxSlide) {
            videoSwiper.slideTo(minSlide+1, 1, true);
          }
          if (activeIndex<=minSlide) {
            videoSwiper.slideTo(maxSlide-1, 1, true);
          }
        }
      });
      videoSwiper.on('slideChangeTransitionEnd', function (swipe) {
        if (swipe.activeIndex===maxSlide-1 || swipe.activeIndex===minSlide+1) {
          //console.log('videoSwiper updated');
          videoSwiper.update();
        }    
      });
    }
  }, [videoSwiper, maxSlide, minSlide, isMobile]);

  useEffect(() => {
    if (infoSwiper!==null && maxSlide!==null && minSlide!==null && isMobile===false) {
      infoSwiper.on('transitionEnd', function (swipe) {
        if (window.hover_state==='info') {
          //console.log('transitionEnd infoSwiper');
          var activeIndex = swipe.activeIndex;
          if (activeIndex>=maxSlide) {
            infoSwiper.slideTo(minSlide+1, 1, true);
          }
          if (activeIndex<=minSlide) {
            infoSwiper.slideTo(maxSlide-1, 1, true);
          }
        }
      });
      infoSwiper.on('slideChangeTransitionEnd', function (swipe) {
        if (swipe.activeIndex===maxSlide-1 || swipe.activeIndex===minSlide+1) {
          //console.log('infoSwiper updated');
          infoSwiper.update();
        }        
      });
    }
  }, [infoSwiper, maxSlide, minSlide, isMobile]);

  useEffect(() => {
    if (!isMobile) {
    window.hover_state = null;
      let element_videos = document.getElementById("videos");
      let element_info = document.getElementById("info");
      if (element_videos!==null && element_info!==null) {
        element_videos.addEventListener("mouseover", function (event) {
          window.hover_state='videos';
          //console.log('isMouseHover videos');
        }, false);
        element_info.addEventListener("mouseover", function (event) {
          window.hover_state='info';
          //console.log('isMouseHover info');
        }, false);
      }
    }
  }, [isMobile]);

  useEffect(() => {
    if (videoSwiper!==null) {
      videoSwiper.on('transitionStart', function () {
        window.hover_state='videos';
        //console.log('isMouseHover videos');
      });
    }
  }, [videoSwiper]);

  useEffect(() => {
    if (infoSwiper!==null) {
      infoSwiper.on('transitionStart', function () {
        window.hover_state='info';
        //console.log('isMouseHover info');
      });
    }
  }, [infoSwiper]);

  function toggleFullScreen(element) {
    if (element.requestFullscreen) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        element.requestFullscreen();
      }
    } else if (element.mozRequestFullScreen) {
      if (document.mozFullScreenElement) {
        document.mozCancelFullScreen();
      } else {
        element.mozRequestFullScreen();
      }
    } else if (element.webkitRequestFullscreen) {
      if (document.webkitFullscreenElement) {
        document.webkitExitFullscreen();
      } else {
        element.webkitRequestFullscreen();
      }
    } else if (element.msRequestFullscreen) {
      if (document.msFullscreenElement) {
        document.msExitFullscreen();
      } else {
        element.msRequestFullscreen();
      }
    }
  }

  useEffect(() => {
    if (creatorsVideo.length>0) {
      var videos = document.querySelectorAll('#videos .swiper-slide>div>div>video');
      videos.forEach(function(video){
        video.addEventListener("click", function(event){
          event.preventDefault();
          toggleFullScreen(video);
        });
        video.addEventListener('fullscreenchange', function() {
          var sources = video.getElementsByTagName('source');
          if (document.fullscreenElement) {
            // El video está en modo de pantalla completa
            console.log('El video está en pantalla completa');
            var maxWidth = window.innerWidth;
            var selectedSource = null;
            // Recorrer las fuentes de video y seleccionar la de mayor calidad compatible
            for (var i = 0; i < sources.length; i++) {
              var source = sources[i];
              var mediaQuery = source.getAttribute('media');
              if (window.matchMedia(mediaQuery).matches) {
                selectedSource = source;
              }
            }
            // Cambiar la fuente del video a la de mayor calidad
            if (selectedSource) {
              video.src = selectedSource.src;
            }
            video.currentTime = 1;
            video.muted = false;
          } else {
            // El video ha salido del modo de pantalla completa
            console.log('El video ha salido de pantalla completa');
            video.src = sources[0].src;
            video.muted = true;
          }
        });
      });
    }
  }, [creatorsVideo]);

  return(
  <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
  <Menu propType={'lighten'} propActive={'creators'} />
  <style>{`
    .menu_dev {
        z-index: 200;
    }
  `}</style>
  <Helmet>
    <title>HELO - Creators</title>
    <meta name='description' content='' />
  </Helmet>

    <section className="fix_overflow darked" /*key={reloadKey} id={reloadKey}*/>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 col-lg-6 p-0" id="videos">
            <div>
              <Swiper
                direction="vertical"
                speed={300}
                slidesPerView={isMobile?1:3}
                centeredSlides={!isMobile?true:false}
                spaceBetween={0}
                mousewheel={true}
                loop={isMobile}
                onSwiper={setVideoSwiper}
                initialSlide={3}
                centeredSlidesBounds={!isMobile}
                loopedSlides={isMobile?3:null}
                preventClicksPropagation={true}
                // controller={{ control: infoSwiper}}
                controller={{ control: infoSwiper, inverse: !isMobile }}
              >
                {isMobile?
                  <React.Fragment>
                    {creatorsOriginal.map((creator, index) => {
                      return (
                        <SwiperSlide key={index} slidekey={index} creator={creator.nombre}>
                          <div className="row h-100 m-0">
                            <div
                              className="col-12 p-relative"
                            >
                              <video loop muted preload="metadata" id={"vid_"+index} alt={creator.nombre} poster={creator.videos[0]['poster']}>
                                  <source type="video/mp4" src={creator.videos[0]['360']} media="all and (max-width: 480px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['540']} media="all and (max-width: 540px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['720']} media="all and (max-width: 720px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['1080']} media="all and (max-width: 1080px)"/>
                              </video>
                            </div>
                            <Link
                              className="go_to"
                              onClick={() => {
                                goToView(creator.url);
                              }}
                            >
                              GO TO CREATOR
                            </Link>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </React.Fragment>
                :''}
                {!isMobile?
                  <React.Fragment>
                    {creatorsVideo.map((creator, index) => {
                      return (
                        <SwiperSlide key={index} slidekey={index} creator={creator.nombre}>
                          <div className="row h-100 m-0">
                            <div className="col-12">
                              <video loop muted preload="metadata" id={"vid_"+index} alt={creator.nombre} poster={creator.videos[0]['poster']}>
                                  <source type="video/mp4" src={creator.videos[0]['360']} media="all and (max-width: 480px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['540']} media="all and (max-width: 540px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['720']} media="all and (max-width: 720px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['1080']} media="all and (max-width: 1080px)"/>
                              </video>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </React.Fragment>
                :''}
              </Swiper>
              <div className="overlay_videos">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 p-0" id="info">
            <div>
              <Swiper
                direction="vertical"
                speed={300}
                slidesPerView={3}
                centeredSlides={true}
                spaceBetween={0}
                mousewheel={true}
                loop={isMobile}
                onSwiper={setInfoSwiper}
                initialSlide={3}
                centeredSlidesBounds={!isMobile}
                loopedSlides={isMobile?3:null}
                preventClicksPropagation={true}
                // controller={{ control: videoSwiper }}
                controller={{ control: videoSwiper, inverse: !isMobile }}
                breakpoints={{ 
                  0: {
                    slidesPerView: 5,
                    spaceBetween: 0
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 0
                  }
                }}
              >
                {isMobile?
                  <React.Fragment>
                    {creatorsOriginal.map((creator, index) => {
                      return (
                        <SwiperSlide key={index} slidekey={index} creator={creator.nombre}>
                          <div className="row align-items-center justify-content-center h-100">
                            <div className="col-12">
                              <Link
                                className="title"
                                onClick={() => {
                                  goToView(creator.url);
                                }}
                              >
                                {creator.nombre}
                              </Link>
                              <span className="sub">{creator.sub}</span>
                              <span>{creator.extracto}</span>
                              {/*<a className="link" onClick={() => {showCreator(index);}}>+ Know More</a>*/}
                              <Link
                                className="link"
                                component={RouterLink}
                                to={creator.url}
                              >
                                + Know More
                              </Link>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </React.Fragment>
                :''}
                {!isMobile?
                  <React.Fragment>
                    {creatorsInfo.map((creator, index) => {
                      return (
                        <SwiperSlide key={index} slidekey={index} creator={creator.nombre}>
                          <div className="row align-items-center justify-content-center justify-content-md-start h-100">
                            <div className="col-12">
                              <Link
                                className="title"
                                onClick={() => {
                                  goToView(creator.url);
                                }}
                              >
                                {creator.nombre}
                              </Link>
                              <span>{creator.extracto}</span>
                              {/*<a className="link" onClick={() => {showCreator(index);}}>+ Know More</a>*/}
                              <Link
                                className="link"
                                component={RouterLink}
                                to={creator.url}
                              >
                                + Know More
                              </Link>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </React.Fragment>
                :''}
              </Swiper>
            </div>
            <div className="overlay_info">
              <div></div>
              <div></div>
            </div>
          </div>
{/*          <a className={`show_all ${showAll ? "active" : ""}`} onClick={() => {setShowAll(!showAll);}}>
            {showAll?
              <img className="" src={plus_dark} alt="" />
            :'VIEW ALL'}          
          </a>*/}
        </div>
      </div>
    </section>
    <div className="newContainerVideo">
      <div className="close">
        <div></div>
      </div>
    </div>
  </motion.div>
  );
};

export default CreatorsVideoFullScreenPreventClick;