import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { gsap } from "gsap";
import Menu from '../../components/submodules/Menu';
import { motion } from "framer-motion/dist/framer-motion";
import poster_home from "../../assets/img/poster_home.webp";
import poster_home_mov from "../../assets/img/poster_home_mov.webp";

import video_home_1080_mp4 from "../../assets/img/video_home/desk/1080.mp4";
import video_home_1080_webm from "../../assets/img/video_home/desk/1080.webm";
import video_home_1080_ogg from "../../assets/img/video_home/desk/1080.ogg";
import video_home_1080_mov from "../../assets/img/video_home/desk/1080.mov";

import video_878_mp4 from "../../assets/img/video_home/mov/video_878.mp4";
import video_878_ogg from "../../assets/img/video_home/mov/video_878.ogg";
import video_878_webm from "../../assets/img/video_home/mov/video_878.webm";

import logo_part_1 from "../../assets/img/logo_partes/logo_letra_01.svg";
import logo_part_2 from "../../assets/img/logo_partes/logo_letra_02.svg";
import logo_part_3 from "../../assets/img/logo_partes/logo_letra_03.svg";
import logo_part_4 from "../../assets/img/logo_partes/logo_letra_04.svg";

import lett_01 from "../../assets/img/logo_partes/lett_01.svg";
import lett_02 from "../../assets/img/logo_partes/lett_02.svg";
import lett_03 from "../../assets/img/logo_partes/lett_03.svg";
import lett_04 from "../../assets/img/logo_partes/lett_04.svg";

const HomeJs = () => {
  const [isMobilePortrait, setIsMobilePortrait] = useState(window.matchMedia("(orientation: portrait)").matches && window.innerWidth <= 767.98);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1900);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobilePortrait(window.matchMedia("(orientation: portrait)").matches && window.innerWidth <= 767.98);
      setIsDesktop(window.innerWidth >= 1900);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleLoadedData = () => {
      const currentSrc = videoElement.currentSrc;
      console.log('Current video source:', currentSrc);
    };

    videoElement.addEventListener('loadeddata', handleLoadedData);

    return () => {
      videoElement.removeEventListener('loadeddata', handleLoadedData);
    };
  }, [isMobilePortrait, isDesktop]);


  const section_home = document.querySelector('section#home');
  useEffect(() => {
      document.querySelector("html").classList.remove("exit");

      var TL_body_bg = gsap.timeline({
        defaults: {
          duration: 1,
          ease: "linear"
        },
        paused: true,
        repeat: -1
      });
      TL_body_bg
      .from('section#home', 
        { 
          backgroundColor: '#007CB0',
          delay: 0.5,
          duration: 2
        }
      )
      .to('#father_video_home', 
        { 
          autoAlpha: 0,
          delay: 0,
          onComplete: function() {
            document.querySelector('#father_video_home').style.display = 'none';
          }
        }, '-1'
      )
      .to('section#home', 
        { 
          backgroundColor: '#FF2A1B',
          delay: 0.5,
          duration: 2
        }
      )
      .to('section#home',
        { 
          backgroundColor: '#000000',
          delay: 0.5,
          duration: 2
        }
      )
      .to('section#home',
        { 
          backgroundColor: '#6049F8',
          delay: 0.5,
          duration: 2
        }
      )
      .to('section#home',
        { 
          backgroundColor: '#008351',
          delay: 0.5,
          duration: 2
        }
      )
      .to('section#home',
        { 
          backgroundColor: '#007CB0',
          delay: 0.5,
          duration: 2
        }
      );
      
      var TL_body_text = gsap.timeline({
        defaults: {
          duration: 0.4,
          ease: "Sine.easeIn"
        },
        paused: true
      });
      TL_body_text
      .to('img.logo_01', 
        { 
          y: '0',
          autoAlpha: 1,
          delay: 0
        }, '-=0.2'
      )
      .to('img.logo_02',
        { 
          y: '0',
          autoAlpha: 1
        }, '-=0.2'
      )
      .to('img.logo_03',
        { 
          y: '0',
          autoAlpha: 1
        }, '-=0.2'
      )
      .to('img.logo_04',
        { 
          y: '0',
          autoAlpha: 1
        }, '-=0.2'
      )
      .to('section#home div.spc > img',
        { 
          autoAlpha: 1,
          stagger: 0.1
        }, '-=0.2'
      )
      .to('div.submenu_',
        { 
          autoAlpha: 1
        },
        '<'
      );
      
      var TL_body_submenu_ = gsap.timeline({
        defaults: {
          duration: 0.4,
          ease: "Sine.easeIn"
        },
        paused: true
      });
      TL_body_submenu_
      .to('div.submenu_',
        { 
          autoAlpha: 1,
          delay: 1
        },
        '-=0.2'
      )
      TL_body_submenu_.play();

      const videoElement = document.getElementById('video_home');
      const handleVideoEnded = () => {
        TL_body_bg.play();
        TL_body_text.play();
      };

      const handleVideoError = () => {
        TL_body_bg.play();
        TL_body_text.play();
      };

      videoElement.addEventListener('ended', handleVideoEnded);
      videoElement.addEventListener('error', handleVideoError);
      
      return () => {
        videoElement.removeEventListener('ended', handleVideoEnded);
        videoElement.removeEventListener('error', handleVideoError);
      };

  }, []);

  const VideoElements = ({ isMobilePortrait, isDesktop }) => {
    return (
      <>
        {isMobilePortrait ? (
          <>
            <source type="video/mp4" src="https://f004.backblazeb2.com/file/b9-videos/helo/home/mov/video_878.mp4" />
            <source type="video/webm" src="https://f004.backblazeb2.com/file/b9-videos/helo/home/mov/video_878.webm" />
            <source type="video/ogg" src="https://f004.backblazeb2.com/file/b9-videos/helo/home/mov/video_878.ogg" />
          </>
        ) : isDesktop ? (
          <>
            <source type="video/mp4" src="https://f004.backblazeb2.com/file/b9-videos/helo/home/desk/1080.mp4" data-width="1920"/>
            <source type="video/webm" src="https://f004.backblazeb2.com/file/b9-videos/helo/home/desk/1080.webm" data-width="1920"/>
            <source type="video/webm" src="https://f004.backblazeb2.com/file/b9-videos/helo/home/desk/1080.ogg" data-width="1920"/>
            <source type="video/webm" src="https://f004.backblazeb2.com/file/b9-videos/helo/home/desk/1080.mov" data-width="1920"/>
          </>
        ) : (
          <>
            <source type="video/mp4" src="https://f004.backblazeb2.com/file/b9-videos/helo/home/desk_md/1200.mp4" data-width="1600"/>
            <source type="video/webm" src="https://f004.backblazeb2.com/file/b9-videos/helo/home/desk_md/1200.webm" data-width="1600"/>
            <source type="video/webm" src="https://f004.backblazeb2.com/file/b9-videos/helo/home/desk_md/1200.ogg" data-width="1600"/>
            <source type="video/webm" src="https://f004.backblazeb2.com/file/b9-videos/helo/home/desk_md/1200.mov" data-width="1600"/>
          </>
        )}
      </>
    );
  };

  return(
    <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
    <Menu propType={'lighten'} propLogoLocation={'inMenu'} />
    <style>{`
      section#home{
        background-color: #007CB0;
      }
      div.submenu_{
        opacity: 0;
        visibility: hidden;
      }
      @media (max-width: 767.98px) {
        div.submenu_>a:after{
          content: "MENU";
          display: inline-block;
          position: absolute;
          left: -39px;
          top: 9px;
          color: rgba(255, 255, 255, .6);
          font-size: 12px;
        }
      }
    `}</style>
    <Helmet>
      <title>HELO - Moving Experiences</title>
      <meta name='description' content='HELO combines storytelling, design and technology in innovative ways. We believe in the power of strong narrative.' />
      <meta name="keywords" content="HELO, HELO TV, HELO WORLD, storytelling" />
      <meta http-equiv="Content-Language" content="en" />
      <meta name="robots" content="index, follow" />
    </Helmet>
    <section id="home">
      <div className="row h-100 justify-content-center align-items-center align-items-md-end">
        <div className={isMobilePortrait?"is_portrait":""} id="father_video_home">
            <video ref={videoRef} muted autoPlay playsInline id="video_home" poster={isMobilePortrait?poster_home_mov:poster_home} >
                <VideoElements isMobilePortrait={isMobilePortrait} isDesktop={isDesktop} />
            </video>
        </div>
        <div className="elements col-auto">
          <div className="block_ctrl xl_logo">
            <div className="row m-0 justify-content-center align-items-center">
              <div className="col-auto p-0">
                <img className="logo_01" src={logo_part_1} />
                <img className="logo_02" src={logo_part_2} />
                <img className="logo_03" src={logo_part_3} />
                <img className="logo_04" src={logo_part_4} />
              </div>
            </div>
          </div>
          <div className="block_ctrl spc">
            <img className="lett_01" src={lett_01} />
            <img className="lett_02" src={lett_02} />
            <img className="lett_03" src={lett_03} />
            <img className="lett_04" src={lett_04} />
          </div>  
        </div>
      </div>
    </section>
  </motion.div>
  );
};

export default HomeJs;