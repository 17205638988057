import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from '@studio-freight/lenis';
import AlternateFutures from "../../assets/img/AlternateFutures.jpg";
import linkImg from "../../assets/img/linkImg.svg";

import poster_01 from "../../assets/img/posters/helo_labs/821748102.jpg";
import poster_02 from "../../assets/img/posters/helo_labs/797837099.jpg";
import poster_03 from "../../assets/img/posters/helo_labs/815082986.jpg";
import poster_04 from "../../assets/img/posters/helo_labs/823840805.jpg";
import poster_05 from "../../assets/img/posters/helo_labs/825581560.jpg";

import brassroots_01 from "../../assets/img/posters/helo_labs/brassroots_01.jpg";
import brassroots_02 from "../../assets/img/posters/helo_labs/brassroots_02.jpg";
import brassroots_03 from "../../assets/img/posters/helo_labs/brassroots_03.jpg";

import surf_genie01 from "../../assets/img/spark/surf_genie/surf_genie01.jpg";
import surf_genie02 from "../../assets/img/spark/surf_genie/surf_genie02.jpg";
import surf_genie03 from "../../assets/img/spark/surf_genie/surf_genie03.jpg";

import Menu from '../../components/submodules/Menu';
import { motion } from "framer-motion/dist/framer-motion";

SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);
gsap.registerPlugin(ScrollTrigger);


const HeloSpark = () => {

  const carousel_videos = [
    'https://player.vimeo.com/progressive_redirect/playback/797837099/rendition/720p/file.mp4?loc=external&signature=2f3fef2bbefdf3f51baff6b6fc01bb51f1244e6b28bf737445b48f30de0507d8',
    '',
    '',
    ''
  ];

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
    var TL_body_bg = gsap.timeline({
      defaults: {
        duration: 1,
        ease: "linear"
      },
      paused: true,
      onComplete: () => {
      },
      onStart: () => {
      }
    });
    TL_body_bg
    .fromTo('body', { backgroundColor: '#008351'}, { backgroundColor: '#6049F8'})
    .fromTo('div.overlay_fixed>div.top_', 
      {
        background: '-moz-linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      { 
        background: '-moz-linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      '<'
    )
    .fromTo('div.overlay_fixed>div.bottom_', 
      {
        background: '-moz-linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      { 
        background: '-moz-linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      '<'
    )

    gsap.from('div.row-about', {
      duration: .1,
      scrollTrigger: {
        scroller: "#about_container",
        trigger: "div.row-about",
        start: "top top",
        end: "bottom top-100",
        toggleActions: "play pause reverse reset",
        onEnter: () => {
          TL_body_bg.play();
        },
        onLeave: () => {
          TL_body_bg.reverse();
        },
        onEnterBack: () => {
          TL_body_bg.play();
        },
        onLeaveBack: () => {
          TL_body_bg.reverse();
        },
        scrub: 1
      }
    })

    var main = document.querySelector('main');
    var wrapper = document.querySelector('section#about_container');
    var content = document.querySelector('section#about_container>div.container-xl');
    if (wrapper!==null) {
      var lenis = new Lenis({
        wrapper: wrapper,
        content: content,
        wheelEventsTarget: main,
        lerp: 1,
        duration: 2.5,
        wheelMultiplier: .5,
        smoothTouch: true
      })
      gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
      })

      /*<----Scroll wheel on body*/
      var triggerElement = document.querySelector('body');
      var targetElement = document.getElementById('about_container');
      if (navigator.userAgent.indexOf('Firefox') !== -1) {
        triggerElement.addEventListener('wheel', (event) => {
          var wheelEvent = new WheelEvent('wheel', {
            deltaX: 0,
            deltaY: event.deltaY,
            deltaZ: 0,
            deltaMode: WheelEvent.DOM_DELTA_PIXEL,
          });
          targetElement.dispatchEvent(wheelEvent);
        });
      } else {
        triggerElement.addEventListener('mousewheel', (event) => {
          var wheelEvent = new WheelEvent('wheel', event);
          targetElement.dispatchEvent(wheelEvent);
        });
      }

    }
  }, []);

  return(
    <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
    <Menu propType={'lighten'}/>
    <style>{`
      html>body{
        background-color: #008351;
      }
    `}</style>
    <Helmet>
      <title>HELO - About Us</title>
      <meta name='description' content='Beginner friendly page for learning React Helmet.' />
    </Helmet>
    <main data-barba="container" data-barba-namespace="about">
      <div className="overlay_fixed">
        <div className="top_"></div>
        <div className="bottom_"></div>
      </div>
      <section id="about_container">
        <div className="container-xl helo_labs">
          <div className="row row-about">
            <div className="col-12 col-md-6 py-0 px-0 px-md-5 text-end title">
              <div className="row justify-content-end">
                <div className="col-auto text-start main_title">
                  <span>HELO SPARK</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-0 info">
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="pb-3">At HELO Spark, invention and originality is everything. Here, we meddle with the familiar - film, episodic, theatrical, original IP, even as we charge into new paradigms, testing ideas and integrating unusual formats in unexpected ways. Our alchemists here are encouraged to engineer the transcendent storytelling techniques of tomorrow.</p>
                    <p className="pb-3">We do all this to find original and authentic avenues to reach audiences for our clients - today’s perplexing prototype becoming tomorrow’s product.</p>
                    <p>HELO Spark. There’s method to our madness.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-about-helo">
            <div className="col-12 col-md-6 py-0 px-0 px-md-5 text-end title">
              <div className="row justify-content-end">
                <div className="col-auto text-start">
                  <span>
                    <small>Alternate Futures</small>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-0 info">
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="block_ctrl pb-3">Alternate Futures empowers humanity towards an intentional tomorrow by creating an AI-enabled platform for collaborative imagining of the future. Harnessing the collective imagination of millions, creating connections to drive real action.</p>
                    <img className="block_ctrl" src={AlternateFutures} alt="Alternate Futures"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-about-helo">
            <div className="col-12 col-md-6 py-0 px-0 px-md-5 text-end title">
              <div className="row justify-content-end">
                <div className="col-auto text-start">
                  <span>
                    <small>AI Dreams</small>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-0 info">
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="block_ctrl pb-3">What would AI do, if handed the briefs we've received? HELO decided to find out. We discovered that while the tech offers amazing tools, there’s still no replacing the creative human mind in the equation.</p>
                    
                    <p className="block_ctrl pb-3 pt-3"><strong>Grinch Rockefeller Plaza Tree (AI).</strong>We started the series by re-imagining one of our favorite Christmas activations that we did in support of The Grinch movie, building a 40-foot tall social media magnet in Rock Center.</p>
                    <div className="video_responsive">
                      <video loop controls preload="metadata" alt="Grinch Rockefeller Plaza Tree (AI)" poster={poster_01}>
                          <source type="video/mp4" src="https://player.vimeo.com/progressive_redirect/playback/821748102/rendition/720p/file.mp4?loc=external&signature=91ee77f9c987b22d7fb167c33d3a234c1bd07f7c93f6e30b1db756e8f38f7529" />
                      </video>
                    </div>
                    <a className="link_01" href="https://vimeo.com/389944706" target="_blank">
                      <img src={linkImg} alt="" />
                      Original video
                    </a>

                    <p className="block_ctrl pb-3 pt-3"><strong>Up For Whatever (AI).</strong>This is a play on the original Super Bowl spot we did for Bud Light, but using AI images, deep fakes and AI-generated voices for the celebs.</p>
                    <div className="video_responsive">
                      <video loop controls preload="metadata" alt="Up For Whatever (AI)" poster={poster_02}>
                          <source type="video/mp4" src="https://player.vimeo.com/progressive_redirect/playback/797837099/rendition/720p/file.mp4?loc=external&signature=2f3fef2bbefdf3f51baff6b6fc01bb51f1244e6b28bf737445b48f30de0507d8" />
                      </video>
                    </div>
                    <a className="link_01" href="https://vimeo.com/85972196/bb035c9a38" target="_blank">
                      <img src={linkImg} alt="" />
                      Original video
                    </a>

                    <p className="block_ctrl pb-3 pt-3"><strong>Headspace The Dot (AI).</strong>Testing the photo-real capabilities of AI, we riffed off of an installation we did in the city of Pittsburgh for Headspace. Everything in this video is AI-generated. Even the meditation script.</p>
                    <div className="video_responsive">
                      <video loop controls preload="metadata" alt="Headspace The Dot (AI)" poster={poster_03}>
                          <source type="video/mp4" src="https://player.vimeo.com/progressive_redirect/playback/815082986/rendition/720p/file.mp4?loc=external&signature=2ff5ff25a90bf6e40379b79082878f9e78e5e36b087a26ef18614b917ab9fc9c" />
                      </video>
                    </div>
                    <a className="link_01" href="https://vimeo.com/385285684" target="_blank">
                      <img src={linkImg} alt="" />
                      Original video
                    </a>

                    <p className="block_ctrl pb-3 pt-3"><strong>Mayhem (AI version).</strong>We ran our Mayhem spot through some AI madness.</p>
                    <div className="video_responsive">
                      <video loop controls preload="metadata" alt="Mayhem (AI version)" poster={poster_04}>
                          <source type="video/mp4" src="https://player.vimeo.com/progressive_redirect/playback/823840805/rendition/720p/file.mp4?loc=external&signature=98e6857ab65c5f0fb4f7a8b4ccd725480685f304656259ec400b2dfa32d4ce7d" />
                      </video>
                    </div>
                    <a className="link_01" href="https://vimeo.com/314559418" target="_blank">
                      <img src={linkImg} alt="" />
                      Original video
                    </a>

                    <p className="block_ctrl pb-3 pt-3"><strong>Fast & Furious (AI).</strong>With this abstract re-interpretation of our original piece for Fate of the Furious, we’ve leveraged AI to take us fully down the Manga rabbit hole, featuring a couple of characters who are learning about our crazy stunt as it’s taking place, and wondering why people would do such a thing. Turns out Fast and Furious is all about family.</p>
                    <div className="video_responsive">
                      <video loop controls preload="metadata" alt="Fast & Furious (AI)" poster={poster_05}>
                          <source type="video/mp4" src="https://player.vimeo.com/progressive_redirect/playback/825581560/rendition/720p/file.mp4?loc=external&signature=22fead913d0541959317ce6d5200cce5e0d396781d48e1d77f44e6050ea24a41" />
                      </video>
                    </div>
                    <a className="link_01" href="https://vimeo.com/394201415" target="_blank">
                      <img src={linkImg} alt="" />
                      Original video
                    </a>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-about-helo">
            <div className="col-12 col-md-6 py-0 px-0 px-md-5 text-end title">
              <div className="row justify-content-end">
                <div className="col-auto text-start">
                  <span>
                    <small>Brassroots District</small>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-0 info">
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="block_ctrl pb-3">With Les Enfants Terribles and creators Ari Herstedt and Andrew Lieb, HELO is developing an immersive musical experience for Las Vegas that takes audiences into the funkadelic world of 1973 through original music and immersive theater. </p>
                    <Swiper
                      direction="horizontal"
                      speed={300}
                      slidesPerView={1}
                      centeredSlides={true}
                      spaceBetween={0}
                      loop={true}
                      className="carousel_videos block_ctrl pt-2"
                      pagination={{
                        type: 'bullets',
                        clickable: true
                      }}
                    >
                      <SwiperSlide>
                        <img src={brassroots_01} className="block_ctrl" alt="Brassroots District" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={brassroots_02} className="block_ctrl" alt="Brassroots District" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={brassroots_03} className="block_ctrl" alt="Brassroots District" />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-about-helo">
            <div className="col-12 col-md-6 py-0 px-0 px-md-5 text-end title">
              <div className="row justify-content-end">
                <div className="col-auto text-start">
                  <span>
                    <small>The Surf Genie</small>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-0 info">
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="block_ctrl pb-3">The Surf Genie is the digital offspring of a humorous conversation between Brendan Kiernan and Kyle Ruddick. Observing Kyle’s knack for pointing friends to the best surf spots, Brendan mused, “Kyle, you need to make an AI surf Genie”. Thus, the idea was born.</p>
                    <p className="block_ctrl pb-3">Today, the Surf Genie has emerged as an engaging online personality, dishing out daily surf reports with humor and wisdom. It’s more than just a forecasting tool, it’s an AI influencer, an entertaining companion for surfers worldwide. But that’s just the beginning. The Surf Genie has ambitions to offer tailored surf reports directly to those who sign up, advising on when and where to go, factoring in key details like crowd size and weather changes.</p>
                    <p className="block_ctrl pb-3">At the heart of the Surf Genie is a groundbreaking collaboration between human intuition and artificial intelligence. Guided by Kyle’s deep understanding of surfing and wave forecasting, the AI is being honed to interpret and respond to surfing data in an authentic and engaging way. This unique interplay is shaping the Surf Genie’s persona, ensuring it’s not only accurate but also funny and deeply rooted in surf culture.</p>
                    <p className="block_ctrl pb-3">Soon, the Surf Genie will become a personalized guide, delivering advice suited to each surfer’s experience and comfort level. This innovative development of a digital influencer who can provide both entertainment and information is part of the wider AI revolution. Together, Brendan and Kyle are not only enhancing the surfing experience but also pushing the boundaries of what AI can achieve.</p>
                    <Swiper
                      direction="horizontal"
                      speed={300}
                      slidesPerView={1}
                      centeredSlides={true}
                      spaceBetween={0}
                      loop={true}
                      className="carousel_videos block_ctrl pt-2"
                      pagination={{
                        type: 'bullets',
                        clickable: true
                      }}
                    >
                      <SwiperSlide>
                        <img src={surf_genie01} className="block_ctrl" alt="Brassroots District" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={surf_genie02} className="block_ctrl" alt="Brassroots District" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={surf_genie03} className="block_ctrl" alt="Brassroots District" />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </main>
  </motion.div>
  );
};

export default HeloSpark;