import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import RefreshingRouteWrapper from "./RefreshingRouteWrapper"; // Importa el componente

const App = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>HELO - Moving Experiences</title>
        <meta name="description" content="App Description" />
      </Helmet>
      <RefreshingRouteWrapper />
    </HelmetProvider>
  );
};

export default App;