import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import plus_dark from "../../assets/img/plus_dark.svg";

const Indice = (props) => {

  const [isIos] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);

  const showIndice = props.propShowIndice;
  const setShowIndice = props.propSetShowIndice;
  const SparkObject = props.propSparkObject;

  var body_section = document.querySelector("html>body");
  useEffect(() => {
    if (showIndice) {
      body_section.classList.add("block_gral_events");
    }else{
      body_section.classList.remove("block_gral_events");
    }
  }, [showIndice, body_section.classList]);

  const navigate = useNavigate();

  function goToView(route){
    setTimeout( function() {
      navigate(route);
    }, 500);
  }

  function goToPage(url) {
    if (isIos) {
      setTimeout(function() {
        window.location.href = url;
      }, 500);
    }else{
      goToView(url);
    }
  }

  return (
    <React.Fragment>
      {showIndice?
        <style>{`
          a.icon {
              z-index: 99999;
          }
          a.icon>img {
              filter: invert(100%);
          }
        `}</style>
      :''}
      <div className={`indice_container ${showIndice ? "on_show" : ""} `}>
        <Link className="close__" onClick={() => {setShowIndice(false);}}>
          <img className="" src={plus_dark} alt="" />
        </Link>
        <div className="container-fluid px-0 h-100">
          <div className="row m-0 align-content-end h-100 index_">
            <div className="col-12 px-0 text-end">
              <span className="h1">INDEX</span>
            </div>
            <div className="col-12 px-0 pb-5">
              <ul>
                {SparkObject.map((project, index) => {
                  return (
                    <li key={index+'_list_item'}>
                      <button
                        onClick={() => {
                          //window.location.href = project.url;
                          goToPage('/'+project.url);
                        }}
                      >
                        <span className="act" data-element-by-class=".block_01">{project.name}</span>
                        <span>{project.year}</span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Indice;