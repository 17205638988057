import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import CreatorsObject from './CreatorsObject';
import logo_helo_white from "../../assets/img/logo_helo_white.svg";

const AllCreators = (props) => {

  const active = props.active;
  const activeFilter = props.activeFilter;
  const setActiveFilter = props.setActiveFilter;
  const showListCreators = props.showListCreators;
  const setShowListCreators = props.setShowListCreators;
  const [creatorsPart1, setCreatorsPart1] = useState([]);
  const [creatorsPart2, setCreatorsPart2] = useState([]);
  const [creatorsPart3, setCreatorsPart3] = useState([]);
  const clasificaciones = ["EXPERIENCES","DESIGN","CONTENT","TECHNOLOGY"];
  const [isIos] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);

  const [disableMenuCat] = useState(false);

  useEffect(() => {
      let tercio = Math.floor(CreatorsObject.length / 3);
      setCreatorsPart1(CreatorsObject.slice(0, tercio+1));
      setCreatorsPart2(CreatorsObject.slice(tercio+1, tercio+tercio+2));
      setCreatorsPart3(CreatorsObject.slice(tercio+tercio+2));
  }, []);

  const navigate = useNavigate();
  function goToView(route){
    document.querySelector("html").classList.add("exit");
    setTimeout( function() {
      navigate(route);
    }, 500);
  }
  function goToPage(url) {
    if (isIos) {
      document.querySelector("html").classList.add("exit");
      setTimeout(function() {
        window.location.href = url;
      }, 500);
    }else{
      goToView(url);
    }
  }

  const LogoVar = (props) => {
      var active = props.active;
      return (
        <React.Fragment>
            <div className={`div_bg_logo ${active ? "active" : ""} `} ></div>
            <button
              className={`icon icon_menu_creators ${active ? "active" : ""} `}
              onClick={() => {
                goToView('/home');
              }}
            >
                <img className="" src={logo_helo_white} alt="" />
            </button>
        </React.Fragment>
      )
  }


  return(
    <React.Fragment>
    
    <div className={`list_creators ${showListCreators ? "active" : ""}`}>
      <LogoVar active={showListCreators}/>
      <div className="back_blur"></div>
      <div className="container h-100">
        <div className="row h-100 m-0 justify-content-center align-items-center">
          <div className="col-12 p-0 ps-lg-5 pb-md-0">
            <div className="row w-100 justify-content-center m-0">
              {creatorsPart1.length>0?
              <div className="col-6 column_creators">
                <ul>
                  {creatorsPart1.map((creator, index) => {
                    return (
                      <li key={index} className={creator.clasificacion.includes(activeFilter)?'active':''}>
                        <Link
                          className="link"
                          onClick={() => {
                            goToPage(creator.url);
                          }}
                        >
                          {creator.nombre}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              :''}
              {creatorsPart2.length>0?
              <div className="col-6 column_creators">
                <ul>
                  {creatorsPart2.map((creator, index) => {
                    return (
                      <li key={index} className={creator.clasificacion.includes(activeFilter)?'active':''}>
                        <Link
                          className="link"
                          onClick={() => {
                            goToPage(creator.url);
                          }}
                        >
                          {creator.nombre}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              :''}
              {creatorsPart3.length>0?
              <div className="col-6 column_creators">
                <ul>
                  {creatorsPart3.map((creator, index) => {
                    return (
                      <li key={index} className={creator.clasificacion.includes(activeFilter)?'active':''}>
                        <Link
                          className="link"
                          onClick={() => {
                            goToPage(creator.url);
                          }}
                        >
                          {creator.nombre}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              :''}
            </div>
          </div>
        </div>
      </div>
      <div className={` menu_clasificacion text-center ${disableMenuCat ? "waitingEffect" : ""} `}>
        <ul>
          {active==='creators'
          ?
            <li>
              <button
                href="#"
                className="show_all"
                onClick={() => {setShowListCreators(!showListCreators);}}
              >
                VIEW ALL
              </button>
            </li>
          :
            <li>
              <button
                onClick={() => {
                  goToPage('/creators');
                }}
                className="show_all"
              >
                VIEW ALL
              </button>
            </li>
          }
          {clasificaciones.map((clasificacion, index) => {
            return (
              <li key={"clasificacion_"+index} className={clasificacion===activeFilter?'active':''}>
                <button onClick={() => {setActiveFilter(clasificacion===activeFilter?'ALL':clasificacion);}}>{clasificacion}</button>
              </li>
            );
          })}
        </ul>

      </div>
    </div>
    </React.Fragment>
  );
};

export default AllCreators;