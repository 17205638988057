import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
import Lenis from '@studio-freight/lenis';
import Menu from '../../../components/submodules/Menu';
import { motion } from "framer-motion/dist/framer-motion";
import arrow_back from "../../../assets/img/arrow_back.svg";

SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);
gsap.registerPlugin(Flip);


const Christine = () => {
  const [videoSwiper, setVideoSwiper] = useState(null);
  const one_videos = [
    'https://player.vimeo.com/progressive_redirect/playback/632008543/rendition/720p/file.mp4?loc=external&signature=a2cbe0292980dcced5ea383992f44678d1bab1c6cfac7b58d88231abb6a8b45f',
    'https://player.vimeo.com/progressive_redirect/playback/632006510/rendition/720p/file.mp4?loc=external&signature=23c1fb99cd00981d733a526c8e72724395196833ba08c5afe3d13ccd70135aa1',
    'https://player.vimeo.com/progressive_redirect/playback/632004825/rendition/720p/file.mp4?loc=external&signature=69493d6044d19ad64b9d667df5b044f938d2357cafacf2bf5643816534d64b95',
    'https://player.vimeo.com/progressive_redirect/playback/632004036/rendition/720p/file.mp4?loc=external&signature=227413302cf6feecf4861eff19658f2b1557df432e726f7faa35bff2035b06a3'
  ];
  const videos = [...one_videos, ...one_videos]

  useEffect(() => {
    if (videoSwiper!==null) {
      videoSwiper.on('transitionStart', function (swipe) {
        var videos = document.querySelectorAll('video');
        Array.prototype.forEach.call(videos, function(video){
          video.pause();
        });
      });
      videoSwiper.on('transitionEnd', function (swipe) {
        var activeIndex = swipe.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        activeSlideVideo.play();
      });
      autoPlayVideo();
    }
  }, [videoSwiper]);

  useEffect(() => {
    var newContainer = document.querySelector(".newContainerVideo");
    var origContainers = document.querySelectorAll('#videos .swiper-slide>div>div');
    window.videoModal = false;
    var tlineVideo = gsap.timeline({
      defaults: {
        duration: 0.25
      },
      paused: true,
      onComplete: () => {
        window.video_element.muted = false;
        window.video_element.setAttribute("controls",true);
      },
      onStart: () => {
        window.videoModal = true;
      },
      onReverseComplete: () => {
        (window.originalContainer).appendChild(window.video_element);
        window.video_element.muted = true;
        window.video_element.removeAttribute("controls");
          window.videoModal = false;
      }
    });
    origContainers.forEach(function(originalContainer){
      originalContainer.addEventListener("click", function(event){
        event.preventDefault();
        window.originalContainer = document.querySelector("#videos .swiper-slide.swiper-slide-active>div>div");
        window.video_element = document.querySelector("#videos .swiper-slide.swiper-slide-active>div>div video");
        window.state = Flip.getState(window.video_element);
        tlineVideo.to(newContainer,
          {
            autoAlpha: 1,
            pointerEvents: 'auto',
            ease: "linear",
            duration: 0.25,
            onStart: () => {
              (newContainer).appendChild(window.video_element);
              Flip.from(window.state, {
                duration: 0.25,
                ease: "linear",
                scale: true
              })
            }
          }
        )
        tlineVideo.play();
      })
    })
    var closeContainer = document.querySelector(".newContainerVideo .close");
    if (closeContainer!==null) {
      closeContainer.addEventListener("click", function(event){
        event.preventDefault();
        tlineVideo.reverse();
      })
    }
  }, []);

  useEffect(() => {
    var wrapper = document.querySelector('div#info_detail>div');
    var content = document.querySelector('div#info_detail>div>div');
    if (wrapper!==null) {
      var lenis = new Lenis({
        wrapper: wrapper,
        content: content,
        wheelEventsTarget: window,
        lerp: 1,
        duration: 2,
        wheelMultiplier: .75
      })
      gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
      })
    }
  }, []);



  function autoPlayVideo(){
    var activeIndex = videoSwiper.activeIndex;
    var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
    var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
    activeSlideVideo.play();
  }

  return(
  <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >



  <Menu propType={'lighten'}/>
  <style>{`
    @media (max-width: 767.98px) {
      html body {
          overflow-y: auto;
      }
    }
  `}</style>
  <Helmet>
    <title>HELO - Christine Ng</title>
    <meta name='description' content='Beginner friendly page for learning React Helmet.' />
  </Helmet>

    <section className="fix_overflow darked" id="creator_detail">
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 col-md-6 d-md-none videos_responsive">
            <div className="row justify-content-center align-items-center">
              <div className="col-auto">
                <iframe src="https://player.vimeo.com/video/632008543?h=b2a9c64da2" width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 p-0 creator_det d-none d-md-inline-block" id="videos">
            <div>
              <Swiper
                direction="vertical"
                speed={300}
                slidesPerView={3}
                centeredSlides={true}
                spaceBetween={0}
                mousewheel={true}
                loop={true}
                onSwiper={setVideoSwiper}
                breakpoints={{ 
                  0: {
                    direction: "horizontal"
                  },
                  768: {
                    direction: "vertical"
                  }
                }}
              >
                {videos.map((video, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="row h-100 m-0">
                        <div className="col-12">
                          <video loop muted preload="metadata" id={"vid_"+index}>
                              <source type="video/mp4" src={video} />
                          </video>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
          <div className="col-12 col-md-6 p-0" id="info_detail">
            <div className="row m-0 align-content-end justify-content-start">
              <div className="col-12">
                <span className="title">Christine NG</span>
                <div className="description">
                  <p>Christine Ng is a Hong Kong born, New York raised artist and a graduate from New York University’s Tisch School of the Arts.</p>
                  <p>As a cinematographer, she has worked in a variety of genres ranging from narrative, commercials, music videos and documentary. The first commercial she shot, Honda’s “50 states” aired during Super Bowl XLVI. Since then she has shot for clients such as: Chanel, Dior, Google, Nike, Apple, Philips, Dove, Maybelline, HP, Sony, Bushmills, Land Rover, Ralph Lauren and many more. Her work for Cancerland x Refinery 29 won a Bronze Clio Award.</p>
                  <p>The first feature-length documentary Christine shot was the Emmy nominated and Critic’s Choice winner “Everything is Copy: Nora Ephron Scripted and Unscripted”, for HBO.</p>
                  <p>Christine has worked on long-form series such as Spike Lee’s “She’s Gotta Have It”, Ava DuVernay’s “When They See Us”, “Colin In Black and White” all for Netflix.</p>
                  <p>Her work has screened at festivals worldwide including SXSW, Palm Springs, Outfest, LA Film Festival, TriBeCa Film Festival, NY Film Festival, IFF Boston, and many more.</p>
                  <p>Most recently she shot the film adaptation of Ta–Nehisi Coates’s Critic’s Choice Nominated “Between the World and Me”, “Ziwe” for Showtime and an Untitled series about growing up with Executive Producer and creator Brie Larson for Disney+.</p>
                  <p>Her artistry stems from community and love, harnessing the energy of others around her to create the most magically collaborative process possible. While holding a camera and being able to encapsulate a feeling through imagery, she’s worked with trained actors and real people.</p>
                  <p>Her ability to make others feel comfortable in front of the lens is pivotal to creating a safe space on set. She grew up in one of the most diverse neighborhoods in the world: Elmhurst, Queens, giving her the innate ability to relate to people from different backgrounds. There is always a feeling of kindred spirits cut from the same cloth. There is nothing more powerful than the ability to be seen through human connection.</p>
                </div>
              </div>  
            </div>
          </div>
          <div className="col-12 col-md-6 d-md-none pb-5 videos_responsive pt-0">
            <div className="row justify-content-center align-items-center">
              <div className="col-auto pb-4">
                <iframe src="https://player.vimeo.com/video/632008543?h=b2a9c64da2" width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-auto pb-4">
                <iframe src="https://player.vimeo.com/video/632008543?h=b2a9c64da2" width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-auto pb-4">
                <iframe src="https://player.vimeo.com/video/632008543?h=b2a9c64da2" width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-auto">
                <iframe src="https://player.vimeo.com/video/632008543?h=b2a9c64da2" width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div >
      <div></div>
      <span></span>
    </div>
    <Link
      className="back_creators"
      component={RouterLink}
      to='/creators'
    >
      <img src={arrow_back} alt=""/>
      <span>Back to creators</span>
    </Link>
    <div className="newContainerVideo">
      <div className="close">
        <div></div>
      </div>
    </div>
  </motion.div>
  );
};

export default Christine;