import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { gsap } from "gsap";
import Lenis from '@studio-freight/lenis';
import Menu from '../../components/submodules/Menu';
import { motion } from "framer-motion/dist/framer-motion";
import arrow_back_light from "../../assets/img/arrow_back_light.svg";

const ProjectVideoFullScreen = (props) => {

  const [isIos] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
  const currentProject = props.propProject;

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
  }, []);

  function toggleFullScreen(element) {

    if (isIos) {
      if (element.webkitEnterFullscreen) {
        if (document.webkitFullscreenElement) {
          document.webkitExitFullscreen();
        } else {
          element.webkitEnterFullscreen();
        }
      } else if (element.mozRequestFullScreen) {
        if (document.mozFullScreenElement) {
          document.mozCancelFullScreen();
        } else {
          element.mozRequestFullScreen();
        }
      } else if (element.requestFullscreen) {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          element.requestFullscreen();
        }
      }
    }else{
      if (element.requestFullscreen) {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          element.requestFullscreen();
        }
      } else if (element.mozRequestFullScreen) {
        if (document.mozFullScreenElement) {
          document.mozCancelFullScreen();
        } else {
          element.mozRequestFullScreen();
        }
      } else if (element.webkitRequestFullscreen) {
        if (document.webkitFullscreenElement) {
          document.webkitExitFullscreen();
        } else {
          element.webkitRequestFullscreen();
        }
      } else if (element.msRequestFullscreen) {
        if (document.msFullscreenElement) {
          document.msExitFullscreen();
        } else {
          element.msRequestFullscreen();
        }
      }
    }
  }

  useEffect(() => {
      
      /* <---- CURSOR BALL*/
      var cursor_play = document.querySelector('#ball');
      var mouse = { x: 0, y: 0 }; // Cursor position
      var pos = { x: 0, y: 0 }; // Cursor position
      var ratio = 0.15; // delay follow cursor
      var active = false;
      document.addEventListener("mousemove", mouseMove);
      function mouseMove(e) {
        mouse.x = e.clientX;
        mouse.y = e.clientY;
      }
      gsap.ticker.add(updatePosition);
      function updatePosition() {
        if (!active) {
          pos.x += (mouse.x - pos.x) * ratio;
          pos.y += (mouse.y - pos.y) * ratio;
          gsap.set(cursor_play, { x: pos.x, y: pos.y });
        }
      }
      gsap.set(cursor_play, {
        xPercent: -50, 
        yPercent: -50, 
        width: "0px",
        height: "0px",
        backgroundColor: "transparent",
        opacity: 0
      });
      /*CURSOR BALL -----> */

      if (currentProject.nombre!=='') {
        var video = document.getElementById('one_video_dev');
        video.addEventListener("click", function(event){
          event.preventDefault();
          toggleFullScreen(video);
        });
        video.addEventListener('fullscreenchange', function() {
          var sources = video.getElementsByTagName('source');
          if (document.fullscreenElement) {
            // El video está en modo de pantalla completa
            console.log('El video está en pantalla completa');
            var maxWidth = window.innerWidth;
            var selectedSource = null;
            // Recorrer las fuentes de video y seleccionar la de mayor calidad compatible
            for (var i = 0; i < sources.length; i++) {
              var source = sources[i];
              var mediaQuery = source.getAttribute('media');
              if (window.matchMedia(mediaQuery).matches) {
                selectedSource = source;
              }
            }
            // Cambiar la fuente del video a la de mayor calidad
            if (selectedSource) {
              video.src = selectedSource.src;
            }
            video.currentTime = 1;
            video.muted = false;
          } else {
            // El video ha salido del modo de pantalla completa
            console.log('El video ha salido de pantalla completa');
            video.src = sources[0].src;
            video.muted = true;
          }
        });
        /* <---- CURSOR BALL*/
        video.addEventListener("mouseenter", function() {
          video.style.cursor = "none"; // Ocultar el puntero por defecto
          gsap.to(cursor_play, {
            duration: 0.3,
            opacity: .75,
            backgroundColor: "#000000",
            width: "75px",
            height: "75px",
          });
        });
        video.addEventListener("mouseleave", function() {
          video.style.cursor = "default"; // Restablecer el puntero por defecto
          gsap.to(cursor_play, {
            duration: 0.3,
            opacity: 0,
            backgroundColor: "transparent",
            width: "0px",
            height: "0px",
          });
        });
        video.addEventListener("mousedown", function() {
          gsap.to(cursor_play, {
            duration: 0.3,
            width: "100px",
            height: "100px",
          });
        });
        video.addEventListener("mouseup", function() {
          gsap.to(cursor_play, {
            duration: 0.3,
            width: "75px",
            height: "75px",
          });
        });
        /*CURSOR BALL -----> */
      }
  }, [currentProject]);

  useEffect(() => {
    var wrapper = document.querySelector('div#info_detail>div');
    var content = document.querySelector('div#info_detail>div>div');
    if (wrapper!==null) {
      var lenis = new Lenis({
        wrapper: wrapper,
        content: content,
        wheelEventsTarget: window,
        lerp: 1,
        duration: 2,
        wheelMultiplier: .75
      })
      gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
      })
    }
  }, []);

  const navigate = useNavigate();
  function goToView(route){
    document.querySelector("html").classList.add("exit");
    setTimeout( function() {
      navigate(route);
    }, 500);
  }
  function goToPage(url) {
    if (isIos) {
      document.querySelector("html").classList.add("exit");
      setTimeout(function() {
        window.location.href = url;
      }, 500);
    }else{
      goToView(url);
    }
  }
  
  return(
  <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
  <Menu propType={'darken'}/>
  <style>{`
    div#info_detail > div > div span.title, div#info_detail > div > div div.description > p , div.overlay_back > a.back_creators > span{
        color: #0c0c0c;
    }
    section.darked, div.darked, section.fix_overflow > div > div, div.overlay_back {
        background-color: #FFFFFF;
    }
    html body {
        background-color: #FFFFFF !important;
    }
    div.overlay_back {
        box-shadow: 0px -10px 10px 10px rgb(255 255 255);
    }
    @media (max-width: 991px) {
      html body {
          overflow-y: auto;
      }
      div.overlay_back{
        position: relative;
      }
      div#info_detail > div.overlay_fixed_creator {
        background: rgb(255 255 255);
        background: -moz-linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(180deg, rgb(255 255 255) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(180deg, rgb(255 253 253) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
  `}</style>
  <Helmet>
    <title>HELO - {currentProject.nombre}</title>
    <meta name='description' content={currentProject.extracto} />
  </Helmet>
    <div id="ball"></div>
    <section className="fix_overflow darked" id="creator_detail">
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 col-md-6 p-0 creator_det" id="one_video">
            <div className="row m-0 h-100 justify-content-center align-items-center">
              <div className="col-12 p-0 text-center">
                <video id="one_video_dev" className="block_ctrl" loop muted preload="metadata" controls={false} autoPlay={true} alt={currentProject.nombre} poster={currentProject.poster}>
                    <source type="video/mp4" src={currentProject.video360} media="all and (max-width: 480px)"/>
                    <source type="video/mp4" src={currentProject.video540} media="all and (max-width: 540px)"/>
                    <source type="video/mp4" src={currentProject.video720} media="all and (max-width: 720px)"/>
                    <source type="video/mp4" src={currentProject.video1080} media="all and (max-width: 1080px)"/>
                </video>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6" id="info_detail">
            <div className="row m-0 align-content-end justify-content-start">
              <div className="col-12">
                <span className="title">{currentProject.nombre}</span>
                <div className="description block_ctrl">
                  <p dangerouslySetInnerHTML={{ __html: currentProject.extracto }} />
                  <p></p>
                </div>
              </div>  
            </div>
            <div className="overlay_fixed_creator"></div>
          </div>
        </div>
      </div>
    </section>
    <div >
      <div></div>
      <span></span>
    </div>
    <div className="overlay_back">
      <Link
        className="back_creators"
        onClick={() => {
          goToPage('/projects#'+currentProject.url.slice(1));
        }}
      >
        <img src={arrow_back_light} alt=""/>
        <span>Back to projects</span>
      </Link>
    </div>
    <div className="newContainerVideo">
      <div className="close">
        <div></div>
      </div>
    </div>
  </motion.div>
  );
};

export default ProjectVideoFullScreen;