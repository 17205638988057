import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
import Lenis from '@studio-freight/lenis';
import Menu from '../../components/submodules/Menu';
import { motion } from "framer-motion/dist/framer-motion";
import arrow_back from "../../assets/img/arrow_back.svg";
import CreatorsObject from '../../components/submodules/CreatorsObject';

SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);
gsap.registerPlugin(Flip);

const Creator = (props) => {

  const currentCreator = props.propCreator;
  const [currentVideos, setCurrentVideos] = useState([]);

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
  }, []);

  useEffect(() => {
    setCurrentVideos(props.propCreator);
    if (currentCreator.videos.length===1) {
      console.log('CONDICION 01');
      setCurrentVideos([...currentCreator.videos]);
    }else if (currentCreator.videos.length>1 && currentCreator.videos.length<=3) {
      console.log('CONDICION 02');
      setCurrentVideos([...currentCreator.videos, ...currentCreator.videos, ...currentCreator.videos, ...currentCreator.videos]);
    }else if (currentCreator.videos.length>3 && currentCreator.videos.length<=5) {
      console.log('CONDICION 03');
      setCurrentVideos([...currentCreator.videos, ...currentCreator.videos]);
    }else{
      console.log('CONDICION 04');
      setCurrentVideos([...currentCreator.videos]);
    }
  }, [currentCreator]);

  const [videoSwiper, setVideoSwiper] = useState(null);

  useEffect(() => {
    if (videoSwiper!==null) {
      videoSwiper.on('transitionStart', function (swipe) {
        var videos = document.querySelectorAll('video');
        Array.prototype.forEach.call(videos, function(video){
          video.pause();
        });
      });
      videoSwiper.on('transitionEnd', function (swipe) {
        var activeIndex = swipe.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        activeSlideVideo.play();
      });
      autoPlayVideo();
    }
  }, [videoSwiper]);

  useEffect(() => {
    var newContainer = document.querySelector(".newContainerVideo");
    var origContainers = document.querySelectorAll('#videos .swiper-slide>div>div');
    window.videoModal = false;
    var tlineVideo = gsap.timeline({
      defaults: {
        duration: 0.25
      },
      paused: true,
      onComplete: () => {
        window.video_element.muted = false;
        window.video_element.setAttribute("controls",true);
      },
      onStart: () => {
        window.videoModal = true;
      },
      onReverseComplete: () => {
        (window.originalContainer).appendChild(window.video_element);
        window.video_element.muted = true;
        window.video_element.removeAttribute("controls");
        window.video_element.style.transform = 'translate3d(0px, 0px, 0px) scale(1, 1)';
        window.videoModal = false;
      }
    });
    origContainers.forEach(function(originalContainer){
      originalContainer.addEventListener("click", function(event){
        event.preventDefault();
        window.originalContainer = document.querySelector("#videos .swiper-slide.swiper-slide-active>div>div");
        window.video_element = document.querySelector("#videos .swiper-slide.swiper-slide-active>div>div video");
        window.state = Flip.getState(window.video_element);
        tlineVideo.to(newContainer,
          {
            autoAlpha: 1,
            pointerEvents: 'auto',
            ease: "linear",
            duration: 0.25,
            onStart: () => {
              (newContainer).appendChild(window.video_element);
              Flip.from(window.state, {
                duration: 0.25,
                ease: "linear",
                scale: true
              })
            }
          }
        )
        tlineVideo.play();
      })
    })
    var closeContainer = document.querySelector(".newContainerVideo .close");
    if (closeContainer!==null) {
      closeContainer.addEventListener("click", function(event){
        event.preventDefault();
        tlineVideo.reverse();
      })
    }
    console.log('currentVideos===>');
    console.log(currentVideos);
  }, [currentVideos]);

  useEffect(() => {
    var wrapper = document.querySelector('div#info_detail>div');
    var content = document.querySelector('div#info_detail>div>div');
    if (wrapper!==null) {
      var lenis = new Lenis({
        wrapper: wrapper,
        content: content,
        wheelEventsTarget: window,
        lerp: 1,
        duration: 2,
        wheelMultiplier: .75
      })
      gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
      })
    }
  }, []);

  function autoPlayVideo(){
    var activeIndex = videoSwiper.activeIndex;
    var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
    var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
    activeSlideVideo.play();
  }

  const navigate = useNavigate();
  function goToView(route){
    document.querySelector("html").classList.add("exit");
    setTimeout( function() {
      navigate(route);
    }, 500);
  }

  return(
  <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
  <Menu propType={'lighten'}/>
  <style>{`
    @media (max-width: 767.98px) {
      html body {
          overflow-y: auto;
      }
    }
  `}</style>
  <Helmet>
    <title>HELO - {currentCreator.nombre}</title>
    <meta name='description' content={currentCreator.extracto} />
  </Helmet>

    <section className="fix_overflow darked" id="creator_detail">
      <div className="container-fluid p-0">
        <div className="row m-0">
        {currentVideos.length>1?
          <div className="col-12 col-md-6 p-0 creator_det" id="videos">
            <div>
              <Swiper
                direction="vertical"
                speed={300}
                slidesPerView={3}
                centeredSlides={true}
                spaceBetween={0}
                mousewheel={true}
                loop={true}
                onSwiper={setVideoSwiper}
                breakpoints={{ 
                  0: {
                    direction: "horizontal"
                  },
                  768: {
                    direction: "vertical"
                  }
                }}
              >
                {currentVideos.map((video, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="row h-100 m-0">
                        <div className="col-12">
                          <video loop muted preload="metadata" id={"vid_"+index} alt={currentCreator.nombre} poster={video['poster']}>
                              <source type="video/mp4" src={video['360']} media="all and (max-width: 480px)"/>
                              <source type="video/mp4" src={video['540']} media="all and (max-width: 540px)"/>
                              <source type="video/mp4" src={video['720']} media="all and (max-width: 720px)"/>
                              <source type="video/mp4" src={video['1080']} media="all and (max-width: 1080px)"/>
                          </video>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        :
          <div className="col-12 col-md-6 p-0 creator_det" id="one_video">
            <div className="row m-0 h-100 justify-content-center align-items-center">
              <div className="col-12 p-0 text-center">
                {currentVideos.length>0?
                  <video className="block_ctrl" loop controls autoPlay={true} alt={currentCreator.nombre} poster={currentVideos[0]['poster']}>
                      <source type="video/mp4" src={currentVideos[0]['360']} media="all and (max-width: 480px)"/>
                      <source type="video/mp4" src={currentVideos[0]['540']} media="all and (max-width: 540px)"/>
                      <source type="video/mp4" src={currentVideos[0]['720']} media="all and (max-width: 720px)"/>
                      <source type="video/mp4" src={currentVideos[0]['1080']} media="all and (max-width: 1080px)"/>
                  </video>
                :''}
              </div>
            </div>
          </div>
        }
          <div className="col-12 col-md-6" id="info_detail">
            <div className="row m-0 align-content-end justify-content-start">
              <div className="col-12">
                <span className="title">{currentCreator.nombre}</span>
                <span className="sub">{currentCreator.sub}</span>
                <div className="description">
                  {currentCreator.contenido.map((parrafo, index) => {
                    return (
                      <p className="pb-5 block_ctrl" key={index+'_paragraph'}>{parrafo}</p>
                    );
                  })}
                </div>
              </div>  
            </div>
            <div className="overlay_fixed_creator"></div>
          </div>
        </div>
      </div>
    </section>
    <div >
      <div></div>
      <span></span>
    </div>
    <div className="overlay_back">
      <Link
        className="back_creators"
        onClick={() => {
          goToView('/creators');
        }}
      >
        <img src={arrow_back} alt=""/>
        <span>Back to creators</span>
      </Link>
    </div>
    <div className="newContainerVideo">
      <div className="close">
        <div></div>
      </div>
    </div>
  </motion.div>
  );
};

export default Creator;