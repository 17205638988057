import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { Flip } from "gsap/Flip";

import arrow_back from "../../assets/img/arrow_back.svg";

import SparkObject from '../../components/submodules/SparkObject';

import alternate_futures_01 from "../../assets/img/spark/alternate_futures_01.jpg";
import alternate_futures_02 from "../../assets/img/spark/alternate_futures_02.jpg";
import ai_dreams_01 from "../../assets/img/spark/ai_dreams_01.jpg";
import ai_dreams_02 from "../../assets/img/spark/ai_dreams_02.jpg";
import ai_dreams_03 from "../../assets/img/spark/ai_dreams_03.jpg";
import faistandfurious from "../../assets/img/spark/faistandfurious.jpg";

import diffused_img from "../../assets/img/spark/ddd/diffused/01_DIFFUSED.jpg";
import difussed_04 from "../../assets/img/spark/ddd/diffused/03_heymesh.jpg";


import AI_dreams01 from "../../assets/img/spark/ddd/AI_dreams01.jpg";
import AI_dreams02 from "../../assets/img/spark/ddd/AI_dreams02.jpg";
import Alternate_Futures001 from "../../assets/img/spark/ddd/Alternate_Futures001.jpg";
import Alternate_Futures002 from "../../assets/img/spark/ddd/Alternate_Futures002.jpg";

import Alternate_Futures02 from "../../assets/img/spark/ddd/Alternate_Futures02.jpg";
import Brassroots_District02 from "../../assets/img/spark/ddd/Brassroots_District02.jpg";
import Brassroots_Districts01 from "../../assets/img/spark/ddd/Brassroots_Districts01.jpg";
import Brassroots_Districts03 from "../../assets/img/spark/ddd/Brassroots_Districts03.jpg";
import Surf_Genie01 from "../../assets/img/spark/ddd/Surf_Genie01.jpg";
import Surf_Genie02 from "../../assets/img/spark/ddd/Surf_Genie02.jpg";

import Menu from '../../components/submodules/Menu';
import Indice from '../../components/submodules/Indice';
import { motion } from "framer-motion/dist/framer-motion";

gsap.registerPlugin(Flip, ScrollTrigger, ScrollSmoother);

const HeloSparkV3 = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [currentProject, setCurrentProject] = useState(null);
  const [isMinTo768, setIsMinTo768] = useState(window.innerWidth < 768);
  const [isMinTo992, setIsMinTo992] = useState(window.innerWidth < 992);
  const [isIos] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
  const [isSafari] = useState(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  const [colorMenu, setColorMenu] = useState('darken');
  const [transitioned, setTransitioned] = useState(false);
  const [secondImage, setSecondImage] = useState(false);

  const [oldContainer, setOldContainer] = useState(null);
  const [proyectoImg, setProyectoImg] = useState(null);

  function goToView(route){
    document.querySelector("html").classList.add("exit");
    setTimeout( function() {
      navigate(route);
    }, 500);
  }
  function goToPage(url) {
    if (isIos) {
      document.querySelector("html").classList.add("exit");
      setTimeout(function() {
        window.location.href = url;
      }, 500);
    }else{
      goToView(url);
    }
  }
  function goToViewReload(url) {
    setTimeout(function() {
      window.location.href = url;
    }, 500);
  }

  /*<----SECCION ESTANDAR SE OCULTA LOADER FADE*/
  useEffect(() => {
    //document.querySelector("html").classList.remove("exit");
  }, []);
  /*SECCION ESTANDAR SE OCULTA LOADER FADE------>*/

  useEffect(() => {
    setCurrentProject(null);
    setTransitioned(false);
  }, []);

  /*<------EVITA NAVEGACIÓN CON FLECHAS NATIVAS PARA DIRIGIR A LA SECCION PRINCIAL AL DAR ATRAS*/
  useEffect(() => {
    const handlePopstate = (event) => {
      if (event?.type === 'popstate') {
        window.location.href = '/helo-spark-v3';
        event.preventDefault();
      }
    };
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [location]); // Vuelve a suscribir el efecto cuando cambie la ubicación.
  /*EVITA NAVEGACIÓN CON FLECHAS NATIVAS PARA DIRIGIR A LA SECCION PRINCIAL AL DAR ATRAS----->*/

  /*<------DA VALOR A VARIABLE isMinTo768 AL RESIZE*/
  const handleResize = () => {
    setIsMinTo768(window.innerWidth < 768);
    setIsMinTo992(window.innerWidth < 992);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  /*DA VALOR A VARIABLE isMinTo768 AL RESIZE----->*/

  /*<------SI EL PROYECTO VIENE COMO PROP SE SETEA EL PROYECTO*/
  useEffect(() => {
    if (props.propProject!==undefined) {
      setCurrentProject(props.propProject);
    }
  }, [props]);
  /*SI EL PROYECTO VIENE COMO PROP SE SETEA EL PROYECTO----->*/


  /*<-----EFECTO DE ENTRADA DE VISTA INDIVIDUAL*/
  const goToDetail = (url, old_container, proyecto_img) => {
    let targetProject;
    for (const element of SparkObject) {
      if (element.url === url) {
        targetProject = element;
        break;
      }
    }
    setTransitioned(true);
    setOldContainer(old_container);
    setProyectoImg(proyecto_img);
    setCurrentProject(targetProject);
  };

  const fullModal = document.getElementById("fullModal");

  useEffect(() => {
    if (currentProject!==null && transitioned===true) {
      window.old_container_el = document.getElementById(oldContainer);
      window.new_container_el = document.querySelector('div#new_container #p_1');
      window.image = document.getElementById(proyectoImg);
      const state = Flip.getState(window.image);
      fullModal.classList.add("start_");
      window.new_container_el.appendChild(window.image);
      window.transition = Flip.from(state, {
        scale: true,
        duration: 1,
        ease: "power1.out",
        paused: true,
        delay: 0.5,
        onStart: () => {
          window.history.pushState(null, "", currentProject.url);
        },
        onComplete: () => {
          setTimeout(function() {
          }, 750);
        },
      });
      window.transition.play();
    }else{
      if (fullModal!==null) {
        fullModal.classList.remove("start_");
      }
      if (typeof window.transition !== 'undefined') {
        window.old_container_el.appendChild(window.image);
        //window.transition.reverse();
      }
    }
  }, [currentProject, transitioned]);

  /*EFECTO DE ENTRADA DE VISTA INDIVIDUAL----->*/

  /*<----MOSTRAR MENU E INDECE SE VUELVE DARK*/
  const [showIndice, setShowIndice] = useState(false);
  function toggleIndice(){
    setShowIndice(!showIndice);
  }
  useEffect(() => {
    if (showIndice) {
      setColorMenu('darken');
    }else{
      if (currentProject!==null) {
        setColorMenu('lighten');
      }else{
        setColorMenu('darken');
      }
    }
  }, [showIndice]);
  /*MOSTRAR MENU E INDECE SE VUELVE DARK----->*/

  /*<----AL DEFINIR PROYECTO SEA AGREGA EFECTO DE SMOOTH SCROLL AL NUEVO CONTENIDO CON CONDICIONAL DE TAMAÑO*/
  useEffect(() => {
    const spark_containero = document.getElementById('spark_container');
    if (currentProject===null) {
      spark_containero.style.pointerEvents = 'none';
      document.body.classList.remove('block_gral_events');
      setColorMenu('darken');
      let smoothScroll = ScrollSmoother.create({
        wrapper: '#spark_container',
        content: '#spark_container>div',
        smooth: isSafari?0.2:0.8,
        effects: !isMinTo768,
        normalizeScroll: true
      });
    }else{
      spark_containero.style.pointerEvents = 'auto';
      document.body.classList.add('block_gral_events');
      const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
      setColorMenu('lighten');
      let smoothScrollTwo = ScrollSmoother.create({
        wrapper: '#fullModal>div',
        content: '#fullModal>div>div',
        smooth: 1,
        effects: !isMinTo768,
        normalizeScroll: !isTouchDevice
      });
    }
  }, [currentProject]);
  /*AL DEFINIR PROYECTO SEA AGREGA EFECTO DE SMOOTH SCROLL AL NUEVO CONTENIDO CON CONDICIONAL DE TAMAÑO----->*/

  /*<-------VIDEO PLAY IN VIEWPORT*/
  useEffect(() => {
    if (currentProject!==null) {
      const videoElements = document.querySelectorAll('#new_container .video_media>video');
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          const videoElement = entry.target;
          if (entry.isIntersecting) {
            videoElement.play();
          } else {
            videoElement.pause();
          }
        });
      };
      const observer = new IntersectionObserver(handleIntersection, options);
      videoElements.forEach((videoElement) => observer.observe(videoElement));
      return () => {
        videoElements.forEach((videoElement) => observer.unobserve(videoElement));
      };
    }
  }, [currentProject]);
  /*VIDEO PLAY IN VIEWPORT----------->*/

  /*<------------ FULLSCREEN ONCLICK VIDEO*/
  const handleFullscreenClick = (event) => {
    const element = event.target;
    if (isIos) {
      if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.requestFullscreen) {
        element.requestFullscreen();
      }
    }else{
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  }
useEffect(() => {
  const handleFullscreenChange = () => {
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullscreenElement;

    if (fullscreenElement) {
      const videoElements = document.querySelectorAll('.video_media>video');
      videoElements.forEach((videoElement) => {
        if (videoElement === fullscreenElement) {
          // Obtener la fuente de video que mejor se adapta al tamaño de la pantalla
          let bestSource = null;
          const screenWidth = window.innerWidth;
          videoElement.querySelectorAll('source').forEach((source) => {
            const sourceWidth = parseInt(source.getAttribute('data-width'));
            if (!bestSource || (sourceWidth && sourceWidth <= screenWidth)) {
              bestSource = source;
            }
          });
  
          // Cambiar la fuente del video si se encontró una fuente adecuada
          if (bestSource) {
            videoElement.src = bestSource.src;
          }
  
          videoElement.currentTime = 1;
          videoElement.muted = false;
          videoElement.play();
          videoElement.style.cursor = "default";
        }
      });
    } else {
      const videoElements = document.querySelectorAll('.video_media video');
      videoElements.forEach((videoElement) => {
        videoElement.muted = true;
        videoElement.style.cursor = "none";
      });
    }
  };

  const videoElements = document.querySelectorAll('.video_media video');
  videoElements.forEach((videoElement) => {
    videoElement.addEventListener('fullscreenchange', handleFullscreenChange);
    videoElement.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    videoElement.addEventListener('mozfullscreenchange', handleFullscreenChange);
  });

  return () => {
    videoElements.forEach((videoElement) => {
      videoElement.removeEventListener('fullscreenchange', handleFullscreenChange);
      videoElement.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      videoElement.removeEventListener('mozfullscreenchange', handleFullscreenChange);
    });
  };
}, [currentProject]);
  /*FULLSCREEN ONCLICK VIDEO----------->*/

  /* <---- CURSOR BALL*/
  useEffect(() => {
    if (currentProject!==null && isMinTo992===false) {
      /* <---- CURSOR BALL*/
      //const item_listener = document.querySelector('#fullModal>div>div');
      const item_listener = document.querySelector('#new_container>div');
      //const item_listener = document.getElementById('new_container');
      var cursor_play = document.getElementById('ball');
      var mouse = { x: 0, y: 0 }; // Cursor position
      var pos = { x: 0, y: 0 }; // Cursor position
      var ratio = 0.15; // delay follow cursor
      var active = false;
      item_listener.addEventListener("mousemove", mouseMove);
      function mouseMove(e) {
        mouse.x = e.clientX;
        mouse.y = e.clientY - 120;
      }
      gsap.ticker.add(updatePosition);
      function updatePosition() {
        if (!active) {
          pos.x += (mouse.x - pos.x) * ratio;
          pos.y += (mouse.y - pos.y) * ratio;
          gsap.set(cursor_play, { x: pos.x, y: pos.y });
        }
      }
      gsap.set(cursor_play, {
        xPercent: -50, 
        yPercent: -50, 
        width: "0px",
        height: "0px",
        backgroundColor: "transparent",
        opacity: 0
      });
      /*CURSOR BALL -----> */
      var videos = document.querySelectorAll('div.item_media>div.video_media>video');
      videos.forEach(function(video){
        video.addEventListener("mouseenter", function() {
          video.style.cursor = "none"; // Ocultar el puntero por defecto
          gsap.to(cursor_play, {
            duration: 0.3,
            opacity: .75,
            backgroundColor: "#000000",
            width: "75px",
            height: "75px",
          });
        });
        video.addEventListener("mouseleave", function() {
          video.style.cursor = "default"; // Restablecer el puntero por defecto
          gsap.to(cursor_play, {
            duration: 0.3,
            opacity: 0,
            backgroundColor: "transparent",
            width: "0px",
            height: "0px",
          });
        });
        video.addEventListener("mousedown", function() {
          gsap.to(cursor_play, {
            duration: 0.3,
            width: "100px",
            height: "100px",
          });
        });
        video.addEventListener("mouseup", function() {
          gsap.to(cursor_play, {
            duration: 0.3,
            width: "75px",
            height: "75px",
          });
        });
      });
    }
  }, [currentProject, isMinTo992]);
  /*CURSOR BALL -----> */


  const tagMap = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    p: 'p',
    span: 'span'
  };
  
  const renderParagraph = (parrafo, index) => {
    const Tag = tagMap[parrafo.type] || 'p';
    return <Tag key={index + '_paragraph'} dangerouslySetInnerHTML={{ __html: parrafo.text }} />;
  };

  const RenderText = (props) => {
    return <div dangerouslySetInnerHTML={{ __html: props.parrafo }} />;
  };

  return(
    <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
    <Menu propType={colorMenu}/>
    <Indice propShowIndice={showIndice} propSetShowIndice={setShowIndice} propSparkObject={SparkObject} />
    <Helmet>
      <title>HELO - Spak</title>
      <meta name='description' content='At HELO Spark, invention and originality is everything.' />
    </Helmet>
    <style>{`
      html{
        background-color: #CCCCCC !important;
      }
      html>body{
        overflow-y: auto;
        background-color: #CCCCCC !important;
      }
      div.submenu_{
        position: fixed;
      }
      button.icon{
        position: fixed;
        z-index: 99999;
        pointer-events: auto;
      }
    `}</style>

    {currentProject!==null?
      <style>{`
        html>body{
          overflow-y: hidden !important;
        }
        #fullModal.complete_{
          background-color: `+currentProject.color+` !important;
        }
      `}</style>
    :''}

          <section id="spark_container">
            <div className="container-fluid">

              <div className="row justify-content-end">
                <div data-speed="0.4" className="col-12 col-md-6 item_dev">
                  <div className="block_item">
                    <h1>HELO SPARK</h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div data-speed="0.35" className="col-12 col-md-6 text-start item_dev md">
                  <Link
                      className="block_item"
                      onClick={() => {goToDetail('spark-brassroots-district', 'old_container_ghjk1', 'image_erttey231ere23')}}
                    >
                    <div className="original_container" id="old_container_ghjk1">
                      <img className="standar_img" id="image_erttey231ere23" src={Brassroots_Districts01} alt="" />
                    </div>
                    <p>Brassroots Districts</p>
                  </Link>
                </div>
                <div data-speed="0.45" className="col-12 col-md-6 text-end item_dev">
                  <Link
                      className="block_item"
                      onClick={() => {goToDetail('spark-alternate-futures', 'old_container_tr1asd21g2f', 'image_32141')}}
                    >
                    <div className="original_container" id="old_container_tr1asd21g2f">
                      <img className="standar_img" id="image_32141" src={Alternate_Futures001} alt="" />
                    </div>
                    <p>Alternate Futures</p>
                  </Link>
                </div>
              </div>

              <div className="row justify-content-end" style={{zIndex: 1}} >
                <div data-speed="0.55" className="col-12 col-md-11 col-lg-10 col-xl-9 item_dev text-start">
                  <span className="header">At HELO Spark, invention and originality is everything.</span>
                </div>
              </div>

              <div className="row justify-content-end align-items-center">
                <div data-speed="0.6" className="col-12 col-md-6 text-start item_dev">
                  <Link
                      className="block_item"
                      onClick={() => {goToDetail('spark-ai-dreams', 'old_container_ghjkjgh12j', 'image_qwer2231')}}
                    >
                    <div className="original_container" id="old_container_ghjkjgh12j">
                      <img className="standar_img" id="image_qwer2231" src={AI_dreams01} alt="" />
                    </div>
                    <p>AI Dreams</p>
                  </Link>
                </div>
                <div data-speed="0.4" className="col-12 col-md-6 content_ text-start">
                  <div className="row m-0 justify-content-start">
                    <div className="col-12 col-md-6 p-0">
                      <p className="paragraph">Here, we meddle with the familiar - film, episodic, theatrical, original IP, even as we charge into new paradigms, testing ideas and integrating unusual formats in unexpected ways. Our alchemists here are encouraged to engineer the transcendent storytelling techniques of tomorrow.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center align-items-center">
                <div data-speed="0.4" className="col-12 col-md-6 content_ text-start">
                  <div className="row m-0 justify-content-end">
                    <div className="col-12 col-md-6 p-0">
                      <p className="paragraph">We do all this to find original and authentic avenues to reach audiences for our clients - today’s perplexing prototype becoming tomorrow’s product.</p>
                    </div>
                  </div>
                </div>
                <div data-speed="0.65" className="col-12 col-md-6 text-end item_dev order-first order-md-last">
                  <Link
                      className="block_item"
                      onClick={() => {goToDetail('spark-surf-genie', 'old_container_fg3h21fgd1', 'image_wqe23r12wqe12')}}
                    >
                    <div className="original_container" id="old_container_fg3h21fgd1">
                      <img className="standar_img" id="image_wqe23r12wqe12" src={Surf_Genie01} alt="" />
                    </div>
                    <p>Surf Genie</p>
                  </Link>
                </div>
              </div>

              <div className="row justify-content-center align-items-center">
                <div data-speed="0.65" className="col-12 col-md-6 text-start item_dev">
                  <Link
                      className="block_item"
                      onClick={() => {goToDetail('spark-brassroots-district', 'old_container_6546571124', 'image_dfg56471')}}
                    >
                    <div className="original_container" id="old_container_6546571124">
                      <img className="standar_img" id="image_dfg56471" src={Brassroots_District02} alt="" />
                    </div>
                    <p>Brassroots District</p>
                  </Link>
                </div>
                <div data-speed="0.4" className="col-12 col-md-6 content_ text-start">
                  <div className="row m-0 justify-content-start">
                    <div className="col-12 p-0">
                      <p className="paragraph xl text-center text-md-start pt-5 pt-md-0">THERE’S METHOD TO OUR MADNESS</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center align-items-center">
                <div data-speed="0.4" className="col-12 col-md-6 content_ text-start">
                  <div className="row m-0 justify-content-end">
                    <div className="col-12 col-md-8 p-0 info_content">
                      <div className="row m-0">
                        <div className="col-12 p-0 d-none d-md-inline-block">
                          <p className="paragraph lg">ALTERNATE FUTURES</p>
                        </div>
                        <div className="col-12 paragraph_content">
                          <p className="paragraph">Harnessing the collective imagination of millions via A.I. to create connections that will drive real action.</p>
                          <p className="paragraph">Empowering humanity towards an intentional tomorrow by instigating fresh conversations on the benefits of living in a post-climate world.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-speed="0.65" className="col-12 col-md-6 text-end item_dev order-first order-md-last">
                  <Link
                      className="block_item"
                      onClick={() => {setSecondImage(true); goToDetail('spark-alternate-futures', 'old_container_9800711', 'image_365456417')}}
                    >
                    <div className="original_container" id="old_container_9800711">
                      <img className="standar_img" id="image_365456417" src={Alternate_Futures002} alt="" />
                    </div>
                    <p>Alternate Futures</p>
                  </Link>
                </div>
              </div>


              <div className="row justify-content-end align-items-center">
                <div data-speed="0.65" className="col-12 col-md-6 text-start item_dev">
                  <Link
                      className="block_item"
                      onClick={() => {goToDetail('spark-ai-dreams', 'old_container_65321324', 'image_01207111')}}
                    >
                    <div className="original_container" id="old_container_65321324">
                      <img className="standar_img" id="image_01207111" src={AI_dreams02} alt="" />
                    </div>
                    <p>AI Dreams</p>
                  </Link>
                </div>
                <div data-speed="0.4" className="col-12 col-md-6 content_ text-start">
                  <div className="row m-0 justify-content-start">
                    <div className="col-12 col-md-8 p-0 info_content">
                      <div className="row m-0">
                        <div className="col-12 p-0 d-none d-md-inline-block">
                          <p className="paragraph lg">AI DREAMS</p>
                        </div>
                        <div className="col-12 paragraph_content">
                          <p className="paragraph">What would Artificial Intelligence do, if handed the original briefs we've received?</p>
                          <p className="paragraph">HELO decided to find out. We discovered that while the technology offers amazing tools, there’s still no replacing the creative spirit and human intuition within the equation.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center align-items-center">
                <div data-speed="0.4" className="col-12 col-md-6 content_ text-start">
                  <div className="row m-0 justify-content-end">
                    <div className="col-12 col-md-9 p-0 info_content">
                      <div className="row m-0">
                        <div className="col-12 p-0 d-none d-md-inline-block">
                          <p className="paragraph lg">BRASSROOTS DISTRICT: <small>RECORD RELEASE PARTY</small></p>
                        </div>
                        <div className="col-12 paragraph_content">
                          <p className="paragraph">With Les Enfants Terribles and creators Ari Herstedt and Andrew Lieb, HELO is developing an immersive musical experience for Las Vegas that takes audiences into the funkadelic world of 1973 through original music and immersive theater.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-speed="0.65" className="col-12 col-md-6 text-end item_dev order-first order-md-last">
                  <Link
                      className="block_item"
                      onClick={() => {goToDetail('spark-brassroots-district', 'old_container_712241', 'image_32154857841')}}
                    >
                    <div className="original_container" id="old_container_712241">
                      <img className="standar_img" id="image_32154857841" src={Brassroots_Districts03} alt="" />
                    </div>
                    <p>Brassroots District</p>
                  </Link>
                </div>
              </div>


              <div className="row justify-content-end align-items-center">
                <div data-speed="0.65" className="col-12 col-md-6 text-start item_dev">
                  <Link
                      className="block_item"
                      onClick={() => {goToDetail('spark-surf-genie', 'old_container_ytretey231dfg1', 'image_fgdh23311')}}
                    >
                    <div className="original_container" id="old_container_ytretey231dfg1">
                      <img className="standar_img" id="image_fgdh23311" src={Surf_Genie02} alt="" />
                    </div>
                    <p>Surf Genie</p>
                  </Link>
                </div>
                <div data-speed="0.4" className="col-12 col-md-6 content_ text-start">
                  <div className="row m-0 justify-content-start">
                    <div className="col-12 col-md-8 p-0 info_content">
                      <div className="row m-0">
                        <div className="col-12 p-0 d-none d-md-inline-block">
                          <p className="paragraph lg">SURF GENIE</p>
                        </div>
                        <div className="col-12 paragraph_content">
                          <p className="paragraph">Combining a passion for wave riding, creative thinking and some good old fashioned human knowledge with Artificial Intelligence, to create the ultimate surfing guru.</p>
                          <p className="paragraph">Observing Kyle’s knack for pointing friends to the best surf spots, Brendan Kiernan mused, “Kyle, you need to make an A.I. surf Genie”. Thus, the idea was born.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center align-items-center">
                <div data-speed="0.4" className="col-12 col-md-6 content_ text-start">
                  <div className="row m-0 justify-content-end">
                    <div className="col-12 col-md-9 p-0 info_content">
                      <div className="row m-0">
                        <div className="col-12 p-0 d-none d-md-inline-block">
                          <p className="paragraph lg">Introducing DIFFUSED:</p>
                        </div>
                        <div className="col-12 paragraph_content">
                          <p className="paragraph">Introducing DIFFUSED, a creative studio that combines design thinking with cutting edge technical know-how, aiming to create meaningful connections with audiences by staying ahead of the curve and engaging communities by democratising technology.</p>
                          <p className="paragraph">DIFFUSED represents HELO SPARK’s first official technologist-in-residency, click through to read more about our collective ambitions.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-speed="0.65" className="col-12 col-md-6 text-end item_dev order-first order-md-last">
                  <Link
                      className="block_item"
                      onClick={() => {goToDetail('spark-diffused', 'old_container_6544123', 'image_4213214')}}
                    >
                    <div className="original_container" id="old_container_6544123">
                      <img className="standar_img" id="image_4213214" src={difussed_04} alt="" />
                    </div>
                    <p>Diffused</p>
                  </Link>
                </div>
              </div>


            </div>
          </section>
          <div id="fullModal" className={currentProject!==null?'complete_':''} style={currentProject!==null ? {backgroundColor: currentProject.color+' !important'} : {} }>
            <div className="container-fluid p-0">
              <div className="row m-0 align-items-start justify-content-center">

                {currentProject!==null?   
                  <React.Fragment>
                    {currentProject.type_theme==='one_column'?
                      <React.Fragment>
                        <div className="col-12 p-0 order-2 order-md-1" id="new_container">
                          <div className="row m-0">
                            <React.Fragment>
                              {currentProject.files.map((file, index) => {
                                if (index===0) {
                                  if (!transitioned) {
                                    return (
                                      <React.Fragment>
                                        <div className="col-12 col-md-7 item_media" key={index+'_img'}>
                                          {file.type==='video' ?
                                            'El primer archivo debe de ser una imagén'
                                          :
                                            <img src={file.data} alt={currentProject.name} />  
                                          }
                                        </div>
                                        <div className="col-md-5 pb-5 pb-md-0 item_info text-start" key={index+'_info'}>
                                          <h1><RenderText parrafo={file.title}/></h1>
                                          <p><RenderText parrafo={file.text}/></p>
                                        </div>
                                      </React.Fragment>
                                    );
                                  }else{
                                    return (
                                      <React.Fragment>
                                        <div className="col-12 col-md-7 item_media" key={index+'_img'} id="p_1"></div>
                                        <div className="col-md-5 pb-5 pb-md-0 item_info text-start" key={index+'_info'}>
                                          <h1><RenderText parrafo={file.title}/></h1>
                                          <p><RenderText parrafo={file.text}/></p>
                                        </div>
                                      </React.Fragment>
                                    );
                                  }
                                }else{
                                  //FULL
                                  return (
                                    <React.Fragment>
                                      <div className="col-12 col-md-7 item_media" key={index+'_img'}>
                                        <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                          {file.type==='video' ?
                                            <React.Fragment>
                                              <div className="player">
                                                <div></div>
                                              </div>
                                              <video loop muted preload="metadata" alt={currentProject.nombre} poster={file.data['poster']} onClick={handleFullscreenClick}>
                                                <source type="video/mp4" src={file.data['sm']} data-width="540"/>
                                                <source type="video/mp4" src={file.data['lg']} data-width="1080"/>
                                              </video>
                                            </React.Fragment>
                                          :
                                            <img src={file.data} alt={currentProject.name} />
                                          }
                                        </div>
                                      </div>
                                      <div className="col-md-5 pb-5 pb-md-0 item_info text-start" key={index+'_info'}>
                                        <h5><RenderText parrafo={file.title}/></h5>
                                        <p><RenderText parrafo={file.text}/></p>
                                      </div>
                                    </React.Fragment>
                                  );
                                }
                              })}
                            </React.Fragment>
                          </div>
                        </div>
                      </React.Fragment>
                    :''}

                    {currentProject.type_theme==='normal_001'?
                      <React.Fragment>
                        <div className="col-12 col-md-6 col-lg-8 p-0 order-2 order-md-1" id="new_container">
                          <div className="row m-0">
                            <div className={`col-12 item_media col_complete ${transitioned ? '' : 'py-0'}`} key='00_img' id="p_1">
                            </div>          
                            <React.Fragment>
                              {currentProject.files.map((file, index) => {
                                if (index===0) {
                                  if (!transitioned) {
                                    return (
                                      <div className="col-12 item_media col_complete" key={index+'_img'}>
                                        {!secondImage ?
                                        <img src={Alternate_Futures001} />
                                        :
                                        <img src={Alternate_Futures002} />
                                        }
                                      </div>
                                    );
                                  }
                                }else if(index===1){
                                  //FULL
                                  return (
                                    <div className="col-12 item_media col_complete" key={index+'_img'}>
                                      <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                        {file.type==='video' ?
                                          <React.Fragment>
                                            <div className="player">
                                              <div></div>
                                            </div>
                                            <video loop muted preload="metadata" alt={currentProject.nombre} poster={file.data['poster']} onClick={handleFullscreenClick}>
                                              <source type="video/mp4" src={file.data['sm']} data-width="540"/>
                                              <source type="video/mp4" src={file.data['lg']} data-width="1080"/>
                                            </video>
                                          </React.Fragment>
                                        :
                                          <img src={file.data} alt={currentProject.name} />
                                        }
                                      </div>
                                    </div>
                                  );
                                }else if(index===2){
                                  //FULL
                                  return (
                                    <div className="col-12 item_media col_complete" key={index+'_img'}>
                                      <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                        {secondImage ?
                                        <img src={Alternate_Futures001} />
                                        :
                                        <img src={Alternate_Futures002} />
                                        }
                                      </div>
                                    </div>
                                  );
                                }else{
                                  //FULL
                                  return (
                                    <div className="col-12 item_media col_complete" key={index+'_img'}>
                                      <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                        {file.type==='video' ?
                                          <React.Fragment>
                                            <div className="player">
                                              <div></div>
                                            </div>
                                            <video loop muted preload="metadata" alt={currentProject.nombre} poster={file.data['poster']} onClick={handleFullscreenClick}>
                                              <source type="video/mp4" src={file.data['sm']} data-width="540"/>
                                              <source type="video/mp4" src={file.data['lg']} data-width="1080"/>
                                            </video>
                                          </React.Fragment>
                                        :
                                          <img src={file.data} alt={currentProject.name} />
                                        }
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </React.Fragment>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 px-0 order-1 order-md-2" id="content">
                          <div className="row align-items-start justify-content-start" data-speed="1.2" data-lag=".2">
                            <div className="col-12 col-lg-11 col-xl-10 item_info">
                              {currentProject!==null?
                                <React.Fragment>
                                  <h1>{currentProject.name}</h1>
                                  {currentProject.content.map(renderParagraph)}
                                </React.Fragment>
                              :''}
                            </div>  
                          </div>
                        </div>
                      </React.Fragment>
                    :''}

                    {currentProject.type_theme==='normal_01'?
                      <React.Fragment>
                        <div className="col-12 col-md-6 col-lg-8 p-0 order-2 order-md-1" id="new_container">
                          <div className="row m-0">
                            <div className={`col-12 item_media col_complete ${transitioned ? '' : 'py-0'}`} key='00_img' id="p_1">
                            </div>          
                            <React.Fragment>
                              {currentProject.files.map((file, index) => {
                                if (index===0) {
                                  if (!transitioned) {
                                    return (
                                      <div className="col-12 item_media col_complete" key={index+'_img'}>
                                        {file.type==='video' ?
                                          'El primer archivo debe de ser una imagén'
                                        :
                                          <img src={file.data} alt={currentProject.name} />  
                                        }
                                      </div>
                                    );
                                  }
                                }else{
                                  //FULL
                                  return (
                                    <div className="col-12 item_media col_complete" key={index+'_img'}>
                                      <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                        {file.type==='video' ?
                                          <React.Fragment>
                                            <div className="player">
                                              <div></div>
                                            </div>
                                            <video loop muted preload="metadata" alt={currentProject.nombre} poster={file.data['poster']} onClick={handleFullscreenClick}>
                                              <source type="video/mp4" src={file.data['sm']} data-width="540"/>
                                              <source type="video/mp4" src={file.data['lg']} data-width="1080"/>
                                            </video>
                                          </React.Fragment>
                                        :
                                          <img src={file.data} alt={currentProject.name} />
                                        }
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </React.Fragment>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 px-0 order-1 order-md-2" id="content">
                          <div className="row align-items-start justify-content-start" data-speed="1.2" data-lag=".2">
                            <div className="col-12 col-lg-11 col-xl-10 item_info">
                              {currentProject!==null?
                                <React.Fragment>
                                  <h1>{currentProject.name}</h1>
                                  {currentProject.content.map(renderParagraph)}
                                </React.Fragment>
                              :''}
                            </div>  
                          </div>
                        </div>
                      </React.Fragment>
                    :''}
                    {currentProject.type_theme==='normal_02'?
                      <React.Fragment>
                        <div className="col-12 col-md-6 col-lg-8 p-0 order-2 order-md-1" id="new_container">
                          <div className="row m-0">
                            <div className={`col-12 item_media col_complete ${transitioned ? '' : 'py-0'}`} key='00_img' id="p_1">
                            </div>          
                            <React.Fragment>
                              {currentProject.files.map((file, index) => {
                                if (index===0) {
                                  if (!transitioned) {
                                    return (
                                      <div className="col-12 item_media col_complete" key={index+'_img'}>
                                        {file.type==='video' ?
                                          'El primer archivo debe de ser una imagén'
                                        :
                                          <img src={file.data} alt={currentProject.name} />  
                                        }
                                      </div>
                                    );
                                  }
                                }else if(index===1){
                                  //FULL
                                  return (
                                    <div className="col-12 item_media col_complete" key={index+'_img'}>
                                      <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                        {file.type==='video' ?
                                          <React.Fragment>
                                            <div className="player">
                                              <div></div>
                                            </div>
                                            <video loop muted preload="metadata" alt={currentProject.nombre} poster={file.data['poster']} onClick={handleFullscreenClick}>
                                              <source type="video/mp4" src={file.data['sm']} data-width="540"/>
                                              <source type="video/mp4" src={file.data['lg']} data-width="1080"/>
                                            </video>
                                          </React.Fragment>
                                        :
                                          <img src={file.data} alt={currentProject.name} />
                                        }
                                      </div>
                                    </div>
                                  );
                                }else if(index===2){
                                  //LEFT
                                  return (
                                    <div className="col-12 col-md-4 item_media" key={index+'_img'}>
                                      <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                        {file.type==='video' ?
                                          <React.Fragment>
                                            <div className="player">
                                              <div></div>
                                            </div>
                                            <video loop muted preload="metadata" alt={currentProject.nombre} poster={file.data['poster']} onClick={handleFullscreenClick}>
                                              <source type="video/mp4" src={file.data['sm']} data-width="540"/>
                                              <source type="video/mp4" src={file.data['lg']} data-width="1080"/>
                                            </video>
                                          </React.Fragment>
                                        :
                                          <img src={file.data} alt={currentProject.name} />
                                        }
                                      </div>
                                    </div>
                                  );
                                }else if(index===3){
                                  //RIGHT
                                  return (
                                    <div className="col-12 col-md-8 item_media" key={index+'_img'}>
                                      <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                        {file.type==='video' ?
                                          <React.Fragment>
                                            <div className="player">
                                              <div></div>
                                            </div>
                                            <video loop muted preload="metadata" alt={currentProject.nombre} poster={file.data['poster']} onClick={handleFullscreenClick}>
                                              <source type="video/mp4" src={file.data['sm']} data-width="540"/>
                                              <source type="video/mp4" src={file.data['lg']} data-width="1080"/>
                                            </video>
                                          </React.Fragment>
                                        :
                                          <img src={file.data} alt={currentProject.name} />
                                        }
                                      </div>
                                    </div>
                                  );
                                }else if(index===4){
                                  //FULL
                                  return (
                                    <div className="col-12 item_media col_complete" key={index+'_img'}>
                                      <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                        {file.type==='video' ?
                                          <React.Fragment>
                                            <div className="player">
                                              <div></div>
                                            </div>
                                            <video loop muted preload="metadata" alt={currentProject.nombre} poster={file.data['poster']} onClick={handleFullscreenClick}>
                                              <source type="video/mp4" src={file.data['sm']} data-width="540"/>
                                              <source type="video/mp4" src={file.data['lg']} data-width="1080"/>
                                            </video>
                                          </React.Fragment>
                                        :
                                          <img src={file.data} alt={currentProject.name} />
                                        }
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </React.Fragment>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 px-0 order-1 order-md-2" id="content">
                          <div className="row align-items-start justify-content-start" data-speed="1.2" data-lag=".2">
                            <div className="col-12 col-lg-11 col-xl-10 item_info">
                              {currentProject!==null?
                                <React.Fragment>
                                  <h1>{currentProject.name}</h1>
                                  {currentProject.content.map(renderParagraph)}
                                </React.Fragment>
                              :''}
                            </div>  
                          </div>
                        </div>
                      </React.Fragment>
                    :''}
                    {currentProject.type_theme==='normal_03'?
                      <React.Fragment>
                        <div className="col-12 col-md-6 col-lg-8 p-0 order-2 order-md-1" id="new_container">
                          <div className="row m-0">
                            <div className={`col-12 item_media col_complete ${transitioned ? '' : 'py-0'}`} key='00_img' id="p_1">
                            </div>          
                            <React.Fragment>
                              {currentProject.files.map((file, index) => {
                                if (index===0) {
                                  if (!transitioned) {
                                    return (
                                      <div className="col-12 item_media col_complete" key={index+'_img'}>
                                        {file.type==='video' ?
                                          'El primer archivo debe de ser una imagén'
                                        :
                                          <img src={file.data} alt={currentProject.name} />  
                                        }
                                      </div>
                                    );
                                  }
                                }else if(index===1){
                                  //FULL
                                  return (
                                    <div className="col-12 col-lg-6 item_media" key={index+'_img'}>
                                      <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                        {file.type==='video' ?
                                          <React.Fragment>
                                            <div className="player">
                                              <div></div>
                                            </div>
                                            <video loop muted preload="metadata" alt={currentProject.nombre} poster={file.data['poster']} onClick={handleFullscreenClick}>
                                              <source type="video/mp4" src={file.data['sm']} data-width="540"/>
                                              <source type="video/mp4" src={file.data['lg']} data-width="1080"/>
                                            </video>
                                          </React.Fragment>
                                        :
                                          <img src={file.data} alt={currentProject.name} />
                                        }
                                      </div>
                                    </div>
                                  );
                                }else if(index===2){
                                  //LEFT
                                  return (
                                    <div className="col-12 col-lg-6 item_media" key={index+'_img'}>
                                      <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                        {file.type==='video' ?
                                          <React.Fragment>
                                            <div className="player">
                                              <div></div>
                                            </div>
                                            <video loop muted preload="metadata" alt={currentProject.nombre} poster={file.data['poster']} onClick={handleFullscreenClick}>
                                              <source type="video/mp4" src={file.data['sm']} data-width="540"/>
                                              <source type="video/mp4" src={file.data['lg']} data-width="1080"/>
                                            </video>
                                          </React.Fragment>
                                        :
                                          <img src={file.data} alt={currentProject.name} />
                                        }
                                      </div>
                                    </div>
                                  );
                                }else{
                                  //FULL
                                  return (
                                    <div className="col-12 item_media col_complete" key={index+'_img'}>
                                      <div className={`${file.type==='video' ? 'video_media' : 'image_media'}`}>
                                        {file.type==='video' ?
                                          <React.Fragment>
                                            <div className="player">
                                              <div></div>
                                            </div>
                                            <video loop muted preload="metadata" alt={currentProject.nombre} poster={file.data['poster']} onClick={handleFullscreenClick}>
                                              <source type="video/mp4" src={file.data['sm']} data-width="540"/>
                                              <source type="video/mp4" src={file.data['lg']} data-width="1080"/>
                                            </video>
                                          </React.Fragment>
                                        :
                                          <img src={file.data} alt={currentProject.name} />
                                        }
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </React.Fragment>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 px-0 order-1 order-md-2" id="content">
                          <div className="row align-items-start justify-content-start" data-speed="1.2" data-lag=".2">
                            <div className="col-12 col-lg-11 col-xl-10 item_info">
                              {currentProject!==null?
                                <React.Fragment>
                                  <h1>{currentProject.name}</h1>
                                  {currentProject.content.map(renderParagraph)}
                                </React.Fragment>
                              :''}
                            </div>  
                          </div>
                        </div>
                      </React.Fragment>
                    :''}


                  </React.Fragment>
                :''}


              </div>

            <div className="overlay_back on_spark">
              <Link
                className="back_creators"
                // onClick={() => {
                //   navigate('/helo-spark-v3');
                // }}
                onClick={() => {
                  goToViewReload('/helo-spark-v3');
                }}
              >
                <img src={arrow_back} alt=""/>
                <span>Back to all</span>
              </Link>
            </div>

            </div>
            <div id="ball"></div>
          </div>
      <span className={`btn_toindex ${colorMenu}`} href="#" onClick={() => {toggleIndice();}}>INDEX</span>
  </motion.div>
  );
};

export default HeloSparkV3;