import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import { motion } from "framer-motion/dist/framer-motion";
import Menu from '../../components/submodules/Menu';
import ProjectsMovilV2 from './ProjectsMovilV2';
import ProjectsDesktopV2 from './ProjectsDesktopV2';
SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);

const ProjectsDeskmovV2 = () => {

  const [hash, setHash] = useState(null);
  const [initialSlide, setInitialSlide] = useState(7);

  const handleAddParam = (param) => {
    window.location.hash = param;
  };

  useEffect(() => {
    setHash(window.location.hash);
    const handlePageShow = (event) => {
      if (event.persisted) {
        document.querySelector("html").classList.remove("exit");
      }
    };
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function isAnyVideoInFullscreen() {
    const videoElements = document.getElementsByTagName('video');
    console.log('retry isAnyVideoInFullscreen 2')
    for (let i = 0; i < videoElements.length; i++) {
      if (document.fullscreenElement === videoElements[i] ||
          document.mozFullScreenElement === videoElements[i] ||
          document.webkitFullscreenElement === videoElements[i] ||
          document.msFullscreenElement === videoElements[i]) {
        return true;
      }
    }  
    return false;
  }

  useEffect(() => {
    const handleResize = () => {
      var isVideoFullscreen = isAnyVideoInFullscreen();
      if (!isVideoFullscreen) {
        setWindowWidth(window.innerWidth);
        console.log('handleResize');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
  }, []);

  const ComponentResize = (props) => {
    if (props.windowWidth < 992) {
      return <ProjectsMovilV2 handleAddParam={handleAddParam} hash={hash}/>;
    }else{
      return <ProjectsDesktopV2 />;
    }
  }

  return(
  <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
  <Menu propType={'darken'} propActive={'projects'}/>
  <style>{`
    .html, body {
        background-color: #FFFFFF !important;
    }
  `}</style>
  <Helmet>
    <title>HELO - Projects</title>
  </Helmet>

  <section className="fix_overflow lighten">
      <div className="container-fluid p-0">
        <div className="row m-0" id="creators_container">
          <ComponentResize windowWidth={windowWidth} />
        </div>
      </div>
    </section>
  </motion.div>
  );
};

export default ProjectsDeskmovV2;