import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import ProjectsObject from '../../components/submodules/ProjectsObject';

SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);

const ProjectsMovilV2 = (props) => {
  const handleAddParam = props.handleAddParam;
  const hash = props.hash;
  const [isIos] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
  const [videoSwiper, setVideoSwiper] = useState(null);
  const [infoSwiper, setInfoSwiper] = useState(null);
  const [creatorsOriginal] = useState([...ProjectsObject]);
  const [creatorsInfo, setCreatorsInfo] = useState([]);
  const [creatorsVideo, setCreatorsVideo] = useState([]);
  const [maxSlide] = useState(ProjectsObject.length+3);
  const [minSlide] = useState(2);
  const [speedSlider, setSpeedSlider] = useState(300);
  const [longSwipesMsSlider, setLongSwipesMsSlider] = useState(300);
  const [longSwipesRatioSlider, setLongSwipesRatioSlider] = useState(0.5);

  useEffect(() => {
    if (isIos) {
      setSpeedSlider(50);
      setLongSwipesMsSlider(100);
      setLongSwipesRatioSlider(0.1);
      console.log('isIos');
    }
  }, [isIos]);

  const navigate = useNavigate();
  function goToView(route){
    document.querySelector("html").classList.add("exit");
    setTimeout( function() {
      navigate(route);
    }, 500);
  }
  function goToPage(url) {
    if (isIos) {
      document.querySelector("html").classList.add("exit");
      setTimeout(function() {
        window.location.href = url;
      }, 500);
    }else{
      goToView(url);
    }
  }

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
  }, []);

  useEffect(() => {
    let creators_temp = creatorsOriginal;
    if (videoSwiper!==null && creators_temp.length>0) {
      function autoPlayVideo(){
        var activeIndex = videoSwiper.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        if (activeSlideVideo.paused) {
          activeSlideVideo.play();
        }
      }
      videoSwiper.on('transitionStart', function (swipe) {
        var videos = document.querySelectorAll('video');
        Array.prototype.forEach.call(videos, function(video){
          video.pause();
        });
      });
      videoSwiper.on('transitionEnd', function (swipe) {
        var activeIndex = swipe.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        if (activeSlideVideo.paused) {
          activeSlideVideo.play();
        }
        var tag = activeSlideVideo.getAttribute('url');
        tag = tag.slice(1);
        var currentURL = window.location.href;
        currentURL = currentURL.split('#')[0];
        var newURL = `${currentURL}#${tag}`;
        window.history.pushState({ path: newURL }, '', newURL);
      });
      autoPlayVideo();
    }
  }, [videoSwiper, creatorsVideo, creatorsOriginal]);

  function setBestResolution(video) {
    const availableSources = video.querySelectorAll("source");
    let bestSource = null;
    let maxWidth = 0;
    // Encuentra la fuente con la resolución más alta
    availableSources.forEach((source) => {
      const mediaQuery = source.getAttribute("media");
      if (!mediaQuery || window.matchMedia(mediaQuery).matches) {
        const sourceWidth = parseInt(source.getAttribute("data-width"), 10);
        if (sourceWidth > maxWidth) {
          maxWidth = sourceWidth;
          bestSource = source.getAttribute("src");
        }
      }
    });
    if (bestSource) {
      // Cambia la fuente del video a la mejor resolución disponible
      console.log('bestSource==>');
      console.log(bestSource);
      video.src = bestSource;
    }
  }

  useEffect(() => {
    if (videoSwiper!==null) {
      videoSwiper.on('transitionStart', function () {
        window.hover_state='videos';
      });
    }
  }, [videoSwiper]);

  useEffect(() => {
    if (infoSwiper!==null) {
      infoSwiper.on('transitionStart', function () {
        window.hover_state='info';
      });
    }
  }, [infoSwiper]);

  useEffect(() => {
    if (hash!==null && videoSwiper!==null) {
      console.log('hash no es nulo')
      for (const [index, creator] of creatorsOriginal.entries()) {
        console.log(creator.url+'==='+hash);
        if ('#'+creator.url.slice(1) === hash) {
          console.log('index: '+index);
          videoSwiper.slideTo(index, 1, true);
          break;
        }
      }
    }
  }, [hash, creatorsOriginal, videoSwiper]);

  return(
    <React.Fragment>
      <div className={`col-12 col-lg-6 p-0 ${isIos ? 'is_ios' : ''}`} id="videos">
        <div>
          <Swiper
            direction="vertical"
            speed={speedSlider}
            longSwipesMs={longSwipesMsSlider}
            longSwipesRatio={longSwipesRatioSlider}
            slidesPerView={1}
            spaceBetween={0}
            mousewheel={true}
            onSwiper={setVideoSwiper}
            initialSlide={3}
            loop={true}
            centeredSlidesBounds={false}
            loopedSlides={3}
            controller={{ control: infoSwiper }}
            preventInteractionOnTransition={true}
          >
            {creatorsOriginal.map((project, index) => {
              return (
                <SwiperSlide key={index} slidekey={index} project={project.nombre}>
                  <div className="row h-100 m-0">
                    <Link
                      className="col-12"
                      onClick={() => {
                        goToPage(project.url);
                      }}
                    >
                      <video loop muted preload="metadata" id={"vid_"+index} url={project.url} alt={project.nombre} poster={project['poster']} src={project['video360']} playsInline>
                        <source type="video/mp4" src={project['video360']} media="(max-width: 360px)" data-width="540"/>
                        <source type="video/mp4" src={project['video540']} media="(max-width: 540px)" data-width="540"/>
                        <source type="video/mp4" src={project['video720']} media="(min-width: 541px) and (max-width: 1920px)" data-width="720"/>
                        <source type="video/mp4" src={project['video1080']} media="(min-width: 1921px)" data-width="1080"/>
                      </video>
                    </Link>
{/*                    <a
                      className="go_to"
                    >
                    </a>*/}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="overlay_videos">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div className={`col-12 col-lg-6 p-0 ${isIos ? 'is_ios' : ''}`} id="info">
        <div>
          <Swiper
            direction="vertical"
            speed={speedSlider}
            longSwipesMs={longSwipesMsSlider}
            longSwipesRatio={longSwipesRatioSlider}
            slidesPerView={3}
            centeredSlides={true}
            spaceBetween={0}
            mousewheel={true}
            onSwiper={setInfoSwiper}
            initialSlide={3}
            preventClicksPropagation={true}
            loop={true}
            centeredSlidesBounds={false}
            loopedSlides={3}
            controller={{ control: videoSwiper }}
            breakpoints={{ 
              0: {
                slidesPerView: 5,
                spaceBetween: 0
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 0
              }
            }}
          >
            {creatorsOriginal.map((project, index) => {
              return (
                <SwiperSlide key={index} slidekey={index} project={project.nombre}>
                  <div className="row align-items-center justify-content-center h-100">
                    <div className="col-12">
                      <span className="autor">{project.autor}</span>
                      <Link
                        className="title"
                        onClick={() => {
                          goToPage(project.url);
                        }}
                      >
                        {project.nombre}
                      </Link>
                      <span>{project.extracto}</span>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="overlay_info">
          <div></div>
          <div></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectsMovilV2;