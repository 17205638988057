import { useEffect, useState } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import GlobalLayout from "./layouts/Global";

import CreatorsLineal from "./pages/web/CreatorsLineal";
import CreatorsVideoFullScreen from "./pages/web/CreatorsVideoFullScreen";
import CreatorVideoFullScreen from "./pages/web/CreatorVideoFullScreen";
import ProjectVideoFullScreen from "./pages/web/ProjectVideoFullScreen";
import CreatorsVideoFullScreenPreventClick from "./pages/web/CreatorsVideoFullScreenPreventClick";
import Projects from "./pages/web/Projects";
import Christine from "./pages/web/creators/Christine";
import ChristineV2 from "./pages/web/creators/ChristineV2";
import About from "./pages/web/About";
import HeloLabs from "./pages/web/HeloLabs";
import HeloSpark from "./pages/web/HeloSpark";
import HeloSparkV2 from "./pages/web/HeloSparkV2";
import HeloSparkV3 from "./pages/web/HeloSparkV3";
import HeloSparkV4 from "./pages/web/HeloSparkV4";
import HeloSparkV5 from "./pages/web/HeloSparkV5";

import HomeJs from "./pages/web/HomeJs";

import Contact from "./pages/web/Contact";
import ContactV2 from "./pages/web/ContactV2";
import HomeLoop from "./pages/web/HomeLoop";
import CreatorsObject from './components/submodules/CreatorsObject';
import ProjectsObject from './components/submodules/ProjectsObject';
import Creator from "./pages/web/Creator";
import CreatorsDeskmov from "./pages/web/CreatorsDeskmov";
import CreatorsDeskmovV2 from "./pages/web/CreatorsDeskmovV2";
import CreatorsLineaV2 from "./pages/web/CreatorsLineaV2";
import ProjectsDeskmovV2 from "./pages/web/ProjectsDeskmovV2";

import ProjectsDeskmov from "./pages/web/ProjectsDeskmov";
import SparkObject from './components/submodules/SparkObject';

const RefreshingRouteWrapper = () => {
  const location = useLocation();
  const [randomString, setRandomString] = useState(0);

  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  useEffect(() => {
    console.log('Location: '+location.pathname);
    if (location.pathname==='/helo-spark-v3') {
      setRandomString(generateRandomString(10));
    }
  }, [location]);

  const sparkRouters = [];
  Object.values(SparkObject).forEach((proyecto, index) => {
    sparkRouters.push(
      <Route
        path={proyecto.url}
        element={<HeloSparkV3 propProject={proyecto} key={proyecto.url} />}
        key={index+'-project-spark'}
      />
    );
  });

  const creatorsRouters = [];
  Object.values(CreatorsObject).forEach((creador, index) => {
    creatorsRouters.push(
      <Route
        path={creador.url}
        element={<CreatorVideoFullScreen propCreator={creador} key={creador.url} />}
        key={index+'-creator'}
      />
    );
  });
  
  const projectsRouters = [];
  Object.values(ProjectsObject).forEach((proyecto, index) => {
    projectsRouters.push(
      <Route
        path={proyecto.url}
        element={<ProjectVideoFullScreen propProject={proyecto} key={proyecto.url} />}
        key={index+'-project'}
      />
    );
  });

  return (
    <Routes>
      <Route path="/" element={<GlobalLayout />}>
        <Route index element={<HomeJs />} />
        <Route path="home" element={<HomeJs />} />
        <Route path="contact-us" element={<ContactV2 />} />
        <Route path="creators" element={<CreatorsDeskmovV2 />} />
        <Route path="creators-splide" element={<CreatorsLineaV2 />} />
        <Route path="projects" element={<ProjectsDeskmovV2 />} />
        <Route path="about-us" element={<About />} />
        {/*<Route path="helo-spark" element={<HeloSpark />} />*/}
        <Route path="helo-spark" element={<HeloSparkV3 propProject={null} randomString={randomString} />} />
        <Route path="helo-spark-v3" element={<HeloSparkV3 propProject={null} randomString={randomString} />} />
        {sparkRouters}
        {creatorsRouters}
        {projectsRouters}
      </Route>
    </Routes>
  );
};

export default RefreshingRouteWrapper;