import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import ProjectsObject from '../../components/submodules/ProjectsObject';
import { gsap } from "gsap";

SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);

const ProjectsDesktop = () => {
  const [videoSwiper, setVideoSwiper] = useState(null);
  const [infoSwiper, setInfoSwiper] = useState(null);
  const [creatorsOriginal] = useState([...ProjectsObject]);
  const [creatorsInfo, setCreatorsInfo] = useState([]);
  const [creatorsVideo, setCreatorsVideo] = useState([]);
  const [maxSlide] = useState(ProjectsObject.length+2);
  const [minSlide] = useState(1);

  const navigate = useNavigate();
  function goToView(route){
    document.querySelector("html").classList.add("exit");
    setTimeout( function() {
      navigate(route);
    }, 500);
  }

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
  }, []);

  useEffect(() => {
    if (creatorsInfo.length===0 && creatorsVideo.length===0) {
      var firstItems = [
        creatorsOriginal[0],
        creatorsOriginal[1]
      ]
      var ultimoItem = creatorsOriginal[creatorsOriginal.length - 1];
      var penultimoItem = creatorsOriginal[creatorsOriginal.length - 2];
      setCreatorsInfo([ultimoItem, penultimoItem, ...creatorsOriginal, ...firstItems]);
      setCreatorsVideo([ultimoItem, penultimoItem, ...creatorsOriginal, ...firstItems].reverse());
    }
  }, [ProjectsObject]);

  useEffect(() => {
    if (videoSwiper!==null && creatorsVideo.length>0) {
      function autoPlayVideo(){
        var activeIndex = videoSwiper.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        activeSlideVideo.play();
      }
      videoSwiper.on('transitionStart', function (swipe) {
        var videos = document.querySelectorAll('video');
        Array.prototype.forEach.call(videos, function(video){
          video.pause();
        });
      });
      videoSwiper.on('transitionEnd', function (swipe) {
        var activeIndex = swipe.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        activeSlideVideo.play();
      });
      autoPlayVideo();
    }
  }, [videoSwiper, creatorsVideo]);

  useEffect(() => {
    if (infoSwiper!==null && maxSlide!==null && minSlide!==null) {
      infoSwiper.on('transitionEnd', function (swipe) {
        var activeIndex = swipe.activeIndex;
        console.log('activeIndex==>'+activeIndex);
        console.log('minSlide==>'+minSlide);
        console.log('maxSlide==>'+maxSlide);
        if (activeIndex>=maxSlide) {
          infoSwiper.slideTo(minSlide+1, 1, true);
        }
        if (activeIndex<=minSlide) {
          infoSwiper.slideTo(maxSlide-2, 1, true);
        }
      });
      infoSwiper.slideTo(2, 1, true);
    }
  }, [infoSwiper, maxSlide, minSlide]);

  function setBestResolution(video) {
    const availableSources = video.querySelectorAll("source");
    let bestSource = null;
    let maxWidth = 0;
    // Encuentra la fuente con la resolución más alta
    availableSources.forEach((source) => {
      const mediaQuery = source.getAttribute("media");
      if (!mediaQuery || window.matchMedia(mediaQuery).matches) {
        const sourceWidth = parseInt(source.getAttribute("data-width"), 10);
        if (sourceWidth > maxWidth) {
          maxWidth = sourceWidth;
          bestSource = source.getAttribute("src");
        }
      }
    });
    if (bestSource) {
      // Cambia la fuente del video a la mejor resolución disponible
      console.log('bestSource==>');
      console.log(bestSource);
      video.src = bestSource;
    }
  }

  function toggleFullScreen(element) {
    if (element.requestFullscreen) {
      if (!document.fullscreenElement) {
        setBestResolution(element);
        element.requestFullscreen();
      }
    } else if (element.mozRequestFullScreen) {
      if (!document.mozFullScreenElement) {
        setBestResolution(element);
        element.mozRequestFullScreen();
      }
    } else if (element.webkitRequestFullscreen) {
      if (!document.webkitFullscreenElement) {
        setBestResolution(element);
        element.webkitRequestFullscreen();
      }
    } else if (!element.msRequestFullscreen) {
      if (document.msFullscreenElement) {
        setBestResolution(element);
        element.msRequestFullscreen();
      }
    }
  }

  useEffect(() => {

    /* <---- CURSOR BALL*/
    var cursor_play = document.querySelector('#ball');
    var mouse = { x: 0, y: 0 }; // Cursor position
    var pos = { x: 0, y: 0 }; // Cursor position
    var ratio = 0.15; // delay follow cursor
    var active = false;
    document.addEventListener("mousemove", mouseMove);
    function mouseMove(e) {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    }
    gsap.ticker.add(updatePosition);
    function updatePosition() {
      if (!active) {
        pos.x += (mouse.x - pos.x) * ratio;
        pos.y += (mouse.y - pos.y) * ratio;
        gsap.set(cursor_play, { x: pos.x, y: pos.y });
      }
    }
    gsap.set(cursor_play, {
      xPercent: -50, 
      yPercent: -50, 
      width: "0px",
      height: "0px",
      backgroundColor: "transparent",
      opacity: 0
    });
    /*CURSOR BALL -----> */

    if (creatorsVideo.length>0) {
      var videos = document.querySelectorAll('video');
      videos.forEach(function(video){
        video.addEventListener("click", function(event){
          event.preventDefault();
          toggleFullScreen(video);
        });
        video.addEventListener('fullscreenchange', function() {
          var sources = video.getElementsByTagName('source');
          if (document.fullscreenElement) {
            video.currentTime = 1;
            video.muted = false;
            video.play();
            video.style.cursor = "default";
          } else {
            video.muted = true;
            video.style.cursor = "none";
            let parentSlide = video.closest('.swiper-slide');
            if (parentSlide) {
              let slidekey = parentSlide.getAttribute('slidekey');
              setTimeout(() => {
                videoSwiper.slideTo(slidekey, 1, true);
              }, 100);
            }
          }
        });
        /* <---- CURSOR BALL*/
        video.addEventListener("mouseenter", function() {
          video.style.cursor = "none"; // Ocultar el puntero por defecto
          gsap.to(cursor_play, {
            duration: 0.3,
            opacity: .75,
            backgroundColor: "#000000",
            width: "75px",
            height: "75px",
          });
        });
        video.addEventListener("mouseleave", function() {
          video.style.cursor = "default"; // Restablecer el puntero por defecto
          gsap.to(cursor_play, {
            duration: 0.3,
            opacity: 0,
            backgroundColor: "transparent",
            width: "0px",
            height: "0px",
          });
        });
        video.addEventListener("mousedown", function() {
          gsap.to(cursor_play, {
            duration: 0.3,
            width: "100px",
            height: "100px",
          });
        });
        video.addEventListener("mouseup", function() {
          gsap.to(cursor_play, {
            duration: 0.3,
            width: "75px",
            height: "75px",
          });
        });
        /*CURSOR BALL -----> */
      });
    }
  }, [creatorsVideo]);

  return(
    <React.Fragment>
      <div className="col-12 col-lg-6 p-0" id="videos">
        <div>
          <Swiper
            direction="vertical"
            speed={300}
            slidesPerView={3}
            centeredSlides={true}
            spaceBetween={0}
            mousewheel={true}
            loop={false}
            onSwiper={setVideoSwiper}
            centeredSlidesBounds={true}
            preventClicksPropagation={true}
            // controller={{ control: infoSwiper}}
            controller={{ control: infoSwiper, inverse: true }}
          >
            {creatorsVideo.map((project, index) => {
              return (
                <SwiperSlide key={index} slidekey={index} project={project.nombre}>
                  <div className="row h-100 m-0">
                    <div className="col-12">
                      <video loop muted preload="metadata" id={"vid_"+index} alt={project.nombre} poster={project['poster']} src={project['video360']} playsInline>
                        <source type="video/mp4" src={project['video360']} media="(max-width: 360px)" data-width="540"/>
                        <source type="video/mp4" src={project['video540']} media="(max-width: 540px)" data-width="540"/>
                        <source type="video/mp4" src={project['video720']} media="(min-width: 541px) and (max-width: 1920px)" data-width="720"/>
                        <source type="video/mp4" src={project['video1080']} media="(min-width: 1921px)" data-width="1080"/>
                      </video>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="overlay_videos">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 p-0" id="info">
        <div>
          <Swiper
            direction="vertical"
            speed={300}
            slidesPerView={3}
            centeredSlides={true}
            spaceBetween={0}
            mousewheel={true}
            loop={false}
            onSwiper={setInfoSwiper}
            centeredSlidesBounds={true}
            preventClicksPropagation={true}
            // controller={{ control: videoSwiper }}
            controller={{ control: videoSwiper, inverse: true }}
          >
            {creatorsInfo.map((project, index) => {
              return (
                <SwiperSlide key={index} slidekey={index} project={project.nombre}>
                  <div className="row align-items-center justify-content-center justify-content-md-start h-100">
                    <div className="col-12">
                      <span className="autor">{project.autor}</span>
                      <a
                        className="title"
                      >
                        {project.nombre}
                      </a>
                      <span>{project.extracto}</span>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="overlay_info">
          <div></div>
          <div></div>
        </div>
      </div>
      <div id="ball"></div>
    </React.Fragment>
  );
};

export default ProjectsDesktop;