import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from '@studio-freight/lenis';
import mouse from "../../assets/img/mouse.svg";
import scroll from "../../assets/img/scroll.svg";
import Menu from '../../components/submodules/Menu';
import { motion } from "framer-motion/dist/framer-motion";

SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);
gsap.registerPlugin(ScrollTrigger);


const About = () => {

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
    var TL_body_bg = gsap.timeline({
      defaults: {
        duration: 1,
        ease: "linear"
      },
      paused: true,
      onComplete: () => {
      },
      onStart: () => {
      }
    });
    TL_body_bg
    .fromTo('body', { backgroundColor: '#6049F8'}, { backgroundColor: '#008351'})
    .fromTo('div.overlay_fixed>div.top_', 
      { 
        background: '-moz-linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      {
        background: '-moz-linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      '<'
    )
    .fromTo('div.overlay_fixed>div.bottom_', 
      { 
        background: '-moz-linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      {
        background: '-moz-linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      '<'
    )

    gsap.from('div.row-about', {
      duration: .1,
      scrollTrigger: {
        scroller: "#about_container",
        trigger: "div.row-about",
        start: "top top",
        end: "bottom top-100",
        toggleActions: "play pause reverse reset",
        onEnter: () => {
          TL_body_bg.play();
        },
        onLeave: () => {
          TL_body_bg.reverse();
        },
        onEnterBack: () => {
          TL_body_bg.play();
        },
        onLeaveBack: () => {
          TL_body_bg.reverse();
        },
        scrub: 1
      }
    })

    var main = document.querySelector('main');
    var wrapper = document.querySelector('section#about_container');
    var content = document.querySelector('section#about_container>div.container-xl');
    if (wrapper!==null) {
      var lenis = new Lenis({
        wrapper: wrapper,
        content: content,
        wheelEventsTarget: main,
        lerp: 1,
        duration: 2.5,
        wheelMultiplier: .5,
        smoothTouch: true
      })
      gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
      })

      /*<----Scroll wheel on body*/
      var triggerElement = document.querySelector('body');
      var targetElement = document.getElementById('about_container');
      if (navigator.userAgent.indexOf('Firefox') !== -1) {
        triggerElement.addEventListener('wheel', (event) => {
          var wheelEvent = new WheelEvent('wheel', {
            deltaX: 0,
            deltaY: event.deltaY,
            deltaZ: 0,
            deltaMode: WheelEvent.DOM_DELTA_PIXEL,
          });
          targetElement.dispatchEvent(wheelEvent);
        });
      } else {
        triggerElement.addEventListener('mousewheel', (event) => {
          var wheelEvent = new WheelEvent('wheel', event);
          targetElement.dispatchEvent(wheelEvent);
        });
      }

    }
  }, []);

  return(
    <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
    <Menu propType={'lighten'}/>
    <style>{`
      html>body{
        background-color: #6049F8;
      }
    `}</style>
    <Helmet>
      <title>HELO - About Us</title>
      <meta name='description' content='HELO is a creative production company. We collaborate with our partners to create moving experiences.' />
    </Helmet>
    <main data-barba="container" data-barba-namespace="about">
      <div className="overlay_fixed">
        <div className="top_"></div>
        <div className="bottom_"></div>
      </div>
      <section id="about_container">
        <div className="container-xl">
          <div className="row">
            <div className="col-12 col-md-6 py-0 px-0 px-md-5 text-end title">
              <span></span>
            </div>
            <div className="col-12 col-md-6 p-0 info">
              <div className="fullHeight">
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="lined">HELO is a creative production company. We collaborate with our partners to create moving experiences™ and to craft content that inspires.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-about">
            <div className="col-12 col-md-6 py-0 px-0 px-md-5 text-end title">
              <div className="row justify-content-end">
                <div className="col-auto text-start">
                  <span>ABOUT US</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-0 info">
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="split">We are HELO (pronounced HēLō), a pioneering creative production studio on a mission to steer brands and culture toward a sustainable, inclusive, and prosperous future. For over a decade, our collaborations with global brands, agencies, and visionaries have yielded multi-platform activations and content that not only resonate deeply with audiences but also actively champion their causes.</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="split">Through the strategic integration of storytelling, technology, and design, we craft bold and meaningful ‘Moving Experiences’™. Our work, celebrated for its innovative approach, is dedicated to sparking significant positive change by addressing the critical societal, environmental, and economic challenges of our time.</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="split">As specialists in creating work that truly matters, we deploy our diverse roster of talent to embark on projects designed to leave a lasting, positive legacy. Our growing network of clients and professionals shares our commitment to transformative change.</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="split">We believe in the power of creativity as a force for good. We prioritize genuine impact over fleeting accolades, and we are committed to a future defined by integrity, innovation, empathy, and unmatched excellence in craft.</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <p className="split">HELO. We make impact work.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </main>
  </motion.div>
  );
};

export default About;