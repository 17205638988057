import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import { motion } from "framer-motion/dist/framer-motion";
import Menu from '../../components/submodules/Menu';
import CreatorsMovilV2 from './CreatorsMovilV2';
import CreatorsDesktopV2 from './CreatorsDesktopV2';
SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);

const CreatorsDeskmovV2 = () => {

  const [hash, setHash] = useState(null);
  const [initialSlide, setInitialSlide] = useState(7);

  const handleAddParam = (param) => {
    window.location.hash = param;
  };

  useEffect(() => {
    setHash(window.location.hash);
    const handlePageShow = (event) => {
      if (event.persisted) {
        document.querySelector("html").classList.remove("exit");
      }
    };
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function isAnyVideoInFullscreen() {
    const videoElements = document.getElementsByTagName('video');
    console.log('retry isAnyVideoInFullscreen 2')
    for (let i = 0; i < videoElements.length; i++) {
      if (document.fullscreenElement === videoElements[i] ||
          document.mozFullScreenElement === videoElements[i] ||
          document.webkitFullscreenElement === videoElements[i] ||
          document.msFullscreenElement === videoElements[i]) {
        return true;
      }
    }  
    return false;
  }

  useEffect(() => {
    const handleResize = () => {
      var isVideoFullscreen = isAnyVideoInFullscreen();
      if (!isVideoFullscreen) {
        setWindowWidth(window.innerWidth);
        console.log('handleResize');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
  }, []);

  const ComponentResize = (props) => {
    if (props.windowWidth < 992) {
      return <CreatorsMovilV2 handleAddParam={handleAddParam} hash={hash}/>;
    }else{
      return <CreatorsDesktopV2 handleAddParam={handleAddParam} hash={hash}/>;
    }
  }


  return(
  <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
  <Menu propType={'lighten'} propActive={'creators'} />
  <style>{`
    .menu_dev {
        z-index: 200;
    }
    .html, body {
        background-color: #000000 !important;
    }
  `}</style>
  <Helmet>
    <title>HELO - Creators</title>
  </Helmet>

    <section className="fix_overflow darked" /*key={reloadKey} id={reloadKey}*/>
      <div className="container-fluid p-0">
        <div className="row m-0" id="creators_container">
          <ComponentResize windowWidth={windowWidth} />
        </div>
      </div>
    </section>
    <div className="newContainerVideo">
      <div className="close">
        <div></div>
      </div>
    </div>
  </motion.div>
  );
};

export default CreatorsDeskmovV2;