import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion/dist/framer-motion";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Link from '@mui/material/Link';
import { gsap } from "gsap";

import Menu from '../../components/submodules/Menu';
import CreatorsObject from '../../components/submodules/CreatorsObject';

const CreatorsLineaV2 = () => {

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
  }, []);

  const [hash, setHash] = useState(null);
  const [initialSlide, setInitialSlide] = useState(7);

  const navigate = useNavigate();
  function goToView(route, checkSize){
    if (checkSize) {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 992) {
        document.querySelector("html").classList.add("exit");
        setTimeout(() => {
          navigate(route);
        }, 500);
      }
    }else{
      document.querySelector("html").classList.add("exit");
      setTimeout( function() {
        navigate(route);
      }, 500);
    }
  }

  const handleAddParam = (param) => {
    window.location.hash = param;
  };

  useEffect(() => {
    setHash(window.location.hash);
    const handlePageShow = (event) => {
      if (event.persisted) {
        document.querySelector("html").classList.remove("exit");
      }
    };
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function setBestResolution(video) {
    const availableSources = video.querySelectorAll("source");
    let bestSource = null;
    let maxWidth = 0;
    // Encuentra la fuente con la resolución más alta
    availableSources.forEach((source) => {
      const mediaQuery = source.getAttribute("media");
      if (!mediaQuery || window.matchMedia(mediaQuery).matches) {
        const sourceWidth = parseInt(source.getAttribute("data-width"), 10);
        if (sourceWidth > maxWidth) {
          maxWidth = sourceWidth;
          bestSource = source.getAttribute("src");
        }
      }
    });
    if (bestSource) {
      // Cambia la fuente del video a la mejor resolución disponible
      console.log('bestSource==>');
      console.log(bestSource);
      video.src = bestSource;
    }
  }

  function toggleFullScreen(element) {
    if (element.requestFullscreen) {
      if (!document.fullscreenElement) {
        setBestResolution(element);
        element.requestFullscreen();
      }
    } else if (element.mozRequestFullScreen) {
      if (!document.mozFullScreenElement) {
        setBestResolution(element);
        element.mozRequestFullScreen();
      }
    } else if (element.webkitRequestFullscreen) {
      if (!document.webkitFullscreenElement) {
        setBestResolution(element);
        element.webkitRequestFullscreen();
      }
    } else if (!element.msRequestFullscreen) {
      if (document.msFullscreenElement) {
        setBestResolution(element);
        element.msRequestFullscreen();
      }
    }
  }

  function addListeners(){

    /* <---- CURSOR BALL*/
    var cursor_play = document.querySelector('#ball');
    var mouse = { x: 0, y: 0 }; // Cursor position
    var pos = { x: 0, y: 0 }; // Cursor position
    var ratio = 0.15; // delay follow cursor
    var active = false;
    document.addEventListener("mousemove", mouseMove);
    function mouseMove(e) {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    }
    gsap.ticker.add(updatePosition);
    function updatePosition() {
      if (!active) {
        pos.x += (mouse.x - pos.x) * ratio;
        pos.y += (mouse.y - pos.y) * ratio;
        gsap.set(cursor_play, { x: pos.x, y: pos.y });
      }
    }
    gsap.set(cursor_play, {
      xPercent: -50, 
      yPercent: -50, 
      width: "0px",
      height: "0px",
      backgroundColor: "transparent",
      opacity: 0
    });
    /*CURSOR BALL -----> */

    if (CreatorsObject.length>0) {
      var videos = document.querySelectorAll('#creators_projects video');
      videos.forEach(function(video){
        video.addEventListener("click", function(event){
          event.preventDefault();
          toggleFullScreen(video);
        });
        video.addEventListener('fullscreenchange', function() {
          var sources = video.getElementsByTagName('source');
          var activeIndexVideo = video.getAttribute('indexSlide');
          if (document.fullscreenElement) {
            video.currentTime = 1;
            //video.muted = false;
            video.play();
            video.style.cursor = "default";
          } else {
            video.src = sources[0].src;
            //video.muted = true;
            video.style.cursor = "none";
            console.log('Slide to: '+activeIndexVideo);
          }
        });
        /* <---- CURSOR BALL*/
        video.addEventListener("mouseenter", function() {
          if (!document.fullscreenElement){
            video.style.cursor = "none"; // Ocultar el puntero por defecto
            gsap.to(cursor_play, {
              duration: 0.3,
              opacity: .75,
              backgroundColor: "#000000",
              width: "75px",
              height: "75px",
            });
          }
        });
        video.addEventListener("mouseleave", function() {
          if (!document.fullscreenElement){
            video.style.cursor = "default"; // Restablecer el puntero por defecto
            gsap.to(cursor_play, {
              duration: 0.3,
              opacity: 0,
              backgroundColor: "transparent",
              width: "0px",
              height: "0px",
            });
          }
        });
        video.addEventListener("mousedown", function() {
          if (!document.fullscreenElement){
            gsap.to(cursor_play, {
              duration: 0.3,
              width: "100px",
              height: "100px",
            });
          }
        });
        video.addEventListener("mouseup", function() {
          if (!document.fullscreenElement){
            gsap.to(cursor_play, {
              duration: 0.3,
              width: "75px",
              height: "75px",
            });
          }
        });
        /*CURSOR BALL -----> */
      });
    }
  }

  return(
  <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
  <Menu propType={'lighten'} propActive={'creators'} />
  <style>{`
    .menu_dev {
        z-index: 200;
    }
    .html, body {
        background-color: #000000 !important;
    }
  `}</style>
  <Helmet>
    <title>HELO - Creators</title>
    <meta name='description' content='' />
  </Helmet>
    <section className="fix_overflow darked">
      <div className="container-fluid p-0">
        <div className="row m-0" id="creators_projects_container">
          <Splide
            aria-label="Creators videos"
            options={ {
              rewind: false,//Determina si rebobinar el carrusel o no.
              //rewindSpeed: 1000,//La velocidad de transición al rebobinar en milisegundos. El valor de velocidad se utiliza por defecto.}
              rewindByDrag: false,
              type : 'loop',
              height: "100svh",
              fixedHeight: "50svh",
              autoplay : false,              
              perPage: 2,
              perMove: 1,
              padding: '25svh',
              arrows: false,
              pagination: false,
              direction   : 'ttb',
              wheel       : true,//Permite la navegación con la rueda del ratón.
              releaseWheel: false,//Determina si se libera el evento de la rueda cuando el carrusel llega a la primera o a la última diapositiva para que el usuario pueda desplazarse por la página continuamente.
              wheelMinThreshold: 0,//El umbral para cortar el pequeño delta producido por la voluta de inercia
              //wheelSleep: 500,//La duración del sueño en milisegundos hasta aceptar la entrada de la siguiente rueda
              breakpoints: {
                992: {
                  fixedHeight: "70svh",
                  perPage: 1,
                  padding: '15svh',
                },
                768: {
                  fixedHeight: "60svh",
                  perPage: 1,
                  padding: '20svh',
                }
              }
            } }
            onMoved={ () => {
              setTimeout(() => {
                const videoActive = document.querySelector('.splide__slide.is-active video');
                videoActive.play();

                const splideSlides = document.querySelectorAll('.splide__slide');
                splideSlides.forEach(slide => {
                  const video = slide.querySelector('video');
                    if (!slide.classList.contains('is-active') && !video.paused) {
                      video.pause();
                    }
                });

              }, 500);
            }}
            onReady={ () => { 
              addListeners();
              const videoActive = document.querySelector('.splide__slide.is-active video');
              const handleVideoCanPlay = () => {
                videoActive.play();
              };
              videoActive.addEventListener('canplay', handleVideoCanPlay);
              return () => {
                videoActive.removeEventListener('canplay', handleVideoCanPlay);
              };
            } }
            id="creators_projects"
          >
              
            {CreatorsObject.map((creator, index) => {
              return (
                <SplideSlide creator={creator.nombre} key={'slide___'+index}>
                  <div className="row h-100 m-0">
                    <div className="col-12 col-md-6 p-0 h-100">
                      <Link
                        className="video_container_"
                        onClick={() => {
                          goToView(creator.url, true);
                        }}
                      >
                        <video muted loop controls={false} id={"vid_"+index} alt={creator.nombre} url={creator.url} poster={creator.videos[0]['poster']} playsInline>
                          <source type="video/mp4" src={creator.videos[0]['540']} media="(max-width: 540px)" data-width="540"/>
                          <source type="video/mp4" src={creator.videos[0]['1080']} media="(min-width: 1260px)" data-width="1080"/>
                        </video>
                      </Link>
                    </div>
                    <div className="col-12 col-md-6 h-100">
                      <div className="row align-items-center justify-content-start h-100">
                        <div className="col-12">
                          <Link
                            className="title"
                            onClick={() => {
                              goToView(creator.url, false);
                            }}
                          >
                            {creator.nombre}
                          </Link>
                          <span>{creator.extracto}</span>
                          <Link
                            className="link"
                            component={RouterLink}
                            to={creator.url}
                          >
                            + Know More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              );
            })}

          </Splide>
          <div className="overlay_info">
            <div></div>
            <div></div>
          </div>
          <div id="ball"></div>
        </div>
      </div>

    </section>
  </motion.div>
  );
};

export default CreatorsLineaV2;