import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import { gsap } from "gsap";
import Lenis from '@studio-freight/lenis';
import Menu from '../../components/submodules/Menu';
import { motion } from "framer-motion/dist/framer-motion";
import arrow_back from "../../assets/img/arrow_back.svg";
import CreatorsObject from '../../components/submodules/CreatorsObject';

SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);

const CreatorVideoFullScreen = (props) => {

  const [isIos] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
  const currentCreator = props.propCreator;
  const [currentVideos, setCurrentVideos] = useState([]);

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
  }, []);

  useEffect(() => {
    if (currentCreator.videos.length===1) {
      console.log('CONDICION 01');
      setCurrentVideos([...currentCreator.videos]);
    }else if (currentCreator.videos.length>1 && currentCreator.videos.length<=3) {
      console.log('CONDICION 02');
      setCurrentVideos([...currentCreator.videos, ...currentCreator.videos, ...currentCreator.videos, ...currentCreator.videos]);
    }else if (currentCreator.videos.length>3 && currentCreator.videos.length<=5) {
      console.log('CONDICION 03');
      setCurrentVideos([...currentCreator.videos, ...currentCreator.videos]);
    }else{
      console.log('CONDICION 04');
      setCurrentVideos([...currentCreator.videos]);
    }
  }, [currentCreator]);

  const [videoSwiper, setVideoSwiper] = useState(null);
  
  useEffect(() => {
    if (videoSwiper!==null) {

      const otherElementRef = document.querySelector('#videos');

      // Manejar el evento de scroll en el otro elemento
      const handleScrollTest = () => {
        console.log('here!!!');
        // Obtener el desplazamiento actual del otro elemento
        const scrollTop = otherElementRef.scrollTop;
        // Calcular la posición del slide en función del desplazamiento
        const slideIndex = Math.floor(scrollTop / videoSwiper.height);
        // Cambiar el slide activo en el Swiper
        videoSwiper.slideTo(slideIndex);
      };

      otherElementRef.addEventListener('scroll', handleScrollTest);
      videoSwiper.on('transitionStart', function (swipe) {
        var videos = document.querySelectorAll('video');
        Array.prototype.forEach.call(videos, function(video){
          if (!video.paused) {
            video.pause();
          }
        });
      });
      videoSwiper.on('transitionEnd', function (swipe) {
        var activeIndex = swipe.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        activeSlideVideo.play();
      });
      autoPlayVideo();
    }
  }, [videoSwiper]);

  function toggleFullScreen(element) {

    if (isIos) {
      if (element.webkitEnterFullscreen) {
        if (document.webkitFullscreenElement) {
          document.webkitExitFullscreen();
        } else {
          element.webkitEnterFullscreen();
        }
      } else if (element.mozRequestFullScreen) {
        if (document.mozFullScreenElement) {
          document.mozCancelFullScreen();
        } else {
          element.mozRequestFullScreen();
        }
      } else if (element.requestFullscreen) {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          element.requestFullscreen();
        }
      }
    }else{
      if (element.requestFullscreen) {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          element.requestFullscreen();
        }
      } else if (element.mozRequestFullScreen) {
        if (document.mozFullScreenElement) {
          document.mozCancelFullScreen();
        } else {
          element.mozRequestFullScreen();
        }
      } else if (element.webkitRequestFullscreen) {
        if (document.webkitFullscreenElement) {
          document.webkitExitFullscreen();
        } else {
          element.webkitRequestFullscreen();
        }
      } else if (element.msRequestFullscreen) {
        if (document.msFullscreenElement) {
          document.msExitFullscreen();
        } else {
          element.msRequestFullscreen();
        }
      }
    }
  }

  useEffect(() => {
      
      /* <---- CURSOR BALL*/
      var cursor_play = document.querySelector('#ball');
      var mouse = { x: 0, y: 0 }; // Cursor position
      var pos = { x: 0, y: 0 }; // Cursor position
      var ratio = 0.15; // delay follow cursor
      var active = false;
      document.addEventListener("mousemove", mouseMove);
      function mouseMove(e) {
        mouse.x = e.clientX;
        mouse.y = e.clientY;
      }
      gsap.ticker.add(updatePosition);
      function updatePosition() {
        if (!active) {
          pos.x += (mouse.x - pos.x) * ratio;
          pos.y += (mouse.y - pos.y) * ratio;
          gsap.set(cursor_play, { x: pos.x, y: pos.y });
        }
      }
      gsap.set(cursor_play, {
        xPercent: -50, 
        yPercent: -50, 
        width: "0px",
        height: "0px",
        backgroundColor: "transparent",
        opacity: 0
      });
      /*CURSOR BALL -----> */

      var videos = document.querySelectorAll('video');
      videos.forEach(function(video){
        video.addEventListener("click", function(event){
          event.preventDefault();
          toggleFullScreen(video);
        });
        video.addEventListener('fullscreenchange', function() {
          var sources = video.getElementsByTagName('source');
          if (document.fullscreenElement) {
            // El video está en modo de pantalla completa
            console.log('El video está en pantalla completa');
            var maxWidth = window.innerWidth;
            var selectedSource = null;
            // Recorrer las fuentes de video y seleccionar la de mayor calidad compatible
            for (var i = 0; i < sources.length; i++) {
              var source = sources[i];
              var mediaQuery = source.getAttribute('media');
              if (window.matchMedia(mediaQuery).matches) {
                selectedSource = source;
              }
            }
            // Cambiar la fuente del video a la de mayor calidad
            if (selectedSource) {
              video.src = selectedSource.src;
            }
            video.currentTime = 1;
            video.muted = false;
            if (video.paused) {
              video.play();
            }
            video.style.cursor = "default";
          } else {
            // El video ha salido del modo de pantalla completa
            console.log('El video ha salido de pantalla completa');
            video.src = sources[0].src;
            video.muted = true;
            if (video.paused) {
              video.play();
            }
            video.style.cursor = "none";
          }
        });
        /* <---- CURSOR BALL*/
        video.addEventListener("mouseenter", function() {
          video.style.cursor = "none"; // Ocultar el puntero por defecto
          gsap.to(cursor_play, {
            duration: 0.3,
            opacity: .75,
            backgroundColor: "#000000",
            width: "75px",
            height: "75px",
          });
        });
        video.addEventListener("mouseleave", function() {
          video.style.cursor = "default"; // Restablecer el puntero por defecto
          gsap.to(cursor_play, {
            duration: 0.3,
            opacity: 0,
            backgroundColor: "transparent",
            width: "0px",
            height: "0px",
          });
        });
        video.addEventListener("mousedown", function() {
          gsap.to(cursor_play, {
            duration: 0.3,
            width: "100px",
            height: "100px",
          });
        });
        video.addEventListener("mouseup", function() {
          gsap.to(cursor_play, {
            duration: 0.3,
            width: "75px",
            height: "75px",
          });
        });
        /*CURSOR BALL -----> */
      });

      if (currentVideos.length===1) {
        var video = document.getElementById('one_video_dev');
        video.addEventListener("click", function(event){
          event.preventDefault();
          toggleFullScreen(video);
        });
        video.addEventListener('fullscreenchange', function() {
          var sources = video.getElementsByTagName('source');
          if (document.fullscreenElement) {
            // El video está en modo de pantalla completa
            console.log('El video está en pantalla completa');
            var maxWidth = window.innerWidth;
            var selectedSource = null;
            // Recorrer las fuentes de video y seleccionar la de mayor calidad compatible
            for (var i = 0; i < sources.length; i++) {
              var source = sources[i];
              var mediaQuery = source.getAttribute('media');
              if (window.matchMedia(mediaQuery).matches) {
                selectedSource = source;
              }
            }
            // Cambiar la fuente del video a la de mayor calidad
            if (selectedSource) {
              video.src = selectedSource.src;
            }
            video.currentTime = 1;
            video.muted = false;
          } else {
            // El video ha salido del modo de pantalla completa
            console.log('El video ha salido de pantalla completa');
            video.src = sources[0].src;
            video.muted = true;
          }
        });
        /* <---- CURSOR BALL*/
        video.addEventListener("mouseenter", function() {
          video.style.cursor = "none"; // Ocultar el puntero por defecto
          gsap.to(cursor_play, {
            duration: 0.3,
            opacity: .75,
            backgroundColor: "#000000",
            width: "75px",
            height: "75px",
          });
        });
        video.addEventListener("mouseleave", function() {
          video.style.cursor = "default"; // Restablecer el puntero por defecto
          gsap.to(cursor_play, {
            duration: 0.3,
            opacity: 0,
            backgroundColor: "transparent",
            width: "0px",
            height: "0px",
          });
        });
        video.addEventListener("mousedown", function() {
          gsap.to(cursor_play, {
            duration: 0.3,
            width: "100px",
            height: "100px",
          });
        });
        video.addEventListener("mouseup", function() {
          gsap.to(cursor_play, {
            duration: 0.3,
            width: "75px",
            height: "75px",
          });
        });
        /*CURSOR BALL -----> */
      }
  }, [currentVideos]);

  useEffect(() => {
    var wrapper = document.querySelector('div#info_detail>div');
    var content = document.querySelector('div#info_detail>div>div');
    if (wrapper!==null) {
      var lenis = new Lenis({
        wrapper: wrapper,
        content: content,
        //wheelEventsTarget: window,
        lerp: 1,
        duration: 2,
        wheelMultiplier: .75
      })
      gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
      })
    }
  }, []);

  function autoPlayVideo(){
    var activeIndex = videoSwiper.activeIndex;
    var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
    var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
    activeSlideVideo.play();
  }

  const navigate = useNavigate();
  function goToView(route){
    document.querySelector("html").classList.add("exit");
    setTimeout( function() {
      navigate(route);
    }, 500);
  }
  function goToPage(url) {
    if (isIos) {
      document.querySelector("html").classList.add("exit");
      setTimeout(function() {
        window.location.href = url;
      }, 500);
    }else{
      goToView(url);
    }
  }
  
  return(
  <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
  <Menu propType={'lighten'}/>
  <style>{`
    html body {
        background-color: #000000 !important;
    }
    @media (max-width: 991px) {
      html body {
          overflow-y: auto;
      }
      div.overlay_back{
        position: relative;
      }
    }
  `}</style>
  <Helmet>
    <title>HELO - {currentCreator.nombre}</title>
    <meta name='description' content={currentCreator.extracto} />
  </Helmet>
    <div id="ball"></div>
    <section className="fix_overflow darked" id="creator_detail">
      <div className="container-fluid p-0">
        <div className="row m-0">
        {currentVideos.length>1?
          <div className="col-12 col-md-6 p-0 creator_det" id="videos">
            <div>
              <Swiper
                direction="vertical"
                speed={300}
                slidesPerView={3}
                centeredSlides={true}
                spaceBetween={0}
                mousewheel={true}
                loop={true}
                onSwiper={setVideoSwiper}
                breakpoints={{ 
                  0: {
                    direction: "horizontal"
                  },
                  992: {
                    direction: "vertical"
                  }
                }}
              >
                {currentVideos.map((video, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="row h-100 m-0">
                        <div className="col-12">
                          <video loop muted preload="metadata" id={"vid_"+index} alt={currentCreator.nombre} poster={video['poster']}>
                              <source type="video/mp4" src={video['360']} media="all and (max-width: 480px)"/>
                              <source type="video/mp4" src={video['540']} media="all and (max-width: 540px)"/>
                              <source type="video/mp4" src={video['720']} media="all and (max-width: 720px)"/>
                              <source type="video/mp4" src={video['1080']} media="all and (max-width: 1080px)"/>
                          </video>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        :
          <div className="col-12 col-md-6 p-0 creator_det" id="one_video">
            <div className="row m-0 h-100 justify-content-center align-items-center">
              <div className="col-12 p-0 text-center">
                {currentVideos.length>0?
                  <video id="one_video_dev" className="block_ctrl" loop muted preload="metadata" controls={false} autoPlay={true} alt={currentCreator.nombre} poster={currentVideos[0]['poster']}>
                      <source type="video/mp4" src={currentVideos[0]['360']} media="all and (max-width: 480px)"/>
                      <source type="video/mp4" src={currentVideos[0]['540']} media="all and (max-width: 540px)"/>
                      <source type="video/mp4" src={currentVideos[0]['720']} media="all and (max-width: 720px)"/>
                      <source type="video/mp4" src={currentVideos[0]['1080']} media="all and (max-width: 1080px)"/>
                  </video>
                :''}
              </div>
            </div>
          </div>
        }
          <div className="col-12 col-md-6" id="info_detail">
            <div className="row m-0 align-content-end justify-content-start">
              <div className="col-12">
                <span className="title">{currentCreator.nombre}</span>
                <span className="sub">{currentCreator.sub}</span>
                <div className="description block_ctrl">
                  {currentCreator.contenido.map((parrafo, index) => {
                    return (
                      <p key={index+'_paragraph'} dangerouslySetInnerHTML={{ __html: parrafo }} />
                    );
                  })}
                  <p></p>
                </div>
              </div>  
            </div>
            <div className="overlay_fixed_creator"></div>
          </div>
        </div>
      </div>
    </section>
    <div >
      <div></div>
      <span></span>
    </div>
    <div className="overlay_back">
      <Link
        className="back_creators"
        onClick={() => {
          goToPage('/creators#'+currentCreator.url.slice(1));
        }}
      >
        <img src={arrow_back} alt=""/>
        <span>Back to creators</span>
      </Link>
    </div>
    <div className="newContainerVideo">
      <div className="close">
        <div></div>
      </div>
    </div>
  </motion.div>
  );
};

export default CreatorVideoFullScreen;