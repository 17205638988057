import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
import creators from "../../assets/data/creators.json";
import Menu from '../../components/submodules/Menu';
import CreatorsObject from '../../components/submodules/CreatorsObject';

import { motion } from "framer-motion/dist/framer-motion";
import plus_dark from "../../assets/img/plus_dark.svg";

SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);
gsap.registerPlugin(Flip);

const CreatorsLineal = () => {
  const [videoSwiper, setVideoSwiper] = useState(null);
  const [infoSwiper, setInfoSwiper] = useState(null);
  const [showAll, setShowAll] = useState(null);
  const [creatorsOriginal] = useState([...CreatorsObject]);
  const [creatorsInfo, setCreatorsInfo] = useState([]);
  const [creatorsVideo, setCreatorsVideo] = useState([]);
  const [maxSlide] = useState(CreatorsObject.length+3);
  const [minSlide] = useState(2);
  const [isMobile] = useState(window.innerWidth<768 ? true : false);

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
  }, []);

  useEffect(() => {
    if (!isMobile && creatorsInfo.length===0 && creatorsVideo.length===0) {
      var firstItems = [
        creatorsOriginal[0],
        creatorsOriginal[1],
        creatorsOriginal[2]
      ]
      var ultimoItem = creatorsOriginal[creatorsOriginal.length - 1];
      var penultimoItem = creatorsOriginal[creatorsOriginal.length - 2];
      var antePenultimoItem = creatorsOriginal[creatorsOriginal.length - 3];
      setCreatorsInfo([ultimoItem, penultimoItem, antePenultimoItem, ...creatorsOriginal, ...firstItems]);
      setCreatorsVideo([ultimoItem, penultimoItem, antePenultimoItem, ...creatorsOriginal, ...firstItems].reverse());
    }
  }, [CreatorsObject]);

  useEffect(() => {
    if (videoSwiper!==null && creatorsVideo.length>0) {
      function autoPlayVideo(){
        var activeIndex = videoSwiper.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        activeSlideVideo.play();
      }
      videoSwiper.on('transitionStart', function (swipe) {
        var videos = document.querySelectorAll('video');
        Array.prototype.forEach.call(videos, function(video){
          video.pause();
        });
      });
      videoSwiper.on('transitionEnd', function (swipe) {
        var activeIndex = swipe.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        activeSlideVideo.play();
      });
      autoPlayVideo();
    }
  }, [videoSwiper, creatorsVideo]);

  useEffect(() => {
    if (videoSwiper!==null && maxSlide!==null && minSlide!==null && isMobile===false) {
      videoSwiper.on('transitionEnd', function (swipe) {
        if (window.hover_state==='videos') {
          //console.log('transitionEnd videoSwiper');
          var activeIndex = swipe.activeIndex;
          if (activeIndex>=maxSlide) {
            videoSwiper.slideTo(minSlide+1, 1, true);
          }
          if (activeIndex<=minSlide) {
            videoSwiper.slideTo(maxSlide-1, 1, true);
          }
        }
      });
      videoSwiper.on('slideChangeTransitionEnd', function (swipe) {
        if (swipe.activeIndex===maxSlide-1 || swipe.activeIndex===minSlide+1) {
          //console.log('videoSwiper updated');
          videoSwiper.update();
        }    
      });
    }
  }, [videoSwiper, maxSlide, minSlide, isMobile]);

  useEffect(() => {
    if (infoSwiper!==null && maxSlide!==null && minSlide!==null && isMobile===false) {
      infoSwiper.on('transitionEnd', function (swipe) {
        if (window.hover_state==='info') {
          //console.log('transitionEnd infoSwiper');
          var activeIndex = swipe.activeIndex;
          if (activeIndex>=maxSlide) {
            infoSwiper.slideTo(minSlide+1, 1, true);
          }
          if (activeIndex<=minSlide) {
            infoSwiper.slideTo(maxSlide-1, 1, true);
          }
        }
      });
      infoSwiper.on('slideChangeTransitionEnd', function (swipe) {
        if (swipe.activeIndex===maxSlide-1 || swipe.activeIndex===minSlide+1) {
          //console.log('infoSwiper updated');
          infoSwiper.update();
        }        
      });
    }
  }, [infoSwiper, maxSlide, minSlide, isMobile]);

  useEffect(() => {
    if (!isMobile) {
    window.hover_state = null;
      let element_videos = document.getElementById("videos");
      let element_info = document.getElementById("info");
      if (element_videos!==null && element_info!==null) {
        element_videos.addEventListener("mouseover", function (event) {
          window.hover_state='videos';
          //console.log('isMouseHover videos');
        }, false);
        element_info.addEventListener("mouseover", function (event) {
          window.hover_state='info';
          //console.log('isMouseHover info');
        }, false);
      }
    }
  }, [isMobile]);

  useEffect(() => {
    if (videoSwiper!==null) {
      videoSwiper.on('transitionStart', function () {
        window.hover_state='videos';
        //console.log('isMouseHover videos');
      });
    }
  }, [videoSwiper]);

  useEffect(() => {
    if (infoSwiper!==null) {
      infoSwiper.on('transitionStart', function () {
        window.hover_state='info';
        //console.log('isMouseHover info');
      });
    }
  }, [infoSwiper]);

  useEffect(() => {
    if (creatorsVideo.length>0) {
      var newContainer = document.querySelector(".newContainerVideo");
      var origContainers = document.querySelectorAll('#videos .swiper-slide>div>div');
      window.videoModal = false;
      var tlineVideo = gsap.timeline({
        defaults: {
          duration: 0.25
        },
        paused: true,
        onComplete: () => {
          window.video_element.muted = false;
          window.video_element.setAttribute("controls",true);
          newContainer.classList.add("filled");
        },
        onStart: () => {
          window.videoModal = true;
        },
        onReverseComplete: () => {
          (window.originalContainer).appendChild(window.video_element);
          window.video_element.muted = true;
          window.video_element.removeAttribute("controls");
          window.video_element.style.transform = 'translate3d(0px, 0px, 0px) scale(1, 1)';
          window.videoModal = false;
          newContainer.classList.remove("filled");
        }
      });
      origContainers.forEach(function(originalContainer){
        originalContainer.addEventListener("click", function(event){
          event.preventDefault();
          window.originalContainer = document.querySelector("#videos .swiper-slide.swiper-slide-active>div>div");
          window.video_element = document.querySelector("#videos .swiper-slide.swiper-slide-active>div>div video");
          window.state = Flip.getState(window.video_element);
          tlineVideo.to(newContainer,
            {
              autoAlpha: 1,
              pointerEvents: 'auto',
              ease: "linear",
              duration: 0.25,
              onStart: () => {
                (newContainer).appendChild(window.video_element);
                Flip.from(window.state, {
                  duration: 0.25,
                  ease: "linear",
                  scale: true
                })
              }
            }
          )
          tlineVideo.play();
        })
      })
      var closeContainer = document.querySelector(".newContainerVideo .close");
      if (closeContainer!==null) {
        closeContainer.addEventListener("click", function(event){
          event.preventDefault();
          tlineVideo.reverse();
        })
      }
    }
  }, [creatorsVideo]);

  return(
  <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
  <Menu propType={'lighten'} propActive={'creators'} />
  <style>{`
    .menu_dev {
        z-index: 200;
    }
  `}</style>
  <Helmet>
    <title>HELO - Creators</title>
    <meta name='description' content='' />
  </Helmet>

    <section className="fix_overflow darked">
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 col-md-6 p-0" id="videos">
            <div>
              <Swiper
                direction="vertical"
                speed={300}
                slidesPerView={3}
                centeredSlides={true}
                spaceBetween={0}
                mousewheel={true}
                loop={isMobile}
                onSwiper={setVideoSwiper}
                initialSlide={3}
                centeredSlidesBounds={!isMobile}
                loopedSlides={isMobile?5:null}
                // controller={{ control: infoSwiper}}
                controller={{ control: infoSwiper, inverse: !isMobile }}
              >
                {isMobile?
                  <React.Fragment>
                    {creatorsOriginal.map((creator, index) => {
                      return (
                        <SwiperSlide key={index} slidekey={index} creator={creator.nombre}>
                          <div className="row h-100 m-0">
                            <div className="col-12">
                              <video loop muted preload="metadata" id={"vid_"+index} alt={creator.nombre} poster={creator.videos[0]['poster']}>
                                  <source type="video/mp4" src={creator.videos[0]['360']} media="all and (max-width: 480px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['540']} media="all and (max-width: 540px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['720']} media="all and (max-width: 720px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['1080']} media="all and (max-width: 1080px)"/>
                              </video>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </React.Fragment>
                :''}
                {!isMobile?
                  <React.Fragment>
                    {creatorsVideo.map((creator, index) => {
                      return (
                        <SwiperSlide key={index} slidekey={index} creator={creator.nombre}>
                          <div className="row h-100 m-0">
                            <div className="col-12">
                              <video loop muted preload="metadata" id={"vid_"+index} alt={creator.nombre} poster={creator.videos[0]['poster']}>
                                  <source type="video/mp4" src={creator.videos[0]['360']} media="all and (max-width: 480px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['540']} media="all and (max-width: 540px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['720']} media="all and (max-width: 720px)"/>
                                  <source type="video/mp4" src={creator.videos[0]['1080']} media="all and (max-width: 1080px)"/>
                              </video>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </React.Fragment>
                :''}
              </Swiper>
              <div className="overlay_videos">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 p-0" id="info">
            <div>
              <Swiper
                direction="vertical"
                speed={300}
                slidesPerView={3}
                centeredSlides={true}
                spaceBetween={0}
                mousewheel={true}
                loop={isMobile}
                onSwiper={setInfoSwiper}
                initialSlide={3}
                centeredSlidesBounds={!isMobile}
                loopedSlides={isMobile?5:null}
                // controller={{ control: videoSwiper }}
                controller={{ control: videoSwiper, inverse: !isMobile }}
                breakpoints={{ 
                  0: {
                    slidesPerView: 5,
                    spaceBetween: 0
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 0
                  }
                }}
              >
                {isMobile?
                  <React.Fragment>
                    {creatorsOriginal.map((creator, index) => {
                      return (
                        <SwiperSlide key={index} slidekey={index} creator={creator.nombre}>
                          <div className="row align-items-center justify-content-center justify-content-md-start h-100">
                            <div className="col-12">
                              <Link
                                className="title"
                                component={RouterLink}
                                to={creator.url}
                              >
                                {creator.nombre}
                              </Link>
                              <span className="sub">{creator.sub}</span>
                              <span>{creator.extracto}</span>
                              {/*<a className="link" onClick={() => {showCreator(index);}}>+ Know More</a>*/}
                              <Link
                                className="link"
                                component={RouterLink}
                                to={creator.url}
                              >
                                + Know More
                              </Link>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </React.Fragment>
                :''}
                {!isMobile?
                  <React.Fragment>
                    {creatorsInfo.map((creator, index) => {
                      return (
                        <SwiperSlide key={index} slidekey={index} creator={creator.nombre}>
                          <div className="row align-items-center justify-content-center justify-content-md-start h-100">
                            <div className="col-12">
                              <Link
                                className="title"
                                component={RouterLink}
                                to={creator.url}
                              >
                                {creator.nombre}
                              </Link>
                              <span>{creator.extracto}</span>
                              {/*<a className="link" onClick={() => {showCreator(index);}}>+ Know More</a>*/}
                              <Link
                                className="link"
                                component={RouterLink}
                                to={creator.url}
                              >
                                + Know More
                              </Link>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </React.Fragment>
                :''}
              </Swiper>
            </div>
            <div className="overlay_info">
              <div></div>
              <div></div>
            </div>
          </div>
{/*          <a className={`show_all ${showAll ? "active" : ""}`} onClick={() => {setShowAll(!showAll);}}>
            {showAll?
              <img className="" src={plus_dark} alt="" />
            :'VIEW ALL'}          
          </a>*/}
        </div>
      </div>
    </section>
    <div className="newContainerVideo">
      <div className="close">
        <div></div>
      </div>
    </div>
  </motion.div>
  );
};

export default CreatorsLineal;