import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";

import AlternateFutures_sq from "../../assets/img/AlternateFutures_sq.jpg";
import linkImg from "../../assets/img/linkImg.svg";

import poster_01 from "../../assets/img/posters/helo_labs/821748102.jpg";
import poster_02 from "../../assets/img/posters/helo_labs/797837099.jpg";
import poster_03 from "../../assets/img/posters/helo_labs/815082986.jpg";
import poster_04 from "../../assets/img/posters/helo_labs/823840805.jpg";
import poster_05 from "../../assets/img/posters/helo_labs/825581560.jpg";

import brassroots_01 from "../../assets/img/posters/helo_labs/brassroots_01.jpg";
import brassroots_02 from "../../assets/img/posters/helo_labs/brassroots_02.jpg";
import brassroots_03 from "../../assets/img/posters/helo_labs/brassroots_03.jpg";

import Menu from '../../components/submodules/Menu';
import { motion } from "framer-motion/dist/framer-motion";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const HeloSparkV2 = () => {

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
    ScrollSmoother.create({
      smooth: 1,               // how long (in seconds) it takes to "catch up" to the native scroll position
      effects: true,           // looks for data-speed and data-lag attributes on elements
      smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });
    ScrollTrigger.create({
      trigger: ".block_01",
      pin: true,
      start: "center center",
      end: "+=300",
      markers: true
    });
    let scroller = ScrollSmoother.create({
      smooth: 1,               // how long (in seconds) it takes to "catch up" to the native scroll position
      effects: true,           // looks for data-speed and data-lag attributes on elements
      smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });
    scroller.effects(".block_item", {speed: 0.5, lag: 0.1});
  }, []);

  return(
    <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
    <Menu propType={'lighten'}/>
    <style>{`
      html>body{
        background-color: #209267;
      }
    `}</style>
    <Helmet>
      <title>HELO - Helo Spark</title>
      <meta name='description' content='Beginner friendly page for learning React Helmet.' />
    </Helmet>
    <main data-barba="container" data-barba-namespace="about">
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <div className="block_item block_01">
                    <img className="standar_img" src={AlternateFutures_sq} alt="" />
                    <p>Alternate Futures</p>
                    <div>
                      <p>At HELO Spark, invention and originality is everything. Here, we meddle with the familiar - film, episodic, theatrical, original IP, even as we charge into new paradigms, testing ideas and integrating unusual formats in unexpected ways.</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="block_item">
                    <h1>HELO SPARK</h1>
                  </div>
                  <div className="block_item">
                    <img className="standar_img" src={brassroots_01} alt="" />
                    <p>Alternate Futures</p>
                    <div>
                      <p>Our alchemists here are encouraged to engineer the transcendent storytelling techniques of tomorrow.</p>
                      <p>We do all this to find original and authentic avenues to reach audiences for our clients - today’s perplexing prototype becoming tomorrow’s product.</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="block_item">
                    <img className="standar_img" src={brassroots_03} alt="" />
                    <p>AI Dreams</p>1
                    <div>
                      <p>What would AI do, if handed the briefs we've received? HELO decided to find out. We discovered that while the tech offers amazing tools, there’s still no replacing the creative human mind in the equation.</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="block_item block_01">
                    <img className="standar_img" src={AlternateFutures_sq} alt="" />
                    <p>Alternate Futures</p>
                    <div>
                      <p>At HELO Spark, invention and originality is everything. Here, we meddle with the familiar - film, episodic, theatrical, original IP, even as we charge into new paradigms, testing ideas and integrating unusual formats in unexpected ways.</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="block_item">
                    <img className="standar_img" src={brassroots_01} alt="" />
                    <p>Alternate Futures</p>
                    <div>
                      <p>Our alchemists here are encouraged to engineer the transcendent storytelling techniques of tomorrow.</p>
                      <p>We do all this to find original and authentic avenues to reach audiences for our clients - today’s perplexing prototype becoming tomorrow’s product.</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="block_item">
                    <img className="standar_img" src={brassroots_03} alt="" />
                    <p>AI Dreams</p>1
                    <div>
                      <p>What would AI do, if handed the briefs we've received? HELO decided to find out. We discovered that while the tech offers amazing tools, there’s still no replacing the creative human mind in the equation.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </main>
  </motion.div>
  );
};

export default HeloSparkV2;