import React from "react";
import { Outlet } from "react-router-dom";

const Global = ({ children }) => (
  <React.Fragment>
      {children}
      <Outlet />
  </React.Fragment>
);

export default Global;