import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Swiper as SwiperInterface, Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade } from "swiper";
import 'swiper/swiper-bundle.css';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from '@studio-freight/lenis';
import mouse from "../../assets/img/mouse.svg";
import scroll from "../../assets/img/scroll.svg";
import Menu from '../../components/submodules/Menu';
import { motion } from "framer-motion/dist/framer-motion";

SwiperCore.use([Controller, Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Zoom, EffectCoverflow, EffectFade]);
gsap.registerPlugin(ScrollTrigger);


const ContactV2 = (props) => {

  useEffect(() => {
    document.querySelector("html").classList.remove("exit");
    var TL_body_bg = gsap.timeline({
      defaults: {
        duration: 1,
        ease: "linear"
      },
      paused: true,
      onComplete: () => {
      },
      onStart: () => {
      }
    });
    TL_body_bg
    .fromTo('body', { backgroundColor: '#6049F8'}, { backgroundColor: '#008351'})
    .fromTo('div.overlay_fixed>div.top_', 
      { 
        background: '-moz-linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(180deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      {
        background: '-moz-linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(180deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      '<'
    )
    .fromTo('div.overlay_fixed>div.bottom_', 
      { 
        background: '-moz-linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(0deg, rgb(96, 73, 248) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      {
        background: '-moz-linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: '-webkit-linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)',
        background: 'linear-gradient(0deg, rgb(0 131 81) 0%, rgba(255, 255, 255, 0) 100%)'
      },
      '<'
    )

    gsap.from('div.row-about', {
      duration: .1,
      scrollTrigger: {
        scroller: "#about_container",
        trigger: "div.row-about",
        start: "top top",
        end: "bottom top-100",
        toggleActions: "play pause reverse reset",
        onEnter: () => {
          TL_body_bg.play();
        },
        onLeave: () => {
          TL_body_bg.reverse();
        },
        onEnterBack: () => {
          TL_body_bg.play();
        },
        onLeaveBack: () => {
          TL_body_bg.reverse();
        },
        scrub: 1
      }
    })

    var main = document.querySelector('main');
    var wrapper = document.querySelector('section#about_container');
    var content = document.querySelector('section#about_container>div.container-xl');
    if (wrapper!==null) {
      var lenis = new Lenis({
        wrapper: wrapper,
        content: content,
        wheelEventsTarget: main,
        lerp: 1,
        duration: 2.5,
        wheelMultiplier: .5,
        smoothTouch: true
      })
      gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
      })

      /*<----Scroll wheel on body*/
      var triggerElement = document.querySelector('body');
      var targetElement = document.getElementById('about_container');
      if (navigator.userAgent.indexOf('Firefox') !== -1) {
        triggerElement.addEventListener('wheel', (event) => {
          var wheelEvent = new WheelEvent('wheel', {
            deltaX: 0,
            deltaY: event.deltaY,
            deltaZ: 0,
            deltaMode: WheelEvent.DOM_DELTA_PIXEL,
          });
          targetElement.dispatchEvent(wheelEvent);
        });
      } else {
        triggerElement.addEventListener('mousewheel', (event) => {
          var wheelEvent = new WheelEvent('wheel', event);
          targetElement.dispatchEvent(wheelEvent);
        });
      }
    }
  }, []);

  return(
    <motion.div
    initial={{ 
      opacity: 0
    }}
    animate={{ 
      opacity: 1
    }}
    transition={{ 
      duration: 3,
      ease: "easeOut"
    }}
    exit={{ 
      opacity: 0
    }}
  >
    <Menu propType={'lighten'} />
    <style>{`
      html>body{
        background-color: #6049F8;
      }
    `}</style>
    <Helmet>
      <title>HELO - Contact Us</title>
    </Helmet>
    <main data-barba="container" data-barba-namespace="about">
      <div className="overlay_fixed">
        <div className="top_"></div>
        <div className="bottom_"></div>
      </div>
      <section id="about_container">
        <div className="container-xl">
          <div className="row row-about">
            <div className="col-12 col-md-6 py-0 px-0 px-md-5 text-end title">
            </div>
            <div className="col-12 col-md-6 p-0 info">
              <div>
                <div className="row m-0 h-100 align-items-center">
                  <div className="col-12 p-0">
                    <div className="block_ctrl item_contact pb-5">
                      <span>WEST COAST US</span>
                      <span>Rena Jett Kayrala</span>
                      <a href="mailto:rena.kayrala@helo.world">rena.kayrala@helo.world</a>
                    </div>
                    <div className="block_ctrl item_contact pb-5">
                      <span>MID WEST US</span>
                      <span>Rowley Samuel</span>
                      <a href="mailto:rowley@RowleySamuel.com">rowley@RowleySamuel.com</a>
                      <a href="tel:+13128604352">312-860-4352</a>
                    </div>
                    <div className="block_ctrl item_contact pb-5">
                      <span>EAST COAST US</span>
                      <span>Rena Jett Kayrala</span>
                      <a href="mailto:rena.kayrala@helo.world">rena.kayrala@helo.world</a>
                    </div>
                    <div className="block_ctrl item_contact">
                      <span>UK / EUROPE</span>
                      <span>Phoebe Smith</span>
                      <a href="mailto:">phoebe.smith@helo.world</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </motion.div>
  );
};

export default ContactV2;