import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import plus_white from "../../assets/img/plus_white.svg";
import plus_dark from "../../assets/img/plus_dark.svg";
import logo_helo_white from "../../assets/img/logo_helo_white.svg";
import logo_helo_dark from "../../assets/img/logo_helo_dark.svg";
import AllCreators from './AllCreators';
import FnRouter from './FnRouter';

const Menu = (props) => {

  const location = useLocation();
  const type = props.propType;
  const active = props.propActive;
  const logoLocation = props.propLogoLocation;
  const typeMenu = props.propTypeMenu;
  const creators = props.propCreators;
  const defaultDisplay = props.propDefaultDisplay;
  const defaultDisplayCreators = props.propDefaultDisplayCreators;

  const [showMenu, setShowMenu] = useState(false);
  const [showListCreators, setShowListCreators] = useState(false);
  const [activeFilter, setActiveFilter] = useState("ALL");
  const [isIos] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);

  function toggleMenu(){
    setShowMenu(!showMenu);
  }

  function toggleListCreators(){
    setShowListCreators(!showListCreators);
  }

  var body_section = document.querySelector("html>body");
  useEffect(() => {
    if (showListCreators) {
      body_section.classList.add("block_gral_events");
    }else{
      body_section.classList.remove("block_gral_events");
    }
  }, [showListCreators, body_section.classList]);

  useEffect(() => {
    if (defaultDisplay!==undefined && defaultDisplay===true) {
      toggleMenu();
    }
  }, [defaultDisplay, toggleMenu]);

  useEffect(() => {
    if (defaultDisplayCreators!==undefined && defaultDisplayCreators===true) {
      toggleListCreators();
    }
  }, [defaultDisplayCreators, toggleListCreators]);

  const navigate = useNavigate();

  function goToView(route){
    document.querySelector("html").classList.add("exit");
    setTimeout( function() {
      navigate(route);
    }, 500);
  }

  function goToPage(url) {
    if (isIos) {
      document.querySelector("html").classList.add("exit");
      setTimeout(function() {
        window.location.href = url;
      }, 500);
    }else{
      goToView(url);
    }
  }
  
  function goToViewReload(url) {
    setTimeout(function() {
      window.location.href = url;
    }, 500);
  }


  const LogoVar = (props) => {
      return (
        <React.Fragment>
            <button
              className={`icon`}
              onClick={() => {
                goToView('/home');
              }}
            >
              {type==='darken' ? 
                <img src={logo_helo_dark} alt="" />
              :
                <img src={logo_helo_white} alt="" />
              }
            </button>
        </React.Fragment>
      )
  }

  return (
    <React.Fragment>
      <style>{`
        div.list_creators {
            z-index: 99;
        }
      `}</style>
      {logoLocation!==undefined && logoLocation==='inMenu'?'':
        <LogoVar forMenuCreators={false}/>
      }

      <div className={` submenu_ ${showMenu ? "on_show_menu" : ""} ${showListCreators ? "on_show_creators" : ""} `} >
        <div className={type==='darken'?'darken':''}>
          <div>
            <Link
              onClick={() => {
                goToView('/projects');
              }}
              className={active==='projects'?'active':''}
            >
              PROJECTS
            </Link>
          </div>
          <div>
            <Link onClick={() => {setShowListCreators(!showListCreators);}}>
              {showListCreators?
              <span>x</span>
              :
              <span>CREATORS</span>
              }
            </Link>
          </div>
        </div>
        <Link id="button_menu" onClick={() => {toggleMenu();}}>
          {type==='darken'?
            <img className="" src={plus_dark} alt="" />
          :
            <img className="" src={plus_white} alt="" />
          }
        </Link>
      </div>
      <div className={`menu_dev ${showMenu ? "on_show" : ""} ${type==='darken' ? "darken" : ""}`}>
        <div className={type==='darken'?'darken':''}>
          {logoLocation!==undefined && logoLocation==='inMenu'?
            <button
              className={`icon ${location.pathname==='/home' ? "active" : ""}`}
              onClick={() => {
                goToView('/home');
              }}
            >
              {type==='darken'?
                <img className="" src={logo_helo_dark} alt="" />
              :
                <img className="" src={logo_helo_white} alt="" />
              }
            </button>
          :''}
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="auto">
                <ul className="menu_options">
                  <li className={`no_desk ${location.pathname==='/home' ? "active" : ""}`}>
                    <button
                      onClick={() => {
                        goToView('/home');
                      }}
                    >
                      HOME
                    </button>
                  </li>
                  <li className={`no_desk ${location.pathname==='/projects' ? "active" : ""}`}>
                    <button
                      onClick={() => {
                        goToPage('/projects');
                      }}
                    >
                      PROJECTS
                    </button>
                  </li>
                  <li className={`no_desk ${location.pathname==='/creators' ? "active" : ""}`}>
                    <button onClick={() => {setShowListCreators(!showListCreators);}}>
                      <span>CREATORS</span>
                    </button>
                  </li>
                  <li className={`${location.pathname==='/about-us' ? "active" : ""}`}>
                    <button
                      onClick={() => {
                        goToView('/about-us');
                      }}
                    >
                      ABOUT
                    </button>
                  </li>
{/* 
                  <li className={`${location.pathname==='/helo-spark' ? "active" : ""}`}>
                    <button
                      onClick={() => {
                        goToViewReload('/helo-spark');
                      }}
                    >
                      HELO SPARK
                    </button>
                  </li>
*/}
                  <li className={`${location.pathname==='/contact-us' ? "active" : ""}`}>
                    <button
                      onClick={() => {
                        goToView('/contact-us');
                      }}
                    >
                      CONTACT US
                    </button>
                  </li>
                </ul>
                <ul className="social_menu mt-5">
{/*                  <li>
                    <a href="https://www.facebook.com/flyhelo/" target="_blank">
                      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.567 0H3.54804C2.60704 0 1.70458 0.37381 1.0392 1.0392C0.37381 1.70458 0 2.60704 0 3.54804L0 29.567C0 30.508 0.37381 31.4104 1.0392 32.0758C1.70458 32.7412 2.60704 33.115 3.54804 33.115H13.6932V21.8566H9.0364V16.5575H13.6932V12.5187C13.6932 7.92468 16.4281 5.3871 20.617 5.3871C22.6232 5.3871 24.7209 5.74486 24.7209 5.74486V10.2538H22.4095C20.1321 10.2538 19.4218 11.6671 19.4218 13.1166V16.5575H24.5058L23.6927 21.8566H19.4218V33.115H29.567C30.508 33.115 31.4104 32.7412 32.0758 32.0758C32.7412 31.4104 33.115 30.508 33.115 29.567V3.54804C33.115 2.60704 32.7412 1.70458 32.0758 1.0392C31.4104 0.37381 30.508 0 29.567 0Z"/>
                      </svg>
                    </a>
                  </li>*/}
                  <li>
                    <a href="https://www.instagram.com/wearehelo/" target="_blank" rel="noreferrer">
                      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5704 8.06993C11.8693 8.06993 8.07732 11.8619 8.07732 16.563C8.07732 21.2642 11.8693 25.0561 16.5704 25.0561C21.2716 25.0561 25.0635 21.2642 25.0635 16.563C25.0635 11.8619 21.2716 8.06993 16.5704 8.06993ZM16.5704 22.0847C13.5324 22.0847 11.0488 19.6084 11.0488 16.563C11.0488 13.5176 13.525 11.0414 16.5704 11.0414C19.6158 11.0414 22.0921 13.5176 22.0921 16.563C22.0921 19.6084 19.6084 22.0847 16.5704 22.0847ZM27.3919 7.72252C27.3919 8.82389 26.5049 9.70351 25.411 9.70351C24.3096 9.70351 23.43 8.8165 23.43 7.72252C23.43 6.62854 24.317 5.74153 25.411 5.74153C26.5049 5.74153 27.3919 6.62854 27.3919 7.72252ZM33.0171 9.73307C32.8914 7.07944 32.2853 4.72887 30.3412 2.79223C28.4046 0.855594 26.054 0.249471 23.4004 0.11642C20.6655 -0.0388066 12.468 -0.0388066 9.73307 0.11642C7.08683 0.242079 4.73626 0.848202 2.79223 2.78484C0.848202 4.72147 0.249471 7.07205 0.11642 9.72568C-0.0388066 12.4606 -0.0388066 20.6581 0.11642 23.393C0.242079 26.0466 0.848202 28.3972 2.79223 30.3339C4.73626 32.2705 7.07944 32.8766 9.73307 33.0097C12.468 33.1649 20.6655 33.1649 23.4004 33.0097C26.054 32.884 28.4046 32.2779 30.3412 30.3339C32.2779 28.3972 32.884 26.0466 33.0171 23.393C33.1723 20.6581 33.1723 12.468 33.0171 9.73307ZM29.4838 26.3275C28.9072 27.7763 27.7911 28.8925 26.3349 29.4764C24.1544 30.3412 18.9801 30.1417 16.5704 30.1417C14.1607 30.1417 8.97912 30.3339 6.80594 29.4764C5.35716 28.8999 4.24101 27.7837 3.65706 26.3275C2.79223 24.147 2.99181 18.9727 2.99181 16.563C2.99181 14.1533 2.79962 8.97172 3.65706 6.79855C4.23362 5.34977 5.34977 4.23362 6.80594 3.64967C8.98651 2.78484 14.1607 2.98441 16.5704 2.98441C18.9801 2.98441 24.1618 2.79223 26.3349 3.64967C27.7837 4.22623 28.8999 5.34238 29.4838 6.79855C30.3486 8.97912 30.1491 14.1533 30.1491 16.563C30.1491 18.9727 30.3486 24.1544 29.4838 26.3275Z"/>
                      </svg>
                    </a>
                  </li>
{/*                  <li>
                    <a href="https://vimeo.com/flyhelo" target="_blank" rel="noreferrer">
                      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.8035 0H3.3115C1.48574 0 0 1.48574 0 3.3115V29.8035C0 31.6293 1.48574 33.115 3.3115 33.115H29.8035C31.6293 33.115 33.115 31.6293 33.115 29.8035V3.3115C33.115 1.48574 31.6293 0 29.8035 0ZM27.8669 10.9989C27.7634 13.3273 26.1372 16.5205 22.9883 20.5638C19.7359 24.7919 16.9788 26.9059 14.7317 26.9059C13.3347 26.9059 12.1594 25.6198 11.1911 23.0401C9.3062 16.1436 8.5005 12.1003 6.94824 12.1003C6.77083 12.1003 6.14254 12.4773 5.07073 13.2238L3.94719 11.775C6.70431 9.35055 9.33577 6.65996 10.9841 6.51212C12.8468 6.33472 13.9926 7.6061 14.4213 10.3337C15.9514 20.0316 16.6314 21.4952 19.4181 17.1045C20.416 15.5227 20.9556 14.3178 21.0295 13.4899C21.2882 11.0359 19.115 11.2059 17.6441 11.8342C18.8194 7.98308 21.0665 6.11297 24.3853 6.21645C26.8468 6.28298 28.0073 7.87959 27.8669 10.9989Z"/>
                      </svg>
                    </a>
                  </li>*/}
                  <li>
                    <a href="https://www.linkedin.com/company/helo/" target="_blank" rel="noreferrer">
                      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.5714 0H2.42098C1.08527 0 0 1.10045 0 2.45134V31.5487C0 32.8996 1.08527 34 2.42098 34H31.5714C32.9071 34 34 32.8996 34 31.5487V2.45134C34 1.10045 32.9071 0 31.5714 0ZM10.2759 29.1429H5.23661V12.917H10.2835V29.1429H10.2759ZM7.75625 10.7009C6.13973 10.7009 4.83437 9.38795 4.83437 7.77902C4.83437 6.17009 6.13973 4.85714 7.75625 4.85714C9.36518 4.85714 10.6781 6.17009 10.6781 7.77902C10.6781 9.39554 9.37277 10.7009 7.75625 10.7009ZM29.1656 29.1429H24.1263V21.25C24.1263 19.3679 24.0884 16.9469 21.508 16.9469C18.8821 16.9469 18.4799 18.996 18.4799 21.1134V29.1429H13.4406V12.917H18.275V15.133H18.3433C19.0187 13.858 20.6656 12.5147 23.117 12.5147C28.217 12.5147 29.1656 15.8768 29.1656 20.2482V29.1429Z"/>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="block_ctrl spc">
            <span>STORYTELLING</span>
            <span className="plus">+</span>
            <span>EXPERIENCE DESIGN</span>
            <span className="plus">+</span>
            <span>INNOVATION</span>
          </div>  
        </div>
      </div>
      <AllCreators active={active} activeFilter={activeFilter} setActiveFilter={setActiveFilter} showListCreators={showListCreators} setShowListCreators={setShowListCreators} />
    </React.Fragment>
  );
};

export default Menu;